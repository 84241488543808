import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';

import Device from './Views/Device';
import Gateway from './Views/Gateway';
import ListLocations from './Views/ListLocations';
import Location from './Views/Location';
import Login from './Views/Login';
import Register from './Views/Register';
import ListEntities from './Views/ListeEntities';
import Navbar from './components/Navbar/Navbar';
import { logout } from './actions/auth';
import EventBus from './common/EventBus';
import SecuredRoute from './components/securedRoute';
import { history } from './helpers/history';
import AddDeviceModal from './components/Modal/addDevice';
import AddGatewayModal from './components/Modal/addGateway';
import UpdateGatewayModal from './components/Modal/updateGateway';
import { useSocket } from './SocketContext';
import Map from '../src/Views/Map';
import UpdateLocationModal from './components/Modal/updateLocation';
import AddLocationModal from './components/Modal/addLocation';
import WeatherChart from './components/chart/WeatherChart';
import DeviceView from './Views/DeviceView';
import VoltageChart from './components/chart/VoltageChart';
import PhChart from './components/chart/PhChart';
import PressureChart from './components/chart/PressureChart';
import Chart from './Views/Chart'
import NotFound from './Views/NotFound'
const App = () => {
    const socket = useSocket();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [ recentEntity, setRecentEntity ] = React.useState()
    const [ userEntity, setUserEntity ] = React.useState()
    useEffect(() => {
        if(userEntity){
            console.log('userEntity',userEntity)
            setRecentEntity(userEntity)
        }
    }, [ userEntity ])

    const REACT_APP_VERSION=process.env.REACT_APP_VERSION
    console.log('REACT_APP_VERSION'+REACT_APP_VERSION)
    useEffect(() => {
        socket.on('Get_Confirm_Config', () => {
            console.log('Get_Confirm_Config')
        });

        socket.on('update_device_data', () => {
            console.log('update_device_data');
        });
        socket.on('Get_TTN_Connect_Status', () => {
            console.log('Get_TTN_Connect_Status');
        })
        socket.on('user_connected', (id) => {
            console.log('user_connected ' + id);
        });
        socket.on('error', (error) => {
            console.error('Connection error:', error);
        });

        return () => {
        //    //socket.disconnect();
        };
    }, [ socket ]);

    const dispatch = useDispatch();

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [ dispatch ]);

    useEffect(() => {
        EventBus.on('logout', () => {
            logOut();
        });

        return () => {
            EventBus.remove('logout');
        };
    }, [ currentUser, logOut ]);
    const currentPathname = window.location.pathname;
    const shouldShowNavbar = !currentPathname.startsWith('/chart') && !currentPathname.startsWith('/notfound');
    return (
        <Router history={history}>
            <>
                {shouldShowNavbar && <Navbar recentEntity={recentEntity} setRecentEntity={setRecentEntity} />}
                <div>
                    <Switch>
                        <Route exact path={[ '/', '/login' ]} component={Login} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/register" component={Register} />
                        <SecuredRoute
                            exact
                            path="/Location/:locationId"
                            component={() => <Location socket={socket}  recentEntity={recentEntity} setRecentEntity={setRecentEntity}/>}
                        />
                        <SecuredRoute
                            exact
                            path="/Entity"
                            component={() => <ListEntities recentEntity={recentEntity} setRecentEntity={setRecentEntity} socket={socket} />}
                        />
                        <SecuredRoute
                            exact
                            path="/Entity/:entityId"
                            component={() => <ListLocations socket={socket} setUserEntity={setUserEntity} setRecentEntity={setRecentEntity} recentEntity={recentEntity}/>}
                        />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/device/:deviceId"
                            component={() => <Device  setRecentEntity={setRecentEntity} recentEntity={recentEntity} socket={socket} />}
                        />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/devices/:deviceId/:sensor?"
                            component={() => <DeviceView  setRecentEntity={setRecentEntity} recentEntity={recentEntity} socket={socket} />}
                        />
                        <Route exact path="/chart/location/:locationId/device/:deviceId/token/:token" component={() =>  <Chart  setRecentEntity={setRecentEntity} recentEntity={recentEntity} socket={socket} />} />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/gateway/:gatewayId"
                            component={() => <Gateway socket={socket}  setRecentEntity={setRecentEntity} recentEntity={recentEntity} />}
                        />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/addDevice"
                            component={() => <AddDeviceModal socket={socket} />}
                        />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/addGateway"
                            component={() => <AddGatewayModal socket={socket} setRecentEntity={setRecentEntity} recentEntity={recentEntity} />}
                        />
                        <SecuredRoute
                            exact
                            path="/location/:locationId/updateGateway/:gatewayId"
                            component={() => <UpdateGatewayModal socket={socket} setRecentEntity={setRecentEntity} recentEntity={recentEntity} />}
                        />
                        <SecuredRoute
                            exact
                            path="/Map"
                            component={() => <Map apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'}  socket={socket} />}                    />
                        <SecuredRoute
                            exact
                            path="/updateLocation/:locationId"
                            component={() => <UpdateLocationModal  socket={socket} recentEntity={recentEntity} setRecentEntity={setRecentEntity} />}
                        />
                        <SecuredRoute
                            exact
                            path="/addLocation/:entityId"
                            component={() => <AddLocationModal  socket={socket} recentEntity={recentEntity} setRecentEntity={setRecentEntity} />}
                        />
                        <SecuredRoute
                            exact
                            path="/weatherChart"
                            component={() => <WeatherChart/>}
                        /><SecuredRoute
                            exact
                            path="/voltageChart"
                            component={() => <VoltageChart/>}
                        />
                        <SecuredRoute
                            exact
                            path="/phChart"
                            component={() => <PhChart/>}
                        />
                        <SecuredRoute
                            exact
                            path="/pressureChart"
                            component={() => <PressureChart/>}
                        />
                        <Route path="/notfound" component={NotFound} />
                    </Switch>
                    <p style={{
                        position: 'fixed',
                        bottom: -15,
                        width: '100%',
                        backgroundColor: '#f8f9fa',
                        textAlign: 'left',
                        color: '#333',
                    }}>{REACT_APP_VERSION}</p>
                </div>
            </>
        </Router>
    );
};

export default App;

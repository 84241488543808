import React from 'react';
import {   Route,Redirect } from 'react-router-dom';

import isLoggedIn  from './isLoggedIn';

const  SecuredRoute = ({ component: Component, ...rest }) => (

    <Route
        { ...rest }
        render={ (props) =>

            (isLoggedIn() === true ? (
                <Component { ...props } />
            ) : (
                <Redirect to="/login" />
            ))
        }
    />
);

export default SecuredRoute;
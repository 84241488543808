import { Card, CardContent, Divider } from '@material-ui/core';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGatewayListByLocationId } from '../actions/gatewayAction';
import Table from './GatewayTable/DataTable';
import Button from 'react-bootstrap/Button';
import Link from '@mui/material/Link';
import { useSocket } from '../SocketContext';
export default function ListGatewaysCard({ id,isAdmin }) {
    const socket = useSocket();
    const dispatch = useDispatch();
    const { gatewayList } = useSelector((state) => state.gatewayList);
    useEffect(() => {
        socket.on('update_device_data', () => {
            dispatch(getGatewayListByLocationId(id));
        });
        return () => {
            //socket.disconnect();
        };
    }, [ dispatch,id,socket ]);
    useEffect(() => {

        socket.on('Get_TTN_Connect_Status',(data) => {
            console.log('Get_TTN_Connect_Status',JSON.stringify(data));
            dispatch(getGatewayListByLocationId(id));
        })
        return () => {
            //socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  ])
    useEffect(() => {
        dispatch(getGatewayListByLocationId(id));
    }, [ dispatch, id ]);

    return (
        <>
            {gatewayList ? (
                <Card
                    elevation={2}
                    style={{ backgroundColor: '#F4F6F8', marginTop: '20px' }}
                >
                    <h5 className="card-header d-flex justify-content-between align-items-center">
            Gateway List
                        {isAdmin && (
                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <Link color="inherit" href={`/location/${ id }/addGateway`}>
                                    <Button color="success" variant="success" className='mr-1'>
                        Add Gateway
                                    </Button>
                                </Link>

                            </Box>
                        )}
                    </h5>

                    <Divider />
                    <CardContent>
                        <Table data={gatewayList} locationId={id} isAdmin={isAdmin} />
                    </CardContent>
                    <Divider />
                </Card>
            ) : (
                <Card elevation={2} style={{ backgroundColor: '#F4F6F8' }}>
                    <h5 className="card-header d-flex justify-content-between align-items-center">
            Gateways List
                    </h5>

                    <CardContent>
                        <Box
                            className=" d-flex align-items-center justify-content-center "
                            sx={{ flexGrow: 1 }}
                        >
                            <div className="spinner-border text-success "></div>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </>
    );
}

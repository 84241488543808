import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail, isMobilePhone } from 'validator';
import 'react-phone-input-2/lib/style.css';
import { register } from '../actions/auth';
import IntlTelInput from 'react-bootstrap-intl-tel-input';
import logo  from '../assets/Watermon.png'
const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
            This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
            This is not a valid email.
            </div>
        );
    }
};

const validPhone = (value) => {
    if (!isMobilePhone(value)) {
        return (
            <div className="alert alert-danger" role="alert">
            This is not a valid phone number.
            </div>
        );
    }
};
const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
            The username must be between 3 and 20 characters.
            </div>
        );
    }
};
const vaddress = (value) => {
    if (value.length < 4) {
        return (
            <div className="alert alert-danger" role="alert">
            The address must have at least 4 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
            The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const Register = () => {
    const form = useRef();
    const checkBtn = useRef();

    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ successful, setSuccessful ] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const { message } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const handlePhoneChange = (status) => {
        setPhoneNumber(JSON.stringify(status.intlPhoneNumber))
    };

    const onChangeFirstname = (e) => {
        const firstname = e.target.value;
        setFirstname(firstname);
    };

    const onChangeLastname = (e) => {
        const lastname = e.target.value;
        setLastname(lastname);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeAddress = (e) => {
        const address = e.target.value;
        setAddress(address);
    };

    const handleRegister = (e) => {
        e.preventDefault();

        setSuccessful(false);
        const generatedUsername = `${ firstname } ${ lastname }`.trim();
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(
                register(
                    firstname,
                    lastname,
                    generatedUsername,
                    email,
                    password,
                    address,
                    phoneNumber.slice(0, 5) + phoneNumber.slice(6),
                ),
            )
                .then(() => {
                    setSuccessful(true);
                })
                .catch(() => {
                    setSuccessful(false);
                });
        }
    };

    return (
        <div  className="col-md-12 parent-container" >
            <div className="card-register card-container">

                <img
                    style={{ width:'150px' }}
                    src={logo}
                    alt="profile-img"
                    className="profile-img-card"
                />
                <Form onSubmit={ handleRegister } ref={ form }>
                    {!successful && (
                        <>
                            <Container >
                                <Row>
                                    <Col sm={6} md={6} >
                                        <div className="form-group">
                                            <label htmlFor="username">Firstname</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="firstname"
                                                value={firstname }
                                                onChange={onChangeFirstname }
                                                validations={[ required, vusername ]}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div className="form-group">
                                            <label htmlFor="lastname">Lastname</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="lastname"
                                                value={lastname}
                                                onChange={onChangeLastname}
                                                validations={[ required, vusername ]}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6} md={6}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={email}
                                                onChange={onChangeEmail}
                                                validations={[ required, validEmail ]}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                value={password}
                                                onChange={onChangePassword}
                                                validations={[ required, vpassword ]}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6} md={6}>
                                        <div className="form-group">
                                            <label htmlFor="address">Address</label>
                                            <Input
                                                type="ext"
                                                className="form-control"
                                                name="address"
                                                value={address}
                                                onChange={onChangeAddress}
                                                validations={[ required, vaddress ]}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <div className="form-group">
                                            <label htmlFor="password">Phone number</label>
                                            <IntlTelInput
                                                preferredCountries={[ 'TN', 'US', 'GB' ]}
                                                defaultCountry={'TN'}
                                                validations={[ required, validPhone ]}
                                                onChange={handlePhoneChange}
                                                value={phoneNumber}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <div className="form-group">
                                <button className="btn btn-success btn-block">Sign up</button>
                            </div>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                        Already have an account?{' '}
                                        <a
                                            href="/login"
                                            style={{
                                                fontSize: '16px',
                                                textAlign: 'start',
                                                fontWeight: '500',
                                                color: '#28A745',
                                            }}
                                        >
                            Sign in
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}

                    {message && (
                        <div className="form-group">
                            <div
                                className={
                                    successful ? 'alert alert-success' : 'alert alert-danger'
                                }
                                role="alert"
                            >
                                {message}
                                <a href="/login"> Sign in</a>
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                </Form>

            </div>
        </div>
    );
};

export default Register;
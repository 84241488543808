import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
//import Wifi from '@mui/icons-material/Wifi';
import { Box } from '@mui/material';
//import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { generatePayload } from './../Utils/GenerateDownlinkData';
import avempaceLogo from '../components/avempaceLogo.png';
import DeviceService from '../services/device.service';
import deviceService from '../services/device.service';
import Swal from 'sweetalert2';
import { useSocket } from '../SocketContext';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { getDeviceLastMessage } from '../actions/deviceAction';
import { interpretHexValues } from '../Utils/DecodeUplink';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
const DeviceImage = ({
    ph,
    pressure,
    temperature,
    batteryVoltage,
    input0,
    input1,
    out1,
    out2,
    device,
    location,
    setOutput0,
    setOutput1,
    setInput0,
    setInput1,
    setBatteryVoltage,
    setPressure,
    setTemperature,
    setBatteryCurrent,
    setCO2,
    setPh,
    scrollToTempChart,
    scrollToVoltChart,
    scrollToPressureChart,
    scrollToPhChart,
}) => {

    const [ isCheckedOut1, setCheckedOut1 ] = React.useState(out1);
    const [ isCheckedOut2, setCheckedOut2 ] = React.useState(out2);
    const [ downlinkSpinnerOut1, setDownlinkSpinnerOut1 ] = React.useState(false);
    const [ downlinkSpinnerOut2, setDownlinkSpinnerOut2 ] = React.useState(false);
    const socket = useSocket();
    const dispatch = useDispatch();
    const { sensor } = useParams();
    const { deviceMessage } = useSelector((state) => state.device);
    useEffect(() => {
        if (downlinkSpinnerOut1) {
            const timer = setTimeout(() => {
                console.log('close spinner on timeout out1');
                setDownlinkSpinnerOut1(false);
                console.log('call getDeviceLastMessage');
                dispatch(getDeviceLastMessage(device.eui)).then((response) => {
                    console.log('result getDeviceLastMessage  on close spinner on timeout out1', JSON.stringify(response))
                    if (response.data) {
                        const result = interpretHexValues(
                            response?.data,
                            setOutput0,
                            setOutput1,
                            setInput0,
                            setInput1,
                            setBatteryVoltage,
                            setPressure,
                            setTemperature,
                            setBatteryCurrent,
                            setCO2,
                            setPh,
                        );
                        console.log('deviceMessage?.data on close spinner on timeout result.output0', result?.output0)
                        console.log('deviceMessage?.data on close spinner on timeout result.output1', result?.output1)
                        setCheckedOut1(result?.output0)
                        setCheckedOut2(result?.output1)
                    }
                })
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [ downlinkSpinnerOut1 ]);

    useEffect(() => {
        if (downlinkSpinnerOut2) {
            const timer = setTimeout(() => {
                console.log('close spinner on timeout out2');
                setDownlinkSpinnerOut2(false);
                console.log('call getDeviceLastMessage');
                dispatch(getDeviceLastMessage(device.eui)).then((response) => {
                    console.log('result getDeviceLastMessage  on close spinner on timeout out1', JSON.stringify(response))
                    if (response.data) {
                        const result = interpretHexValues(
                            response?.data,
                            setOutput0,
                            setOutput1,
                            setInput0,
                            setInput1,
                            setBatteryVoltage,
                            setPressure,
                            setTemperature,
                            setBatteryCurrent,
                            setCO2,
                            setPh,
                        );
                        console.log('deviceMessage?.data on close spinner on timeout result.output0', result?.output0)
                        console.log('deviceMessage?.data on close spinner on timeout result.output1', result?.output1)
                        setCheckedOut1(result?.output0)
                        setCheckedOut2(result?.output1)
                    }
                })
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [ downlinkSpinnerOut2 ]);

    useEffect(() => {
        if (deviceMessage) {
            const result = interpretHexValues(
                deviceMessage?.data,
                setOutput0,
                setOutput1,
                setInput0,
                setInput1,
                setBatteryVoltage,
                setPressure,
                setTemperature,
                setBatteryCurrent,
                setCO2,
                setPh,
            );
            setCheckedOut1(result?.output0)
            setCheckedOut2(result?.output1)
        }

    }, [ deviceMessage, deviceMessage?.data ])

    useEffect(() => {
        socket && socket.on('update_device_data', (data) => {
            if (data.id === device.id) {
                console.log('update_device_data')
                setDownlinkSpinnerOut1(false);
                setDownlinkSpinnerOut2(false);
                const result = interpretHexValues(
                    data?.data,
                    setOutput0,
                    setOutput1,
                    setInput0,
                    setInput1,
                    setBatteryVoltage,
                    setPressure,
                    setTemperature,
                    setBatteryCurrent,
                    setCO2,
                    setPh,
                );
                console.log('result close spinner on after update_device_data output0', result?.output0)
                console.log('result close spinner on after update_device_data output1', result?.output1)
                if(result){
                    console.log('result here',result)
                    setCheckedOut1(result?.output0)
                    setCheckedOut2(result?.output1)
                    console.log('call getDeviceLastMessage');
                    dispatch(getDeviceLastMessage(device.eui));
                }

            }
        });

    }, [ socket ]);

    useEffect(() => {
        socket.on('downlink_failed', (data) => {
            if (data.id === device.id) {
                if (downlinkSpinnerOut1) {
                    console.log('downlinkSpinnerOut1 change')
                    console.log('close spinner on downlink_failed Out1')
                    setDownlinkSpinnerOut1(false);
                }
                if (downlinkSpinnerOut2) {
                    console.log('downlinkSpinnerOut2 change')
                    console.log('close spinner on downlink_failed Out2')
                    setDownlinkSpinnerOut2(false);
                }
                Swal.fire({
                    icon: 'error',
                    text: 'An error occurred while processing your request. ',
                });
                console.log('call getDeviceLastMessage');
                dispatch(getDeviceLastMessage(device.eui))

            }
        })
    }, [ socket, downlinkSpinnerOut1, downlinkSpinnerOut2, out1, out2 ])

    useEffect(() => {
        setCheckedOut1(out1)
    }, [ out1 ])

    useEffect(() => {
        setCheckedOut2(out2)
    }, [ out2 ])

    const handleClickOut1 = (event) => {
        event.stopPropagation()
        const payload = generatePayload(!isCheckedOut1, isCheckedOut2);
        if (device) {
            const port = 2;
            const priority = 'NORMAL';
            setCheckedOut1(!isCheckedOut1);
            setDownlinkSpinnerOut1(true);
            deviceService
                .sendDownlink(device.eui, port, payload, priority)
                .then((response) => {
                    if (response.data.success) {
                        console.log('success downlink');
                    } else {
                        const result = interpretHexValues(
                            deviceMessage?.data,
                            setOutput0,
                            setOutput1,
                            setInput0,
                            setInput1,
                            setBatteryVoltage,
                            setPressure,
                            setTemperature,
                            setBatteryCurrent,
                            setCO2,
                            setPh,
                        );
                        setCheckedOut1(result?.output0)
                        setCheckedOut2(result?.output1)
                        console.log('close spinner on send downlink api fail out1')
                        setDownlinkSpinnerOut1(false)
                    }
                })
                .catch(() => {
                    const result = interpretHexValues(
                        deviceMessage?.data,
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                    setCheckedOut1(result?.output0)
                    setCheckedOut2(result?.output1)
                    console.log('close spinner on send downlink catch error out1')
                    setDownlinkSpinnerOut1(false);
                });
        }
    };

    const handleClickOut2 = (event) => {
        event.stopPropagation()
        const payload = generatePayload(isCheckedOut1, !isCheckedOut2);
        if (device) {
            const port = 2;
            const priority = 'NORMAL';
            setCheckedOut2(!isCheckedOut2);
            setDownlinkSpinnerOut2(true);

            DeviceService.sendDownlink(device.eui, port, payload, priority).then(
                (response) => {
                    if (response.data.success) {
                        console.log('success downlink');
                    } else {

                        const result = interpretHexValues(
                            deviceMessage?.data,
                            setOutput0,
                            setOutput1,
                            setInput0,
                            setInput1,
                            setBatteryVoltage,
                            setPressure,
                            setTemperature,
                            setBatteryCurrent,
                            setCO2,
                            setPh,
                        );
                        setCheckedOut1(result?.output0)
                        setCheckedOut2(result?.output1)
                        console.log('close spinner on send downlink fail Out2')
                        setDownlinkSpinnerOut2(false);
                    }
                },
            ).catch(() => {

                const result = interpretHexValues(
                    deviceMessage?.data,
                    setOutput0,
                    setOutput1,
                    setInput0,
                    setInput1,
                    setBatteryVoltage,
                    setPressure,
                    setTemperature,
                    setBatteryCurrent,
                    setCO2,
                    setPh,
                );
                setCheckedOut1(result?.output0)
                setCheckedOut2(result?.output1)
                console.log('close spinner on catch error')
                setDownlinkSpinnerOut2(false);
                setDownlinkSpinnerOut2(false);
            });
        }
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const currentPath = window.location.pathname;
    const expectedPath = `/location/${ location?.id }/devices/${ device?.id }`
    return (

        <Box style={{ cursor: currentPath !== expectedPath && 'pointer', width: matches ? (matchesSm ? '11.3vw' : '10.5vw') : (matchesSm ? '16vw' : '15.4vw') }} sx={{ flexGrow: 1 }}>
            <Grid className="imgGrid" container spacing={0}  >
                <Grid item xs={6} style={{ height: '5%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start', verticalAlign: 'middle' }}>
                    {batteryVoltage >= 4 && <BatteryFullIcon fontSize="small" style={{ transform: 'rotate(90deg)', color: 'white', fontSize: '1.5vw' }} />}
                    {batteryVoltage <4 && batteryVoltage >= 3.7 && <Battery80Icon fontSize="small" style={{ transform: 'rotate(90deg)', color: 'white', fontSize: '1.5vw' }} />}
                    {batteryVoltage >= 3.4 && batteryVoltage < 3.7 && <Battery20Icon fontSize="small" style={{ transform: 'rotate(90deg)', color: 'white', fontSize: '1.5vw' }} />}
                    {batteryVoltage < 3.4 && <Battery0BarIcon fontSize="small" style={{ transform: 'rotate(90deg)', color: 'white', fontSize: '1.5vw' }} />}
                </Grid>
                <Grid item xs={6} style={{ height: '5%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', verticalAlign: 'middle' }}>
                    {/*     <Wifi fontSize="small" style={{ color: 'white', fontSize: '1.3vw',marginTop:'0.2vw',marginRight:'0.2vw' }} /> */}
                </Grid>
                <Grid item xs={6}
                    onClick={() => {
                        if (currentPath.replace(`/${ sensor }`, '') !== expectedPath) {
                            window.location.href = expectedPath + '/ph';
                        } else {
                            scrollToPhChart()
                        }
                    }}
                    style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', verticalAlign: 'middle', cursor: 'pointer' }}>

                    <p style={{ marginLeft: '3px', color: '#03f4fc', fontSize: '1vw', fontWeight: 'bold', verticalAlign: 'middle' }}>

                        ph={ph ?? 'N/A'}
                    </p>

                </Grid>
                <Grid item xs={6} style={{ height: '15%', flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'flex-end', verticalAlign: 'middle' }}>

                    <div style={{ fontWeight: 'bold', color: 'white', fontSize: '0.6vw', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>IN1
                        {(input0 ? (<RadioButtonCheckedIcon fontSize="small" style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw' }} />) : <RadioButtonUncheckedIcon fontSize="small" style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw' }} />)

                        }</div>

                </Grid>
                <Grid item xs={6}
                    onClick={() => {
                        if (currentPath.replace(`/${ sensor }`, '') !== expectedPath) {
                            window.location.href = expectedPath + '/volt';
                        } else {
                            scrollToVoltChart()
                        }
                    }}
                    style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', verticalAlign: 'middle', cursor: 'pointer' }}>

                    <p style={{ marginLeft: '3px', color: 'yellow', fontSize: '1vw', fontWeight: 'bold' }}>
                        V = {(batteryVoltage ?? 'N/A') !== 'N/A' ? batteryVoltage + 'V' : 'N/A'}
                    </p>

                </Grid>
                <Grid item xs={6} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', verticalAlign: 'middle' }}>

                    <div style={{ fontWeight: 'bold', color: 'white', fontSize: '0.6vw', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>IN2
                        {(input1 ? (<RadioButtonCheckedIcon fontSize="small" style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw' }} />) : <RadioButtonUncheckedIcon fontSize="small" style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw' }} />)

                        }</div>

                </Grid>
                <Grid item
                    onClick={() => {
                        if (currentPath.replace(`/${ sensor }`, '') !== expectedPath) {
                            window.location.href = expectedPath + '/pressure';
                        } else {
                            scrollToPressureChart()
                        }
                    }}
                    xs={6}
                    style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', verticalAlign: 'middle', cursor: 'pointer' }}>

                    <p style={{ marginLeft: '3px', color: '#1cfc03', fontSize: '1vw', fontWeight: 'bold' }}>
                        P=  {(pressure ?? 'N/A') !== 'N/A' ? pressure + 'Bar' : 'N/A'}
                    </p>

                </Grid>
                <Grid item xs={6} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', verticalAlign: 'middle' }}>

                    <div style={{ fontWeight: 'bold', color: 'white', fontSize: '0.6vw', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>OUT1
                        {downlinkSpinnerOut1 && (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={() => null}
                            >
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    variant="light"
                                />
                            </OverlayTrigger>
                        )}
                        {(isCheckedOut1 === undefined) && <RadioButtonUncheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut1(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />}
                        {(isCheckedOut1 === true) && <RadioButtonCheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut1(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />}

                        {(isCheckedOut1 === false) && <RadioButtonUncheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut1(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />

                        }</div>

                </Grid>
                <Grid
                    onClick={() => {
                        if (currentPath.replace(`/${ sensor }`, '') !== expectedPath) {
                            window.location.href = expectedPath + '/temp';
                        } else {
                            scrollToTempChart()
                        }
                    }}
                    item xs={6} style={{ height: '15%', flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', verticalAlign: 'middle', cursor: 'pointer' }}>

                    <p style={{ marginLeft: '3px', color: '#7d0606', fontSize: '1vw', fontWeight: 'bold' }}>
                        T=  {(temperature ?? 'N/A') !== 'N/A' ? temperature + '°C' : 'N/A'}

                    </p>

                </Grid>
                <Grid item xs={6} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', verticalAlign: 'middle' }}>

                    <div style={{ fontWeight: 'bold', color: 'white', fontSize: '0.6vw', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>OUT2
                        {downlinkSpinnerOut2 && (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={() => null}
                            >
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    variant="light"
                                />
                            </OverlayTrigger>
                        )}
                        {(isCheckedOut2 === undefined) && <RadioButtonUncheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut2(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />}
                        {(isCheckedOut2 === true) && (<RadioButtonCheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut2(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />)}

                        {(isCheckedOut2 === false && <RadioButtonUncheckedIcon fontSize="small" onClick={(e) => {
                            handleClickOut2(e)
                        }} style={{ color: 'white', fontSize: '1.5vw', marginRight: '1vw', cursor: 'pointer' }} />)

                        }</div>

                </Grid>

                <Grid item xs={12} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <img
                        src={avempaceLogo}
                        alt="avempace logo"
                        style={{
                            height: '2vw',
                        }}
                    />
                </Grid>
                <Grid item xs={6} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', verticalAlign: 'middle' }}>
                    <div style={{ color: 'blue', fontSize: '1vw', fontWeight: 'bold', textAlign: 'left', marginLeft: '0.17vw' }}>
                        Watermon
                    </div>
                </Grid>
                <Grid item xs={6} style={{ height: '15%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', verticalAlign: 'middle' }}>
                    <div style={{ color: 'blue', fontSize: '1vw', fontWeight: 'bold', textAlign: 'right', marginRight: '0.5vw' }}>
                        {device.software_version}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DeviceImage;
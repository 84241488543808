import React from 'react';
import { useParams } from 'react-router-dom';
import LocationInfo from '../LocationInfo';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import { Card, Container } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { addDevice } from '../../actions/deviceAction';
//import { getDeviceListByLocationId } from '../../actions/deviceAction';
import Link from '@mui/material/Link';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@material-ui/core/Typography';
import MapComponent from '../Map/MapComponent';
export default function Location({ socket }) {
    const devEuiRegex = /^[0-9A-Fa-f]{16}$/;
    const schema = yup.object().shape({
        name: yup.string().required(),
        dev_eui: yup.string().matches(devEuiRegex, 'Invalid DevEUI').required(),
        join_eui: yup.string().matches(devEuiRegex, 'Invalid DevEUI').required(),
        description: yup.string().required(),
        model: yup.string().required(),

    });
    const [ lng,setLng ]=React.useState(null);
    const [ lat,setLat ]=React.useState(null);
    const [ latChanged, setLatChanged ] = React.useState(false);
    const [ lngChanged, setLngChanged ] = React.useState(false);
    const dispatch = useDispatch();

    const { location } = useSelector((state) => state.location);
    const { locationId } = useParams();
    const history = useHistory();

    React.useEffect(() => {
        if (lat !== null) {
            setLatChanged(true);
        }
    }, [ lat ]);

    React.useEffect(() => {
        if (lng !== null) {
            setLngChanged(true);
        }
    }, [ lng ]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <AppBar
                        position="static"
                        sx={{ backgroundColor: '#F7F7F7 !important' }}
                    >
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                    }}
                                >
                  Add device

                                    <Breadcrumbs separator="/" aria-label="breadcrumb">
                                        {/*   <Link underline="hover" color="inherit" href="/">
                                Your Entities
                                        </Link> */}

                                        {location && (
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                href={`/entity/${ location.entities[ 0 ].id }`}
                                            >
                                                {location.entities[ 0 ].name}
                                            </Link>
                                        )}
                                        {location && (
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                href={`/location/${ location.id }`}
                                            >
                                                {location.name}
                                            </Link>
                                        )}

                                        {location && (
                                            <Link
                                                underline="hover"
                                                color="inherit"
                                                href={`/location/${ locationId }/addDevice`}
                                                sx={{ textDecoration: 'underline' }}
                                            >
                        Add device
                                            </Link>)}
                                    </Breadcrumbs>
                                </Typography>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Grid>
                <Grid item xs={12} md={3}>
                    <LocationInfo id={locationId} view={'Add_Device'} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card elevation={2}>
                                <Card
                                    elevation={2}
                                    style={{
                                        backgroundColor: '#F4F6F8',
                                    }}
                                >
                                    <h5 className="card-header justify-content-between align-items-center">
                    Add device form
                                    </h5>
                                </Card>

                                <Card>
                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={async(values) => {
                                            if (!latChanged || !lngChanged) {
                                                // Handle error condition here, e.g., display an alert
                                                Swal.fire('End Device position in missing.', ' Please double click on the map or drag the marker to set the end device position.', 'error');
                                                return;
                                            }

                                            Swal.fire({
                                                title: 'Do you really want to add device?',
                                                showDenyButton: true,
                                                confirmButtonColor: 'red',
                                                confirmButtonText: 'Yes',
                                                denyButtonText: 'No',
                                            }).then((result) => {
                                                if (result.isConfirmed) {

                                                    dispatch(
                                                        addDevice(locationId,values.name,values.dev_eui,values.join_eui, values.model,values.description,lng,lat,null),
                                                    ).then((response) => {
                                                        if (response.success) {
                                                            console.log('you are here')
                                                            history.push(`/location/${ locationId }`);
                                                        } else {
                                                            Swal.fire(response.result, '', 'error');
                                                        }
                                                    });
                                                } else if (result.isDenied) {
                                                    Swal.fire('Device not added', '', '');
                                                }
                                            });
                                        }}
                                        initialValues={{
                                            name: '',
                                            dev_eui: '',
                                            join_eui: '',
                                            model: '',
                                            description: '',
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            errors,
                                        }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Grid container className="m-0" spacing={2}>

                                                    <Grid item xs={12} md={6}>
                                                        <Form.Group as={Col} controlId="validationFormik01">
                                                            <Form.Label>Device dev_eui</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Device dev_eui"
                                                                name="dev_eui"
                                                                value={values.dev_eui}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.dev_eui}
                                                            />

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.dev_eui}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className="mb-3">
                                                        <Form.Group as={Col} controlId="validationFormik01">
                                                            <Form.Label>Device join_eui</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Device join_eui"
                                                                name="join_eui"
                                                                value={values.join_eui}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.join_eui}
                                                            />

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.join_eui}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Grid>

                                                    <Grid item xs={12} md={6} className="mb-3">
                                                        <Form.Group as={Col} controlId="validationFormik01">
                                                            <Form.Label>Device name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Device name"
                                                                name="name"
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.name}
                                                            />

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className="mb-3">
                                                        <Form.Group as={Col} controlId="model">
                                                            <Form.Label>Device model</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={values.model}
                                                                onChange={(e) => handleChange(e)}
                                                                isInvalid={!!errors.model}
                                                            >
                                                                <option value="" disabled>
                                  Select a model
                                                                </option>
                                                                <option value="LoRaWAN Node V1.0">LoRaWAN Node V1.0</option>

                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Grid>

                                                    <Grid item xs={12} md={6} className="mb-3">
                                                        <Form.Group as={Col} controlId="validationFormik01">
                                                            <Form.Label>Device description</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Device Description"
                                                                name="description"
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.description}
                                                            />

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.description}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Grid>
                                                    <Grid item xs={12}  className="mb-3 mr-3" >

                                                        <div className="text mb-3" >Please double click on map set the new end device position</div>
                                                        <MapComponent apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'}  setLat={setLat} setLng={setLng} socket={socket} />
                                                    </Grid>
                                                </Grid>
                                                <Modal.Footer>

                                                    <Button type="submit" className="btn btn-success">
                            Submit form
                                                    </Button>
                                                </Modal.Footer>
                                            </Form>
                                        )}
                                    </Formik>
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

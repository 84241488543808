import axios from 'axios';
import authHeader from './auth-header';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const API_URL =`${ REACT_APP_SERVER_API_URL }/api/location/devices/`;

const getDeviceList = () => {
    return axios.get(API_URL + 'getDeviceList');
};
const getDeviceListByLocationId = (locationId) => {
    return axios
        .post(
            API_URL + 'getDeviceListByLocationId',
            {
                locationId: locationId,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const getDeviceById = (id) => {
    return axios
        .post(
            API_URL + 'getDeviceById',
            {
                id: id,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const getDeviceLastMessageByDeviceId = (id) => {
    console.log('getDeviceLastMessageByDeviceId',id)
    return axios
        .post(
            API_URL + 'getDeviceLastMessageByDeviceId',
            {
                deviceId: id,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const addDevice = (locationId,name, dev_eui,join_eui, model, description,lng,lat,software_version) => {
    return axios.post(
        API_URL + 'addDevice',
        {
            locationId,name, dev_eui,join_eui, model, description,lng,lat,software_version,
        },
        {
            headers: authHeader(),
        },
    );
};
const updateDevice = (deviceId,dev_eui, name, model, description,image) => {
    return axios.post(
        API_URL + 'updateDeviceInfo',
        { deviceId,dev_eui, name, model, description,image },
        {
            headers: authHeader(),
        },
    );
};
const deleteDeviceById = (deviceId) => {
    return axios.post(
        API_URL + 'deleteDeviceById',
        {
            id: deviceId,
        },
        {
            headers: authHeader(),
        },
    );
};

const getDeviceLastMessage= (device_eui) => {
    return axios
        .post(
            API_URL + 'getDeviceLastMessage',
            {
                device_eui: device_eui,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const getDeviceLastMessages = (device_eui,period) => {
    return axios
        .post(
            API_URL + 'getDeviceLastMessages',
            {
                device_eui: device_eui,
                period:period,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const getDeviceLastActivities = (device_eui) => {
    return axios
        .post(
            API_URL + 'getDeviceLastActivities',
            {
                device_eui: device_eui,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};
const getMostActiveDevice = () => {
    return axios.get(API_URL + 'getMostActiveDevice',{
        headers: authHeader(),
    });
};

const sendDownlink = (dev_eui, port, payload, priority) => {
    return axios.post(
        API_URL + 'sendDownlink',
        {
            dev_eui,
            port,
            payload,
            priority,
        },
        {
            headers: authHeader(),
        },
    ).then((response) => {
        return response;
    });
};
export default {
    getDeviceList,
    getDeviceListByLocationId,
    addDevice,
    deleteDeviceById,
    getDeviceById,
    updateDevice,
    getDeviceLastMessage,
    getDeviceLastMessages,
    getDeviceLastActivities,
    getMostActiveDevice,
    sendDownlink,
    getDeviceLastMessageByDeviceId,
};

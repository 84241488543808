function getTokenFromUrl() {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const tokenIndex = path.indexOf('token/');

    if (tokenIndex !== -1) {
        const token = path.substring(tokenIndex + 6);
        return token;
    }

    return null;
}

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = getTokenFromUrl();
    console.log('token', token);

    if (token) {
        return { 'x-access-token': token };
    } else if (user && user.authToken) {
        return { 'x-access-token': user.authToken };
    } else {
        return {};
    }
}
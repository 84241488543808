import {
    ADD_ENTITY_SUCCESS,
    ADD_ENTITY_FAIL,
    GET_ENTITY_SUCCESS,
    GET_ENTITY_FAIL,
    DELETE_ENTITY_SUCCESS,
    DELETE_ENTITY_FAIL,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case ADD_ENTITY_SUCCESS:
        return {
            ...state,
            data: payload.data,
            isLoggedIn: true,
        };
    case ADD_ENTITY_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };

    case GET_ENTITY_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            entity:payload.entity,
        };
    case GET_ENTITY_FAIL:
        return {
            ...state,
            isLoggedIn: false,
        };
    case DELETE_ENTITY_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
        };
    case DELETE_ENTITY_FAIL:
        return {
            ...state,
            isLoggedIn: false,
        };
    default:
        return state;
    }
}

import axios from 'axios';
import authHeader from './auth-header';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const API_URL =`${ REACT_APP_SERVER_API_URL }/api/location/gateways/`;

const getGatewayList = () => {
    return axios.get(API_URL + 'getGatewayList');
};
const getGatewayListByLocationId = (locationId) => {
    return axios
        .post(
            API_URL + 'getGatewayListByLocationId',
            {
                locationId: locationId,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const getGatewayById = (id) => {
    return axios
        .post(
            API_URL + 'getGatewayById',
            {
                id: id,
            },
            {
                headers: authHeader(),
            },
        )
        .then((response) => {
            return response;
        });
};

const addGateway = (name,description, locationId,macAddress,lat,lng) => {
    return axios.post(
        API_URL + 'addGateway',
        {
            name,
            description,
            locationId,
            macAddress,
            lat,
            lng,
        },
        {
            headers: authHeader(),
        },
    );
};
const getGatewaySocket = (macAddress) => {
    return axios.post(
        API_URL + 'getGatewaySocket',
        {
            macAddress,
        },
        {
            headers: authHeader(),
        },
    );
};
const deleteGatewayById = (gatewayId) => {
    return axios.post(
        API_URL + 'deleteGatewayById',
        {
            id: gatewayId,
        },
        {
            headers: authHeader(),
        },
    );
};

const updateGateway = (gatewayId, name, eui, description,lat,lng) => {
    return axios.post(
        API_URL + 'updateGatewayInfo',
        { gatewayId, name, eui, description,lat,lng },
        {
            headers: authHeader(),
        },
    );
};
const getMostActiveGateways = () => {
    return axios.get(API_URL + 'getMostActiveGateways',{
        headers: authHeader(),
    });
};

const checkGatewayExistence = async(macAddress) => {
    try {
        const response = await axios.post(`${ API_URL }checkGatewayExistence`, { macAddress },{
            headers: authHeader(),
        });
        return response.data;
    } catch (error) {
        console.error('Error checking gateway existence:', error);
        throw error;
    }
};
export default {
    getGatewayList,
    getGatewayListByLocationId,
    addGateway,
    deleteGatewayById,
    getGatewayById,
    updateGateway,
    getMostActiveGateways,
    getGatewaySocket,
    checkGatewayExistence,

};

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import moment from 'moment'
import entityService from '../../services/entity.service';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().required(),
    // entity: yup.string().required(),
});

const InviteUserModal = ({ show,setShow,ownedEntities,entityId }) => {
    const handleClose = () => setShow(false);

    const options = [
        { value: 'admin', label: 'Admin' },
        { value: 'viewer', label: 'Viewer' },
    ];

    const entities = ownedEntities?.map(entity => ({
        value: entity.id,
        label: entity.name,
    }));
    const { user: currentUser } = useSelector((state) => state.auth);
    return (
        <>
            {currentUser && <Modal centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={async(values) => {
                            console.log('InviteUserModal')
                            console.log('InviteUserModal values',values)
                            const entity= entityId?entityId:values.entity;
                            entityService.validateUserEntityStatus(values.email,entity).then((res) => {
                                if(res.data.success){

                                    if(res.data.error === 'User is not associated or invited to this entity'){
                                        entityService.inviteUserToEntity(values.email,values.entity,values.role,currentUser.email,currentUser.username).then((response) => {
                                            if(response.data.success){
                                                handleClose()
                                                Swal.fire({
                                                    title: 'Invitation sent successfully.',
                                                    icon: 'success',
                                                    timer: 3000,
                                                });
                                            } else {
                                                handleClose()
                                                Swal.fire({
                                                    title: 'An error occured while sending inviation.',
                                                    icon: 'error',
                                                    timer: 3000,
                                                });
                                            }
                                        })
                                    } else  if(res.data.error === 'User is already invited to this entity'){

                                        entityService.inviteUserToEntity(values.email,values.entity,values.role,currentUser.email,currentUser.username).then((response) => {
                                            if(response.data.success){
                                                handleClose()
                                                Swal.fire({
                                                    title: 'Invitation sent successfully.',
                                                    icon: 'success',
                                                    timer: 3000,
                                                });
                                            } else if (response.data.error === 'Mail Already Sent.Wait for expiration') {
                                                handleClose()
                                                console.log('inviteUserToEntity response',JSON.stringify(res))
                                                // Parse the expires at time using Moment.js
                                                const expiresAtTime = moment(res.data.result[ 0 ].userEntity.expiresAt);

                                                // Get the current time using Moment.js
                                                const currentTime = moment();

                                                // Calculate the difference between current time and expires at time
                                                const timeDifference = moment.duration(expiresAtTime.diff(currentTime));

                                                // Format the time difference as per your requirement
                                                const formattedDifference = ` ${ timeDifference.minutes() } minutes, ${ timeDifference.seconds() } seconds`;

                                                Swal.fire({
                                                    title: 'Mail Already Sent.You can resend email after ',
                                                    text:`${ formattedDifference }`,
                                                    icon: 'error',
                                                    // timer: 3000,
                                                });
                                            } else  {
                                                handleClose()
                                                console.log('inviteUserToEntity response',JSON.stringify(response))
                                                Swal.fire({
                                                    title: 'An error occured while sending inviation.',
                                                    icon: 'error',
                                                    timer: 3000,
                                                });
                                            }
                                        })

                                    } else  if(res.data.error === 'User already own this entity'){
                                        handleClose()
                                        Swal.fire({
                                            title: 'You Already Own this Entity',
                                            text:`You are the ${ res.data.result[ 0 ].userEntity.role } of the Entity `,
                                            icon: 'error',
                                            // timer: 3000,
                                        });

                                    }else  if(res.data.error === 'User is already associated to this entity'){
                                        Swal.fire({
                                            title: 'You Already Own this Entity',
                                            text:`You are the ${ res.data.result[ 0 ].userEntity.role } of the Entity `,
                                            icon: 'error',
                                            // timer: 3000,
                                        });

                                    } else {
                                        Swal.fire({
                                            title: `${ JSON.stringify(res.data.error) }`,
                                            icon:'error',
                                        });
                                    }
                                }else{
                                    Swal.fire({
                                        title: `${ JSON.stringify(res.data.error) }`,
                                        icon:'error',

                                    })
                                }
                            })

                        }}

                        initialValues={{
                            email: '',
                            role:'',
                            entity:'',

                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}  className='mb-2'>

                                <Row className='mb-3'> <Form.Group as={Col} controlId="validationFormik01">
                                    <Form.Label>User email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="User email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group></Row>
                                <Row className="mb-3">

                                    <Form.Group as={Col}  controlId="validationFormik02">
                                        <Form.Label>Select Role</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="role"
                                            value={values.role}
                                            onChange={handleChange}
                                            isInvalid={!!errors.role}
                                        >
                                            <option value="" disabled>Select Role</option>
                                            {options.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">

                                    <Form.Group as={Col}  controlId="validationFormik03">
                                        <Form.Label>Select Entity</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="entity"
                                            value={values.entity}
                                            onChange={handleChange}
                                            isInvalid={!!errors.entity}
                                        >
                                            <option value="" disabled>Select Entity</option>
                                            {entities?.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    <Button variant="light" onClick={handleClose}>
                    Close
                                    </Button>
                                    <Button type="submit" className="btn btn-success">
                    Submit form
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal> }
        </>
    );
};

export default InviteUserModal;

import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { extractPressure } from '../../Utils/DecodeUplink';
import DeviceService from '../../services/device.service';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const PressureChart = ({ selectedPeriod, deviceEui, socket }) => {
    const [ lastDayPressureChartData, setLastDayPressureChartData ] = useState([]);
    const [ lastWeekPressureChartData, setLastWeekPressureChartData ] = useState([]);
    const [ lastMonthPressureChartData, setLastMonthPressureChartData ] = useState([]);
    const [ chartDataAvailable, setChartDataAvailable ] = useState(false);
    const exportToExcel = (data) => {
        const columnTitles = Object.keys(data[ 0 ]);

        // Create a worksheet with column titles and their respective values
        const worksheet = XLSX.utils.json_to_sheet(data.map(item => {
            const rowData = {};
            columnTitles.forEach(title => {
                rowData[ title ] = item[ title ];
            });
            return rowData;
        }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'PressureChartData');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([ excelBuffer ], { type: 'application/octet-stream' });
        saveAs(blob, 'PressureChartData.xlsx');
    };
    const isChartDataNull = () => {
        if (selectedPeriod === 'lastDay') {
            return lastDayPressureChartData.every(data => Number(data.averagePressure) === 0 || data.averagePressure === null || data.averagePressure === undefined);
        } else if (selectedPeriod === 'lastWeek') {
            return lastWeekPressureChartData.every(data => Number(data.averagePressure) === 0 || data.averagePressure === null || data.averagePressure === undefined);
        } else if (selectedPeriod === 'lastMonth') {
            return lastMonthPressureChartData.every(data => Number(data.averagePressure) === 0 || data.averagePressure === null || data.averagePressure === undefined);
        }
        return false;
    };
    useEffect(() => {
        if (selectedPeriod === 'lastDay' && lastDayPressureChartData.length > 0 || selectedPeriod === 'lastWeek' && lastWeekPressureChartData.length > 0 || selectedPeriod === 'lastMonth' && lastMonthPressureChartData.length > 0) {
            setChartDataAvailable(isChartDataNull());
        }
    }, [ selectedPeriod, lastDayPressureChartData, lastWeekPressureChartData, lastMonthPressureChartData ]);

    const fetchLastDayData = () => {
        if (deviceEui) {
            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const newChartData = [];
                const groupedData = response.data.result.reduce((acc, aResult) => {
                    const date = new Date(aResult.updatedAt).toLocaleDateString();
                    const hour = new Date(aResult.updatedAt).getHours();
                    const pressure = extractPressure(aResult.data);
                    const key = date + '-' + hour;

                    if (!acc[ key ]) {
                        acc[ key ] = {
                            date: date,
                            hour: hour,
                            pressures: [],
                        };
                    }

                    if (pressure !== undefined) {
                        acc[ key ].pressures.push(pressure);
                    }

                    return acc;
                }, {});

                // Define the current date and time
                const currentDate = new Date();

                // Iterate over the last 24 hours
                for (let i = 0; i < 24; i++) {
                    // Calculate the date and hour for the current iteration
                    const date = new Date(currentDate);
                    date.setHours(date.getHours() - i);

                    const dateString = date.toLocaleDateString();
                    const hour = date.getHours();

                    const key = dateString + '-' + hour;
                    const existingData = groupedData[ key ];

                    if (existingData) {
                        // If data exists for this hour, calculate the average temperature
                        const sum = existingData.pressures.reduce((total, volt) => total + volt, 0);
                        const average = sum / existingData.pressures.length;
                        existingData.averagePressure = average===0 ? average.toFixed(2) : average ? average.toFixed(2) : null;
                        newChartData.push(existingData);
                    } else {
                        // If data doesn't exist for this hour, push null
                        newChartData.push({
                            date: dateString,
                            hour: hour,
                            pressures: [],
                            averagePressure: null,
                        });
                    }
                }

                // Sort the data by date and hour
                newChartData.sort((a, b) => {
                    const dateA = new Date(
                        parseInt(a.date.split('/')[ 2 ]), // Year
                        parseInt(a.date.split('/')[ 1 ]) - 1, // Month (subtract 1 because months are zero-based)
                        parseInt(a.date.split('/')[ 0 ]), // Day
                        a.hour, // Hour
                    );
                    const dateB = new Date(
                        parseInt(b.date.split('/')[ 2 ]), // Year
                        parseInt(b.date.split('/')[ 1 ]) - 1, // Month (subtract 1 because months are zero-based)
                        parseInt(b.date.split('/')[ 0 ]), // Day
                        b.hour, // Hour
                    );

                    // Compare dates
                    if (dateA < dateB) return -1;
                    if (dateA > dateB) return 1;
                    return a.hour - b.hour;
                });
                console.log('LastDayPressureChartData', newChartData)
                setLastDayPressureChartData(newChartData);
            });
        }
    }
    const fetchLastWeekData = () => {
        if (deviceEui) {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 7); // Adjusted to last 7 days

            // Generate an array of dates for the last 8 days (including current date)
            const lastWeekDates = [];
            for (let i = 0; i < 8; i++) {
                const date = new Date(startDate);
                date.setDate(date.getDate() + i);
                lastWeekDates.push(date.toLocaleDateString());
            }

            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const dailyPressureData = {};

                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt).toLocaleDateString();
                    const pressure = extractPressure(result.data);
                    if (pressure !== undefined) {
                        if (!dailyPressureData[ date ]) {
                            dailyPressureData[ date ] = {
                                pressures: [ pressure ],
                            };
                        } else {
                            dailyPressureData[ date ].pressures.push(pressure);
                        }
                    }

                });

                const pressureChartData = lastWeekDates.map((date) => {
                    const pressures = dailyPressureData[ date ] ? dailyPressureData[ date ].pressures : [];
                    const averagePressure = pressures.length > 0 ? pressures.reduce((acc, val) => acc + val, 0) / pressures.length : null;
                    return {
                        date: date,
                        pressures: pressures,
                        averagePressure: averagePressure === 0 ? averagePressure?.toFixed(2) : averagePressure ? averagePressure?.toFixed(2) : null,
                    };
                });

                pressureChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log('LastWeekPressureChartData', pressureChartData)
                setLastWeekPressureChartData(pressureChartData);
            });
        }
    }
    const fetchLastMonthData = () => {

        if (deviceEui) {
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1); // Adjusted to last month

            // Generate an array of dates for the last 31 days (including the current date)
            const lastMonthDates = [];
            for (let i = 0; i < 31; i++) {
                const date = new Date(startDate);
                date.setDate(date.getDate() + i);
                lastMonthDates.push(date.toLocaleDateString());
            }

            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const dailyPressureData = {};

                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt).toLocaleDateString();
                    const pressure = extractPressure(result.data);
                    if (pressure !== undefined) {
                        if (!dailyPressureData[ date ]) {
                            dailyPressureData[ date ] = {
                                pressures: [ pressure ],
                            };
                        } else {
                            dailyPressureData[ date ].pressures.push(pressure);
                        }
                    }

                });

                // Calculate average pressure for each date, or set to null if not available
                const pressureChartData = lastMonthDates.map((date) => {
                    const pressures = dailyPressureData[ date ] ? dailyPressureData[ date ].pressures : [];
                    const averagePressure = pressures.length > 0 ? pressures.reduce((acc, val) => acc + val, 0) / pressures.length : null;

                    return {
                        date: date,
                        pressures: pressures,
                        averagePressure: averagePressure === 0 ? averagePressure?.toFixed(2) : averagePressure ? averagePressure?.toFixed(2) : null,
                    };
                });

                // Sort the pressure data by date
                pressureChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log('LastMonthPressureChartData', pressureChartData)
                setLastMonthPressureChartData(pressureChartData);
            });
        }
    }

    useEffect(() => {
        if (selectedPeriod) {

            if (selectedPeriod === 'lastDay') {

                fetchLastDayData()
            } else if (selectedPeriod === 'lastWeek') {
                fetchLastWeekData()
            } else if (selectedPeriod === 'lastMonth') {
                fetchLastMonthData()
            }
        }

    }, [ selectedPeriod ]);

    useEffect(() => {
        socket && socket.on('update_device_data', () => {
            console.log('update_device_data pressureChart')
            if (selectedPeriod && deviceEui) {
                if (selectedPeriod === 'lastDay') {
                    fetchLastDayData()
                } else if (selectedPeriod === 'lastWeek') {
                    fetchLastWeekData()
                } else if (selectedPeriod === 'lastMonth') {
                    fetchLastMonthData()
                }
            }
        });

    }, [ socket ]);
    const options = {
        series: [
            {
                name: 'Average Pressure',
                data: selectedPeriod === 'lastDay' ? lastDayPressureChartData?.map((val) => {
                    if (val.averagePressure !== undefined) {
                        return val.averagePressure
                    } else return null;
                }) : selectedPeriod === 'lastWeek' ? lastWeekPressureChartData?.map((val) => {

                    if (val.averagePressure !== undefined) {
                        return val.averagePressure
                    } else return null;
                }) : selectedPeriod === 'lastMonth' ? lastMonthPressureChartData?.map((val) => {
                    if (val.averagePressure !== undefined) {
                        return val.averagePressure
                    } else return null;
                }) : null,
                color: '#007500',
            },
        ],
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        chart: {
            height: 350,
            width: '90%',
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [
                        {
                            icon: '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>',
                            title: 'Export to Excel', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {

                                const data = selectedPeriod === 'lastDay' ? lastDayPressureChartData?.map((val) => {
                                    return { date: `${ val.date } ${ val.hour }h`, pressures: JSON.stringify(val.pressures), averagePressure: val.averagePressure }
                                }) : selectedPeriod === 'lastWeek' ? (lastWeekPressureChartData?.map((val) => {
                                    return { date: `${ val.date }`, pressures: JSON.stringify(val.pressures), averagePressure: val.averagePressure }
                                })) : selectedPeriod === 'lastMonth' ? (lastMonthPressureChartData?.map((val) => {
                                    return { date: `${ val.date }`, pressures: JSON.stringify(val.pressures), averagePressure: val.averagePressure }
                                })) : null
                                exportToExcel(data)
                            },
                        },
                    ],
                },
            },
        },
        colors: [ '#77B6EA' ],
        dataLabels: {
            enabled: false,
            formatter: function(val) {
                if (val) {
                    return val?.toFixed(2);
                } else if (val === 0) {
                    return val?.toFixed(2)
                }
            },
        },
        markers: {
            size: 5,
            hover: {
                size: 9,
            },
        },
        xaxis: {
            type: 'category',
            categories:
                selectedPeriod === 'lastDay' ? lastDayPressureChartData?.map((res) => {
                    const formattedHour = res.hour < 10 ? `0${ res.hour }` : res.hour;
                    return `${ res.date } ${ formattedHour }h`;
                }) : selectedPeriod === 'lastWeek' ? lastWeekPressureChartData?.map((res) => {

                    return `${ res.date }`;
                }) : selectedPeriod === 'lastMonth' ? lastMonthPressureChartData?.map((res) => {

                    return `${ res.date }`;
                }) : null,
            tickPlacement: 'between',
            position: 'bottom',
            labels: {
                show: true,
                rotate: -65,
                rotateAlways: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 120,

            },

            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },

        },
        legend: {
            enabled: true,
        },
        tooltip: {
            enabled: true,
            followCursor: false,
            hideEmptySeries: false,
            onDatasetHover: {
                highlightDataSeries: true,
            },
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(2) + 'Bar';
                    } else if (val === 0) {
                        return 0.01 + 'Bar';
                    }
                },
            },
        },

        yaxis: {
            labels: {
                formatter: (val) => {
                    if (val) {

                        return Number(parseFloat(val.toFixed(2)))
                    }
                },
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 150,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: -12,
                offsetY: 0,
                rotate: 0,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
            title: {
                text: 'Average Pressure (Bar)',
                rotate: -90,
                offsetX: 12,
                offsetY: 0,
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                left: 0,
                right: 0,
            },
        },
    };
    return (
        <div id="chart" >
            {selectedPeriod && !chartDataAvailable && <ReactApexChart options={options} series={options.series} type="line" height={options.chart.height} />}
            {selectedPeriod && chartDataAvailable && <Typography variant="body1" color="text.secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',  marginTop: '25%',
                marginBottom: '25%',
                height: '100%',
                textAlign: 'center',
            }}>No coherent data available to display</Typography>}
        </div>
    );
};

export default PressureChart;
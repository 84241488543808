import React, { useState } from 'react';
import { GoogleMap, Marker, useLoadScript, Circle, InfoWindow, OverlayView } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceListByLocationId } from '../../actions/deviceAction';
import { getGatewayListByLocationId } from '../../actions/gatewayAction';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import point from '../../assets/point.png'
import gatewayIcon from '../../assets/gatewayIcon.png'
import gatewayIconConnected from '../../assets/GatewayIconConnected.png'
import gatewayIconDisconnected from '../../assets/GatewayIconDisconnected.png'
import deviceIcon from '../../assets/deviceIcon.png'
import DeviceImage from '../deviceImage';
import deviceService from '../../services/device.service';
import { getDeviceById } from '../../actions/deviceAction';
function MapComponent({ setLat, setLng, deviceListView, apiKey, socket ,gatewayId,lat,lng }) {
    const center = React.useMemo(() => ({
        lat: 36.44035,
        lng: 10.70592,
    }), []);
    const dispatch = useDispatch();
    const { deviceList } = useSelector((state) => state.deviceList);
    const { gatewayList } = useSelector((state) => state.gatewayList);
    const { device } = useSelector((state) => state.device);
    const { location } = useSelector((state) => state.location);
    const [ mapCenter, setMapCenter ] = useState(center);
    const { locationId } = useParams();
    const [ markers, setMarkers ] = useState([]);
    const [ deviceMarkers, setDeviceMarkers ] = useState([]);
    const [ gatewayMarkers, setGatewayMarkers ] = useState([]);
    const [ zoom, setZoom ] = useState();
    const [ openInfoWindow, setOpenInfoWindow ] = useState(null);
    const [ input0, setInput0 ] = useState(null);
    const [ input1, setInput1 ] = useState(null);
    const [ output0, setOutput0 ] = useState(null);
    const [ output1, setOutput1 ] = useState(null);
    const [ ph, setPh ] = useState(null);
    const [ batteryVoltage, setBatteryVoltage ] = useState(null);
    const [ pressure, setPressure ] = useState(null);
    const [ temperature, setTemperature ] = useState(null);
    const [ selectedMarker, setMarker ] = useState(null);
    const [ initialPosition, setInitialPosition ] = useState(null);
    const currentPath = window.location.pathname;
    const handleMarkerClick = (marker) => {
        setMarker(marker)
        dispatch(getDeviceById(marker.id));
        deviceService.getDeviceLastMessageByDeviceId(marker.id).then((response) => {
            console.log('response.data.success', response.data.success)
            if (response.data.success) {
                setPh(response.data.result.pH)
                setTemperature(response.data.result.temperature)
                setBatteryVoltage(response.data.result.batteryVoltage)
                setPressure(response.data.result.pressure)
                setInput0(response.data.result.input1)
                setInput1(response.data.result.input2)
                setOutput0(response.data.result.output1)
                setOutput1(response.data.result.output2)
                if (currentPath === `/location/${ locationId }`)
                    setOpenInfoWindow(marker);
            }
        })

    };

    const handleMapClick = () => {
        setOpenInfoWindow(null);
    };

    React.useEffect(() => {

        socket && socket.on('Get_Confirm_Config', () => {
            console.log('Get_Confirm_Config')
        });

        socket && socket.on('update_device_data', () => {
            if (selectedMarker) {
                dispatch(getDeviceById(selectedMarker.id));
                deviceService.getDeviceLastMessageByDeviceId(selectedMarker.id).then((response) => {
                    console.log('response.data.success', response.data.success)
                    if (response.data.success) {
                        setPh(response.data.result.pH)
                        setTemperature(response.data.result.temperature)
                        setBatteryVoltage(response.data.result.batteryVoltage)
                        setPressure(response.data.result.pressure)
                        setInput0(response.data.result.input1)
                        setInput1(response.data.result.input2)
                        setOutput0(response.data.result.output1)
                        setOutput1(response.data.result.output2)
                    }
                })
            }

        });
        socket && socket.on('Get_TTN_Connect_Status', () => {
            console.log('Get_TTN_Connect_Status');

        })
        socket && socket.on('gateway_status_changed', () => {
            console.log('gateway_status_changed');
            dispatch(getGatewayListByLocationId(locationId));
        })
        return () => {
            //socket.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ socket, selectedMarker ]);
    React.useEffect(() => {
        dispatch(getDeviceListByLocationId(locationId));
        dispatch(getGatewayListByLocationId(locationId));
    }, [ dispatch, locationId ]);

    React.useEffect(() => {
        if (deviceList) {
            const existingMarkers = deviceList.map(device => ({
                id: device.id,
                name: device.name,
                lat: device.lat,
                lng: device.lng,
                fromDeviceList: true,
            }));
            setDeviceMarkers(existingMarkers)
        }
    }, [ deviceList ]);
    React.useEffect(() => {
        if (gatewayList) {
            const existingMarkers = gatewayList.map(device => ({
                id: device.id,
                name: device.name,
                lat: device.lat,
                lng: device.lng,
                fromDeviceList: true,
                status:device.status,
            }));
            setGatewayMarkers(existingMarkers)
        }
    }, [ gatewayList ]);
    const isInsideCircle = (position, location) => {
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(position.lat, position.lng),
            new window.google.maps.LatLng(location.lat, location.lng),
        );
        return distance <= location.radius;
    };
    const [ isNewMarkerAdded, setIsNewMarkerAdded ] = useState(false);
    React.useEffect(() => {
        if (deviceList) {
            console.log('Map deviceList ', JSON.stringify(deviceList));
        }
    }, [ deviceList ]);

    React.useEffect(() => {
        if (location) {
            if (location.radius < 50) {
                setZoom(22);
            } else if (location.radius >= 50 && location.radius < 100) {
                setZoom(19);
            } else if (location.radius >= 100 && location.radius < 400) {
                setZoom(17);
            } else if (location.radius >= 400 && location.radius < 800) {
                setZoom(16);
            } else if (location.radius >= 800 && location.radius < 1700) {
                setZoom(15);
            } else if (location.radius >= 1700) {
                setZoom(14);
            } else {
                setZoom(13);
            }
            setMapCenter({ lng: location?.lng, lat: location?.lat })
        }
    }, [ location ]);

    const onMapClick = (event) => {
        const clickedPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };

        if (isInsideCircle(clickedPosition, location)) {
            if (!isNewMarkerAdded) {
                const newMarker = {
                    lat: clickedPosition.lat,
                    lng: clickedPosition.lng,
                    isNew: true,
                };
                setMarkers(prevMarkers => [ ...prevMarkers, newMarker ]);
                setIsNewMarkerAdded(true);
            } else {
                const updatedMarkers = markers.map((marker, index) => {
                    if (index === markers.length - 1 && marker.isNew) {
                        return {
                            ...marker,
                            lat: clickedPosition.lat,
                            lng: clickedPosition.lng,
                        };
                    }
                    return marker;
                });
                setMarkers(updatedMarkers);
            }
            setMapCenter(clickedPosition);
            console.log('setLat', clickedPosition.lat)
            console.log('setLng', clickedPosition.lng)
            setLat(clickedPosition.lat);
            setLng(clickedPosition.lng);
        } else {
            Swal.fire({
                icon: 'error',
                text: 'You can only place markers inside the location circle.',
            });
        }
    };

    const options = {
        language: 'french',
        gestureHandling: 'cooperative',
        mapTypeId: 'hybrid',
        labels: true,
        styles: [
            { featureType: 'poi.business', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.medical', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.attraction', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.government', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.park', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.school', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.sports_complex', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
            { featureType: 'poi.place_of_worship', elementType: 'labels', stylers: [ { visibility: 'on' } ] },
        ],
    };

    const libraries = [ 'places' ];
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <div>
            <GoogleMap
                key={1}
                mapContainerStyle={{ width: '100%', height: '600px' }}
                center={mapCenter}
                zoom={zoom ? zoom : 12}
                options={options}
                onDblClick={!deviceListView && !gatewayId && onMapClick}
                onClick={handleMapClick}
            >
                {markers.map((marker, index) => (
                    <React.Fragment key={`marker-${ index }`}>
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            draggable={marker.isNew}
                            onDragEnd={(event) => {
                                !deviceListView && onMapClick(event)
                            }}
                            icon={marker.isNew && currentPath === `/location/${ locationId }/addGateway` ? gatewayIcon : marker.isNew && currentPath === `/location/${ locationId }/addDevice` ? deviceIcon : undefined}
                        />
                        {!marker.isNew && (
                            <OverlayView
                                position={{ lat: marker.lat, lng: marker.lng }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div className="custom-marker-label">
                                    {marker?.name}
                                </div>
                            </OverlayView>
                        )}
                    </React.Fragment>
                ))}
                {deviceMarkers.map((marker, index) => (
                    <React.Fragment key={`deviceMarker-${ index }`}>
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            draggable={marker.isNew}
                            onDragEnd={(event) => {
                                !deviceListView && onMapClick(event)
                            }}
                            onMouseOver={() => {
                                if (!marker.isNew && currentPath === `/location/${ locationId }`) {
                                    handleMarkerClick(marker)
                                }
                            }}
                            onClick={() => {
                                if (!marker.isNew && currentPath === `/location/${ locationId }`) {
                                    window.location.href = (`/location/${ location?.id }/devices/${ marker?.id }`);
                                }
                            }}
                            icon={{
                                url: deviceIcon,
                            }}
                        >
                            {openInfoWindow === marker && (
                                <InfoWindow position={{ lat: marker.lat, lng: marker.lng }} onCloseClick={() => setOpenInfoWindow(null)}>
                                    <DeviceImage
                                        ph={ph}
                                        pressure={pressure}
                                        temperature={temperature}
                                        input0={input0}
                                        input1={input1}
                                        out1={output0}
                                        out2={output1}
                                        batteryVoltage={batteryVoltage}
                                        device={device}
                                        location={location}
                                    />
                                </InfoWindow>
                            )}
                        </Marker>
                        {!marker.isNew && (
                            <OverlayView
                                position={{ lat: marker.lat, lng: marker.lng }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div className="custom-marker-label">
                                    {marker?.name}
                                </div>
                            </OverlayView>
                        )}
                    </React.Fragment>
                ))}
                {gatewayMarkers.map((marker, index) => (

                    <React.Fragment key={`gatewayMarker-${ index }`}>
                        <Marker
                            key={index}
                            icon={{
                                url:marker.status? gatewayIconConnected:gatewayIconDisconnected,
                            }}
                            position={{ lat: lat?lat:marker.lat, lng:lng?lng: marker.lng }}
                            draggable={marker.isNew||marker?.id=== Number(gatewayId)}
                            onDragStart={(event) => {
                                // Save the initial position when dragging starts
                                setInitialPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
                            }}
                            onDragEnd={(event) => {
                                //  console.log('isTrue', marker?.id=== Number(gatewayId))
                                if(!deviceListView && marker?.id!== Number(gatewayId)){
                                    onMapClick(event)
                                }
                                if(marker?.id=== Number(gatewayId)){
                                    const clickedPosition = {
                                        lat: event.latLng.lat(),
                                        lng: event.latLng.lng(),
                                    };

                                    if (isInsideCircle(clickedPosition, location)) {
                                        setLat(event.latLng.lat())
                                        setLng(event.latLng.lng())
                                        setMapCenter({ lng: event.latLng.lng(), lat:event.latLng.lat() })
                                        setInitialPosition(clickedPosition);
                                        console.log('new position',  { lat: event.latLng.lat(),
                                            lng: event.latLng.lng() })
                                    } else {
                                        if (initialPosition) {
                                            setLat(initialPosition.lat)
                                            setLng(initialPosition.lng)
                                            setInitialPosition({ lat,lng })
                                        }
                                        Swal.fire({
                                            icon: 'error',
                                            text: 'You can only place markers inside the location circle.',
                                        });
                                    }
                                }
                            }}
                            onClick={() => {
                                if (!marker.isNew && currentPath === `/location/${ locationId }`) {
                                    window.location.href = (`/location/${ location?.id }/gateway/${ marker?.id }`);
                                }
                            }}
                        />
                        {!marker.isNew && (
                            <OverlayView
                                position={{ lat:lat?lat: marker.lat, lng:lng?lng: marker.lng }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div className="custom-marker-label">
                                    {marker?.name}
                                </div>
                            </OverlayView>
                        )}
                    </React.Fragment>
                ))}
                {location && (
                    <>
                        <Circle
                            center={{ lng: location?.lng, lat: location?.lat }}
                            radius={location?.radius}
                            options={{
                                fillColor: '#7dfdfd',
                                fillOpacity: 0.35,
                                strokeColor: '#7dfdfd',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                zIndex: 1,
                            }}
                        />
                        <Marker
                            position={{ lng: location?.lng, lat: location?.lat }}
                            icon={{
                                url: point,
                                scaledSize: new window.google.maps.Size(12, 12),
                                anchor: new window.google.maps.Point(10, 10),
                            }}
                        />
                    </>
                )}
            </GoogleMap>
        </div>
    );
}

export default MapComponent;
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_MESSAGE,
    UPDATE_USER_SUCCESS,
} from './types';
import io from 'socket.io-client';
import AuthService from '../services/auth.service';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const SOCKET_IO_URL = REACT_APP_SERVER_API_URL;
export const register = (firstname,lastname,username, email, password,address,phoneNumber) => (dispatch) => {
    return AuthService.register(firstname,lastname,username, email, password,address,phoneNumber).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message||response.data.result,
            });

            return Promise.resolve();
        },

    ).catch( (error) => {
        const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

        dispatch({
            type: REGISTER_FAIL,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: message,
        });

        return Promise.reject();
    });
};

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (data) => {
            if(data.success){
                console.log('data.result.id',data.result.id)
                const socket = io(SOCKET_IO_URL);
                socket.on('connect', () => {
                    console.log('socket.id',socket.id)
                    AuthService.createUserSocket(data.result.id, socket.id).then((response) => {
                        console.log('createUserSocket',response)
                    });
                    const userWithSocket = {
                        ...data.result,
                        socket: socket,
                    };
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { user: userWithSocket },
                    });
                    return Promise.resolve();
                })
            }else{
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.result,
                });

                return Promise.reject();
            }
        },
    );
};

export const getUserById = (userId) => (dispatch) => {
    console.log('getUserById action')
    return AuthService.getUserById(userId).then(
        (data) => {
            if(data.success){
                console.log('getUserById action response',JSON.stringify(data))
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: { user: data.result },
                });
                return Promise.resolve();

            }else{
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.result,
                });

                return Promise.reject();
            }
        },
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};

import {
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAIL,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAIL,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAIL,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case ADD_LOCATION_SUCCESS:
        return {
            ...state,
            data: payload.data,
            isLoggedIn: true,
        };
    case ADD_LOCATION_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };

    case GET_LOCATION_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            user: payload.user,
            location:payload.location,
        };
    case GET_LOCATION_FAIL:
        return {
            ...state,
            isLoggedIn: false,
            user: null,
        };
    case DELETE_LOCATION_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            user: payload.user,
        };
    case DELETE_LOCATION_FAIL:
        return {
            ...state,
            isLoggedIn: false,
            user: null,
        };
    default:
        return state;
    }
}

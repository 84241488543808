import axios from 'axios';
import authHeader from './auth-header';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const API_URL =`${ REACT_APP_SERVER_API_URL }/api/entity/`;

const getEntitiesList = () => {
    return axios.get(API_URL + 'getEntitiesList',{
        headers: authHeader(),
    });
};
const getEntitiesListByOwnerId = (ownerId) => {
    return axios
        .post(API_URL + 'getEntitiesListByOwnerId', {
            userId: ownerId,
        },{
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};
const getEntityById = (locationId) => {
    return axios
        .post(API_URL + 'getEntityById', {
            id: locationId,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};
const addEntity = (name, address,image,userId,ownerId) => {
    return axios.post(API_URL + 'addEntity', {
        name,
        address,
        image,userId,ownerId,
    }, {
        headers: authHeader(),
    });
};

const updateEntity = (locationId,name, image, city, state, zipCode, description, userId) => {
    return axios.post(API_URL + 'updateEntity', {
        locationId,
        name,
        image,
        city,
        state,
        zipCode,
        description,
        userId,
    }, {
        headers: authHeader(),
    });
};

const deleteEntityByOwnerId = (entityId,ownerId) => {
    console.log('entityId',entityId)
    console.log('ownerId',ownerId)
    return axios.post(API_URL + 'deleteEntityByOwnerId', {
        entityId: entityId,
        ownerId: ownerId,
    }, {
        headers: authHeader(),
    });
};

const inviteUserToEntity = (email,entityId,role,senderEmail,senderName) => {
    return axios
        .post(API_URL + 'inviteUserToEntity', {
            email,
            entityId,
            role,
            senderEmail,
            senderName,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};

const validateUserEntityStatus = (email,entityId) => {
    return axios
        .post(API_URL + 'validateUserEntityStatus', {
            email,
            entityId,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};

const updateInvitationStatus = (userId, entityId, status ) => {
    return axios
        .post(API_URL + 'updateInvitationStatus', {
            userId, entityId, status,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};

const getEntityByLocationId = (locationId ) => {
    return axios
        .post(API_URL + 'getEntityByLocationId', {
            locationId,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};

const setUserRecentEntity = (userId,entityId) => {
    return axios.post(API_URL + 'setUserRecentEntity', {
        userId: userId,
        entityId: entityId,
    }, {
        headers: authHeader(),
    });
};
export default {
    getEntitiesList,
    addEntity,
    getEntitiesListByOwnerId,
    getEntityById,
    updateEntity,
    inviteUserToEntity,
    updateInvitationStatus,
    getEntityByLocationId,
    validateUserEntityStatus,
    deleteEntityByOwnerId,
    setUserRecentEntity,
};

import io from 'socket.io-client';
import authService from './services/auth.service';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const SOCKET_IO_URL = REACT_APP_SERVER_API_URL;
const socket = io(SOCKET_IO_URL);
const user = JSON.parse(localStorage.getItem('user'));
socket.on('user_connected', () => {
    if (socket.id && user) {
        authService.createUserSocket(user.id, socket.id).then((response) => {
            console.log('createUserSocket',response)
        });
    } else {
        console.log('Socket or user is not available yet.');
    }
});

export default socket;

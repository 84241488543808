import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';
import { SocketProvider } from './SocketContext';
ReactDOM.render(
    <Provider store={ store }>
        <SocketProvider>
            <App />
        </SocketProvider>
    </Provider>,
    document.getElementById('root'),
);

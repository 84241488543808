export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const ADD_ENTITY_SUCCESS = 'ADD_ENTITY_SUCCESS';
export const ADD_ENTITY_FAIL = 'ADD_ENTITY_FAIL';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAIL = 'GET_ENTITY_FAIL';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAIL = 'DELETE_ENTITY_FAIL';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_FAIL = 'UPDATE_ENTITY_FAIL';

export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAIL = 'ADD_DEVICE_FAIL';
export const GET_DEVICES_LIST_SUCCESS = 'GET_DEVICES_LIST_SUCCESS';
export const GET_DEVICES_LIST_FAIL = 'GET_DEVICES_LIST_FAIL';

export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAIL = 'DELETE_DEVICE_FAIL';

export const ADD_GATEWAY_SUCCESS = 'ADD_GATEWAY_SUCCESS';
export const ADD_GATEWAY_FAIL = 'ADD_GATEWAY_FAIL';
export const DELETE_GATEWAY_SUCCESS = 'DELETE_GATEWAY_SUCCESS';
export const DELETE_GATEWAY_FAIL = 'DELETE_GATEWAY_FAIL';
export const GET_GATEWAYS_LIST_SUCCESS = 'GET_GATEWAYS_LIST_SUCCESS';
export const GET_GATEWAYS_LIST_FAIL = 'GET_GATEWAYS_LIST_FAIL';
export const GET_GATEWAY_SUCCESS = 'GET_GATEWAY_SUCCESS';
export const GET_GATEWAY_FAIL = 'GET_GATEWAY_FAIL';

export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAIL = 'GET_DEVICES_FAIL';

export const GET_DEVICE_LAST_MESSAGE_SUCCESS ='GET_DEVICE_LAST_MESSAGE_SUCCESS';
export const GET_DEVICE_LAST_MESSAGE_FAIL ='GET_DEVICE_LAST_MESSAGE_FAIL'

export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAIL = 'UPDATE_DEVICE_FAIL';

export const UPDATE_GATEWAY_SUCCESS = 'UPDATE_GATEWAY_SUCCESS';
export const UPDATE_GATEWAY_FAIL = 'UPDATE_GATEWAY_FAIL';

// eslint-disable-next-line react-hooks/exhaustive-deps
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { logout } from '../../actions/auth';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import isLoggedIn from '../isLoggedIn';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import EventBus from '../../common/EventBus';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import entityService from '../../services/entity.service';
import { clearMessage } from '../../actions/message';
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddEntityModal from '../Modal/addEntity';
import InviteUserModal from '../Modal/inviteUserModal';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditEntityModal from '../Modal/editEntity';
import ListIcon from '@mui/icons-material/List';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const theme = createTheme({
    palette: {
        primary: {
            main: '#666 !important',
        },
    },
});
function Navbar({ recentEntity, setRecentEntity }) {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [ ownedEntities, setOwnedEntities ] = React.useState([])
    /*     const [ anchorElNav, setAnchorElNav ] = React.useState(null); */
    const [ anchorElUser, setAnchorElUser ] = React.useState(null);
    const [ openAddEntity, setOpenAddEntity ] = React.useState(false)
    const [ open, setOpen ] = React.useState(false);
    const [ openSetting, setOpenSetting ] = React.useState(false);
    const [ entityID, setEntityID ] = React.useState(null)
    const [ openInviteUser, setOpenInviteUser ] = React.useState(false);
    const [ openEditEntity, setOpenEditEntity ] = React.useState(false);
    const SaveSelectedEntity = (entity) => {
        console.log('SaveSelectedEntity entity', entity)
        setRecentEntity(entity)
    };
    React.useEffect(() => {
        const currentPathname = window.location.pathname;
        if (currentPathname.includes('/entity/')) {
            console.log('===>currentPathname', currentPathname)
            const entityId = currentPathname.split('/').pop();
            console.log('Entity ID:', entityId);
            setEntityID(entityId);
        }
    }, []);

    const getEntitiesListByOwnerId = () => {
        if (currentUser && currentUser.id) {
            entityService.getEntitiesListByOwnerId(currentUser.id).then((response) => {
                if (response.data.success) {
                    setOwnedEntities(response.data.result)
                    //                 console.log('response.data.result.locations', response.data.result)
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const setUserRecentEntity = (entity, deleteMode) => {
        console.log('call setUserRecentEntity navbar ')
        handleCloseUserMenu()
        if (currentUser && currentUser.id) {
            entityService.setUserRecentEntity(currentUser.id, entity.id).then((response) => {
                if (response.data.success) {
                    if (entity) {
                        console.log('call setUserRecentEntity navbar entity', entity)
                        console.log('entity', entity)
                        const userDataString = localStorage.getItem('user');
                        const userData = JSON.parse(userDataString);
                        userData.recentEntityId = entity.id;
                        localStorage.setItem('user', JSON.stringify(userData));
                        console.log('localStorage.getItem(\'user\')', localStorage.getItem('user'))
                        //
                        setRecentEntity(entity)
                        if (deleteMode) {
                            window.location.href = `/entity/${ entity.id }`
                        } else {
                            console.log(' history.push entity.id', entity.id)
                            window.location.href = `/entity/${ entity.id }`
                        }
                    } else {
                        console.error('entity not found')
                    }
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
    }
    const changeLocation = (entity, locationId) => {
        setUserRecentEntity(entity)
        window.location.href = `/location/${ locationId }`
    }

    const handleAddEntity = () => {
        setAnchorElUser(null);
        setOpenAddEntity(true)
    }
    React.useEffect(() => {
        if (currentUser) {
            getEntitiesListByOwnerId()
        }
        dispatch(clearMessage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentUser ])
    /*  const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }; */
    const handleOpenUserMenu = (event) => {
        //getEntitiesListByOwnerId()
        console.log('handleOpenUserMenu')
        setAnchorElUser(event.currentTarget);
    };
    const handleOpenInviteUser = () => {
        setAnchorElUser(null);
        setOpenInviteUser(true)
    };
    /*  const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }; */
    const handleClick = () => {
        setOpen(!open);
    };
    const handleClickSetting = () => {
        setOpenSetting(!openSetting);
    };
    const logOut = React.useCallback(() => {
        setRecentEntity(null)
        setAnchorElUser(null)
        dispatch(logout());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dispatch ]);
    React.useEffect(() => {
        EventBus.on('logout', () => {
            logOut();
        });
        return () => {
            EventBus.remove('logout');
        };
    }, [ currentUser, logOut ]);
    const handleDeleteEntity = () => {
        setAnchorElUser(null);
        getEntitiesListByOwnerId()
        Swal.fire({
            title: 'Select entity to delete',
            input: 'select',
            inputPlaceholder: 'Select a entity',
            inputOptions: ownedEntities.reduce((options, entity) => {
                options[ entity.id ] = entity.name;
                return options;
            }, {}),
            inputValidator: (value) => {

                return new Promise((resolve) => {
                    if (!value) {
                        resolve('You need to select an entity ');
                    } else {
                        resolve();
                    }
                });
            },
            showCancelButton: true,
            confirmButtonText: 'Delete',
            showLoaderOnConfirm: true,
            preConfirm: async(selectedEntityId) => {
                console.log('selectedEntityId', selectedEntityId)
                try {
                    const confirmationResult = await Swal.fire({
                        title: 'Do you really want to delete this entity?',
                        showDenyButton: true,
                        confirmButtonColor: 'red',
                        confirmButtonText: 'Yes',
                        denyButtonText: 'No',
                    });
                    if (confirmationResult.isConfirmed) {
                        const response = await entityService.deleteEntityByOwnerId(selectedEntityId, currentUser.id);
                        if (response.data.success) {
                            console.log('===>selectedEntityId', selectedEntityId)
                            console.log('===>entityID', entityID)
                            console.log('===>selectedEntityId===entityID', selectedEntityId === entityID)
                            if (selectedEntityId === entityID) {
                                console.log('you are here 2', ownedEntities[ 0 ])
                                window.location.href = `/entity/${ ownedEntities[ 0 ].id }`
                                SaveSelectedEntity(ownedEntities[ 0 ]);
                                setUserRecentEntity(ownedEntities[ 0 ], true)
                                getEntitiesListByOwnerId();
                            } else {
                                getEntitiesListByOwnerId();
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Cannot delete entity...',
                                text: 'Something went wrong!',
                            });
                        }
                    } else if (confirmationResult.isDenied) {
                        console.log('Deletion canceled');
                        Swal.fire('Entity not deleted', '', 'info');
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong!',
                    });
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {

            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'Entity deleted successfully!',
                });
            }
        });
    }; const [ entityAnchorEl, setEntityAnchorEl ] = React.useState(null);
    const handleClickEntity = (event) => {
        setEntityAnchorEl(event.currentTarget);
    };
    const handleCloseEntity = () => {
        setEntityAnchorEl(null);
    };

    const [ locationAnchorEl, setLocationAnchorEl ] = React.useState(null);
    const handleClickLocation = (event) => {
        setLocationAnchorEl(event.currentTarget);
    };
    const handleCloseLocation = () => {
        setLocationAnchorEl(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: '#28A745 !important',
                    color: 'white !important',
                    width: '99vw !important',
                    marginBottom:2,
                }}
            >
                <Container maxWidth="100vw">
                    <AddEntityModal openAddEntity={openAddEntity} setOpenAddEntity={setOpenAddEntity} handleAddEntity={handleAddEntity} navbar={true} setUserRecentEntity={setUserRecentEntity} />
                    <InviteUserModal show={openInviteUser} setShow={setOpenInviteUser} ownedEntities={ownedEntities} entityId={entityID} />
                    <EditEntityModal show={openEditEntity} setShow={setOpenEditEntity} ownedEntities={ownedEntities} />
                    {currentUser && (<Toolbar disableGutters>
                        <Link style={{ textDecoration: 'none', color: 'inherit' }} color="inherit" href={isLoggedIn ? '/Entity' : '/login'}>
                            <Typography
                                variant="h6"
                                noWrap
                                className="navbar-brand"
                                sx={{
                                    mr: '2 !important',
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace !important',
                                    fontWeight: '700 !important',
                                    letterSpacing: '.3rem !important',
                                    color: 'inherit !important',
                                    textDecoration: 'none !important',
                                }}
                            >
                                Watermon
                            </Typography></Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Button
                                onClick={handleClickEntity}
                                sx={{
                                    color: 'white !important',
                                }}
                                component={Link}
                                to="/Location"
                            >
                                Entities
                                {entityAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>

                            <Button
                                onClick={handleClickLocation}
                                sx={{
                                    color: 'white !important',
                                }}
                                component={Link}
                                to="/Location"
                            >
                                Locations
                                {locationAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>

                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component={Link}
                            to={isLoggedIn ? '/Entity' : '/login'}
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: '2 !important',
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace !important',
                                fontWeight: '700 !important',
                                letterSpacing: '.3rem !important',
                                color: 'inherit !important',
                                textDecoration: 'none !important',
                            }}
                        >
                            Watermon
                        </Typography>
                        {currentUser && (
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <Button
                                    onClick={handleClickEntity}
                                    sx={{
                                        color: 'white !important',
                                    }}
                                    component={Link}
                                >
                                    <ListIcon />
                                    {entityAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={entityAnchorEl}
                                    keepMounted
                                    open={Boolean(entityAnchorEl)}
                                    onClose={handleCloseEntity}
                                    TransitionComponent={Fade}
                                >
                                    {ownedEntities && ownedEntities.length > 0 ? (
                                        ownedEntities.map((entity) => (
                                            <MenuItem
                                                sx={{ cursor: 'pointer', width: '13vw' }}
                                                key={entity.id}
                                                onClick={() => setUserRecentEntity(entity)}
                                            >
                                                <ListItemText sx={{ pl: 2 }} primary={entity.name} />
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>
                                            <ListItemText primary="No entities" />
                                        </MenuItem>
                                    )}
                                </Menu>
                                <Button
                                    onClick={handleClickLocation}
                                    sx={{
                                        color: 'white !important',
                                    }}
                                    component={Link}
                                    to="/Location"
                                >
                                    <LocationOnIcon />
                                    {locationAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={locationAnchorEl}
                                    keepMounted
                                    open={Boolean(locationAnchorEl)}
                                    onClose={handleCloseLocation}
                                    TransitionComponent={Fade}
                                >
                                    {ownedEntities.length > 0 && recentEntity && ownedEntities.some(entity => entity.id === recentEntity.id && entity.locations && entity.locations.length > 0) ? (
                                        ownedEntities.map((entity) => {
                                            if (entity.id === recentEntity.id && entity.locations && entity.locations.length > 0) {
                                                return entity.locations.map((location, index) => (
                                                    <MenuItem
                                                        sx={{ cursor: 'pointer', width: '13vw' }}
                                                        key={index}
                                                        onClick={() => changeLocation(entity, location.id)}
                                                    >
                                                        <ListItemText sx={{ pl: 2 }} primary={location.name} />
                                                    </MenuItem>
                                                ));
                                            }
                                            return null;
                                        })
                                    ) : (
                                        <MenuItem disabled>
                                            <ListItemText primary="No Locations" />
                                        </MenuItem>
                                    )}
                                </Menu>

                            </Box>
                        )}
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                right: 0,
                            }}
                        >
                            {isLoggedIn && currentUser && ownedEntities ? (<>
                                <Tooltip title="">
                                    <>
                                        <IconButton
                                            onClick={(event) => {
                                                handleOpenUserMenu(event)
                                            }}
                                            sx={{ p: 0, display: { xs: 'flex', md: 'none' } }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <AccountCircleSharpIcon sx={{ backgroundColor: 'transparent', color: 'white' }} />
                                            </Box>
                                        </IconButton>
                                        <IconButton
                                            onClick={(event) => {
                                                handleOpenUserMenu(event)
                                            }}
                                            sx={{ p: 0, display: { xs: 'none', md: 'flex' } }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="subtitle1" sx={{ color: 'white', textTransform: 'capitalize' }}>
                                                    {currentUser.username}
                                                </Typography>
                                                {recentEntity && <Typography variant="subtitle2" sx={{ color: 'white', textAlign: 'right', textTransform: 'capitalize' }}>
                                                    {recentEntity?.name} ({recentEntity?.users?.find(user => user.username === currentUser.username)?.userEntity?.role})
                                                </Typography>}
                                            </Box>
                                            <ArrowDropDownIcon sx={{ color: 'white' }} />
                                        </IconButton></>
                                </Tooltip>
                                <Popover
                                    id="menu-appbar"
                                    className='mr-4'
                                    sx={{ mt: '45px', mr: '-15px' }}
                                    open={Boolean(anchorElUser)}
                                    anchorEl={anchorElUser}
                                    onClose={handleCloseUserMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <List style={{ display: 'block', right: '0 !important' }}>
                                        <ListItem divider disableRipple>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <AccountCircleSharpIcon className='mr-1' />
                                                <div>
                                                    <Typography variant="body1">{currentUser.username}</Typography>
                                                    {recentEntity && <Typography variant="body2" color="text.secondary">{recentEntity.name} ({recentEntity?.users?.find(user => user.username === currentUser.username)?.userEntity?.role})</Typography>}
                                                </div>
                                            </div>
                                        </ListItem>
                                        <ListItem onClick={handleClick}>
                                            <ListItemText primary="Entities" style={{ cursor: 'pointer' }} />
                                            {open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {ownedEntities.map((entity) => {
                                                    return (<ListItem
                                                        sx={{ cursor: 'pointer' }}
                                                        key={entity.id}
                                                        //  className={classes.listItem}
                                                        onClick={() => setUserRecentEntity(entity)}
                                                    >
                                                        <ListItemText sx={{ pl: 2 }} primary={entity.name} />
                                                    </ListItem>)
                                                })}
                                            </List>
                                        </Collapse>
                                        <Divider />
                                        <> <ListItem onClick={handleClickSetting}>
                                            <ListItemText style={{ cursor: 'pointer' }} primary="Manage Entities" />
                                            {openSetting ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={openSetting} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItem
                                                >
                                                    <ListItemText sx={{ pl: 2, cursor: 'pointer' }} primary={'Add Entity'} onClick={handleAddEntity} />
                                                </ListItem>
                                                {/*        <ListItem
                                                   >
                                                       <ListItemText sx={{ pl: 2, cursor: 'pointer' }} primary={'Edit Entity'} onClick={()=>{setOpenEditEntity(true)}} />
                                                   </ListItem> */}
                                                <ListItem
                                                >
                                                    <ListItemText sx={{ pl: 2, cursor: 'pointer' }} primary={'Delete Entity'} onClick={handleDeleteEntity} />
                                                </ListItem>
                                                <ListItem
                                                >
                                                    <ListItemText sx={{ pl: 2, cursor: 'pointer' }} primary={'Invite User'} onClick={handleOpenInviteUser} />
                                                </ListItem>
                                            </List>
                                        </Collapse> </>
                                        <Divider />
                                        <ListItem button onClick={logOut}>
                                            <ListItemIcon>
                                                <LogoutIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Logout" />
                                        </ListItem>
                                    </List>
                                </Popover>
                            </>
                            ) : (null
                            )}
                        </Box>
                    </Toolbar>)}
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}
export default Navbar;

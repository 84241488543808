import { Card, CardContent, Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDeviceById, getDeviceLastMessage } from '../actions/deviceAction';
import { getLocationsById } from '../actions/locationAction';
/* import Breadcrumbs from '@mui/material/Breadcrumbs'; */
import DeviceImage from '../components/deviceImage';
/* import Link from '@mui/material/Link'; */
import { interpretHexValues } from '../Utils/DecodeUplink';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSocket } from '../SocketContext';
import EndNodeImage from '../assets/LoRaWAN_Node_V1.png';
import { getEntityById } from '../actions/entityAction';
import WeatherChart from '../components/chart/WeatherChart';
import VoltageChart from '../components/chart/VoltageChart';
import PressureChart from '../components/chart/PressureChart';
import PhChart from '../components/chart/PhChart';
export default function DeviceView({ recentEntity, setRecentEntity }) {
    const socket = useSocket()
    const { locationId } = useParams();
    const { deviceId } = useParams();
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.location);
    const { device, deviceMessage } = useSelector((state) => state.device);
    const [ input0, setInput0 ] = useState(null);
    const [ input1, setInput1 ] = useState(null);
    const [ output0, setOutput0 ] = useState(null);
    const [ output1, setOutput1 ] = useState(null);
    const [ ph, setPh ] = useState(null);
    const [ batteryVoltage, setBatteryVoltage ] = useState(null);
    // eslint-disable-next-line
    const [batteryCurrent, setBatteryCurrent] = useState(null);
    // eslint-disable-next-line
    const [CO2, setCO2] = useState(null);
    const [ pressure, setPressure ] = useState(null);
    const [ temperature, setTemperature ] = useState(null);
    const [ selectedTemperaturePeriod, setSelectedTemperaturePeriod ] = useState('lastDay');
    const [ selectedVoltagePeriod, setSelectedVoltagePeriod ] = useState('lastDay');
    const [ selectedPhPeriod, setSelectedPhPeriod ] = useState('lastDay');
    const [ selectedPressurePeriod, setSelectedPressurePeriod ] = useState('lastDay');
    const REACT_APP_SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

    useEffect(() => {
        const updateDeviceData = (data) => {
            if (JSON.parse(deviceId) === data.id) {
                dispatch(getDeviceById(deviceId));
                console.log('data', data)
                if (data.data !== null) {
                    interpretHexValues(
                        data.data?.replace(/"/g, ''),
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                }

            }
        };

        socket.on('update_device_data', updateDeviceData);

    }, [ deviceId, dispatch, socket ]);

    useEffect(() => {
        if (output0) {
            console.log('output0 changed')
        }
    }, [ output0 ])
    useEffect(() => {
        if (device && device.eui) {
            dispatch(getDeviceLastMessage(device.eui)).then(() => {
                if (deviceMessage && deviceMessage.data) {
                    interpretHexValues(
                        deviceMessage?.data,
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                }
            });
        }
    }, [ deviceMessage?.data ]);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location) {

                console.log('recent entity is empty')
                console.log('entity', location?.entities[ 0 ].id)

                // Check if the current pathname contains 'entity/52'

                dispatch(getEntityById(location?.entities[ 0 ].id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])

    useEffect(() => {
        dispatch(getLocationsById(locationId)).then((response) => {
            console.log('getLocationsById Response', response)
        });
        dispatch(getDeviceById(deviceId));
    }, [  deviceId ]);
    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        height: '100%',
    }));

    const tempChartRef = React.useRef(null);
    const voltChartRef = React.useRef(null);
    const pressureChartRef = React.useRef(null);
    const phChartRef = React.useRef(null);

    const scrollToTempChart = () => {
        if (tempChartRef.current) {
            console.log('tempChartRef.current',tempChartRef.current)
            tempChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToVoltChart = () => {
        if (voltChartRef.current) {
            voltChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPressureChart = () => {
        if (pressureChartRef.current) {
            pressureChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPhChart = () => {
        if (phChartRef.current) {
            phChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const { sensor } = useParams(); // Assuming your parameter is named 'param'

    useEffect(() => {
        console.log('sensor', sensor);
        switch (sensor) {
        case 'temp':
            scrollToTempChart();
            break;
        case 'volt':
            scrollToVoltChart();
            break;
        case 'pressure':
            scrollToPressureChart();
            break;
        case 'ph':
            scrollToPhChart();
            break;
        default:
            break;
        }
    }, [ sensor ]);
    return (

        <>
            <Card
                elevation={2}
                style={{ backgroundColor: '#F4F6F8', marginTop: '10px' }}
            >

                <CardContent style={{}}>
                    <Box sx={{ width: 1, height: 1 }}>
                        <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                            <Grid item xs={12} md={8}>
                                {device && (
                                    <Card
                                        sx={{
                                            width: 1,
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Box xs={12} sx={{ height: '100%', width: '100%', position: 'relative', minWidth: '100%' }}>
                                            <Box xs={12} md={3} sx={{ height: '100%', position: 'absolute', right: '2.2%', top: '30%' }}>
                                                <DeviceImage
                                                    ph={ph}
                                                    pressure={pressure}
                                                    temperature={temperature}
                                                    input0={input0}
                                                    input1={input1}
                                                    out1={output0}
                                                    out2={output1}
                                                    batteryVoltage={batteryVoltage}
                                                    device={device}
                                                    location={location}
                                                    socket={socket}
                                                    setOutput1={setOutput1}
                                                    setOutput0={setOutput0}
                                                    setInput0={setInput0}
                                                    setInput1={setInput1}
                                                    setBatteryVoltage={setBatteryVoltage}
                                                    setPressure={setPressure}
                                                    setTemperature={setTemperature}
                                                    setBatteryCurrent={setBatteryCurrent}
                                                    setCO2={setCO2}
                                                    setPh={setPh}
                                                    scrollToTempChart={scrollToTempChart}
                                                    scrollToVoltChart={scrollToVoltChart}
                                                    scrollToPressureChart={scrollToPressureChart}
                                                    scrollToPhChart={scrollToPhChart}
                                                />
                                            </Box>
                                            {device.model === 'LoRaWAN Node V1.0' ? (
                                                <img
                                                    src={EndNodeImage}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'fill',
                                                    }}
                                                />)
                                                :
                                                (<img
                                                    src={REACT_APP_SERVER_API_URL + device.image}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'fill',
                                                    }}
                                                />)
                                            }

                                        </Box>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {device && (
                                    <Box>
                                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                                            Device details
                                        </h5>
                                        <Divider />
                                        <Item>
                                            <Table striped bordered className="w-full">
                                                <tbody>
                                                    <tr>
                                                        <td>EUI</td>
                                                        <td>{device.eui}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{device.name}</td>
                                                    </tr>
                                                    {location && (
                                                        <tr>
                                                            <td>Location</td>
                                                            <td>{location.name}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Model</td>
                                                        <td>{device.model}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ph</td>
                                                        <td>{ph === 0 ? ph : ph ? ph : 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Voltage</td>
                                                        {batteryVoltage === 0 || batteryVoltage ? <td>{batteryVoltage} V</td> : <td>N/A</td>}
                                                    </tr>
                                                    <tr>
                                                        <td>Pressure</td>
                                                        <td>{pressure ?? 'N/A'}{pressure != null ? ' Bar' : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Temperature</td>
                                                        <td>{temperature ?? 'N/A'}{temperature != null ? ' °C' : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Latest Activity</td>
                                                        <td>
                                                            {new Date(deviceMessage?.timestamp).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Description</td>
                                                        <td>{device.description}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Item>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <br />

            <Grid container spacing={1} elevation={1}>

                <Grid item xs={12} md={6} ref={tempChartRef}  >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 470 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedTemperaturePeriod === 'lastDay' && 'Average Temperature Over Last Day'}
                            {selectedTemperaturePeriod === 'lastWeek' && 'Temperature Extremes Over Last Week'}
                            {selectedTemperaturePeriod === 'lastMonth' && 'Temperature Extremes Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedTemperaturePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedTemperaturePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <WeatherChart deviceEUI={device?.eui} selectedPeriod={selectedTemperaturePeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} ref={voltChartRef} >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 470 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedVoltagePeriod === 'lastDay' && 'Average Voltage Over Last Day'}
                            {selectedVoltagePeriod === 'lastWeek' && 'Average Voltage  Over Last Week'}
                            {selectedVoltagePeriod === 'lastMonth' && 'Average Voltage  Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedVoltagePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedVoltagePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <VoltageChart deviceEui={device?.eui} selectedPeriod={selectedVoltagePeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} ref={pressureChartRef} >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 470 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedPressurePeriod === 'lastDay' && 'Average Pressure Over Last Day'}
                            {selectedPressurePeriod === 'lastWeek' && 'Average Pressure Over Last Week'}
                            {selectedPressurePeriod === 'lastMonth' && 'Average Pressure Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedPressurePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedPressurePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />

                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <PressureChart deviceEui={device?.eui} selectedPeriod={selectedPressurePeriod} socket={socket} />
                        </div>
                    </Card>

                </Grid>

                <Grid item xs={12} md={6} ref={phChartRef}  >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 470 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedPhPeriod === 'lastDay' && 'Average Ph Over Last Day'}
                            {selectedPhPeriod === 'lastWeek' && 'Average Ph  Over Last Week'}
                            {selectedPhPeriod === 'lastMonth' && 'Average Ph  Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedPhPeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedPhPeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <PhChart deviceEui={device?.eui} selectedPeriod={selectedPhPeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>

            </Grid>

        </>
    );
}
import {
    ADD_DEVICE_FAIL,
    ADD_DEVICE_SUCCESS,
    DELETE_DEVICE_FAIL,
    DELETE_DEVICE_SUCCESS,
    GET_DEVICES_LIST_FAIL,
    GET_DEVICES_LIST_SUCCESS,
    GET_DEVICE_FAIL,
    GET_DEVICE_SUCCESS,
    SET_MESSAGE,
    UPDATE_DEVICE_SUCCESS,
    UPDATE_DEVICE_FAIL,
    GET_DEVICE_LAST_MESSAGE_SUCCESS,
    GET_DEVICE_LAST_MESSAGE_FAIL,
} from './types';

import DeviceService from '../services/device.service';
export const addDevice =(locationId,name, dev_eui,join_eui, model, description,lng,lat ,software_version)  => (dispatch) => {
    return DeviceService.addDevice(
        locationId,name, dev_eui,join_eui, model, description,lng,lat,software_version,
    ).then(
        (response) => {
            dispatch({
                type: ADD_DEVICE_SUCCESS,
                payload: { data: response },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return response.data;
        },
        (error) => {
            const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

            dispatch({
                type: ADD_DEVICE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const updateDevice =
  (deviceId,dev_eui, name,  model, description,filename) => (dispatch) => {
      return DeviceService.updateDevice(
          deviceId,
          dev_eui,
          name,
          model,
          description,filename,
      ).then(
          (response) => {
              dispatch({
                  type: UPDATE_DEVICE_SUCCESS,
                  payload: { data: response },
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: response.data.message,
              });

              return response.data;
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: UPDATE_DEVICE_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      );
  };

export const deleteDeviceById = (deviceId) => (dispatch) => {
    return DeviceService.deleteDeviceById(deviceId).then(
        (response) => {
            dispatch({
                type: DELETE_DEVICE_SUCCESS,
                payload: { data: response },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: DELETE_DEVICE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const getDeviceListByLocationId = (locationId) => (dispatch) => {
    return DeviceService.getDeviceListByLocationId(locationId).then(
        (response) => {
            if (response.data.success) {
                dispatch({
                    type: GET_DEVICES_LIST_SUCCESS,
                    payload: { deviceList: response.data.result },
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.message,
                });
                return Promise.resolve();
            } else {
                dispatch({
                    type: GET_DEVICES_LIST_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
                return Promise.reject();
            }
        },
    );
};

export const getDeviceById = (id) => (dispatch) => {
    return DeviceService.getDeviceById(id).then((response) => {
        if (response.data.success) {
            dispatch({
                type: GET_DEVICE_SUCCESS,
                payload: { device: response.data.result },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });
            return Promise.resolve();
        } else {
            dispatch({
                type: GET_DEVICE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.result,
            });
            return Promise.reject();
        }
    });
};

export const getDeviceLastMessage = (device_eui) => (dispatch) => {
    return DeviceService.getDeviceLastMessage(device_eui).then((response) => {
        if (response.data.success) {
            dispatch({
                type: GET_DEVICE_LAST_MESSAGE_SUCCESS,
                payload: { deviceMessage: response.data.result },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });
            return response.data.result;
        } else {
            dispatch({
                type: GET_DEVICE_LAST_MESSAGE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.result,
            });
            return Promise.reject();
        }
    });
};

import {
    ADD_GATEWAY_FAIL,
    ADD_GATEWAY_SUCCESS,
    GET_GATEWAY_FAIL,
    GET_GATEWAY_SUCCESS,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case ADD_GATEWAY_SUCCESS:
        return {
            ...state,
            data: payload.data,
            isLoggedIn: true,
        };
    case ADD_GATEWAY_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };

    case GET_GATEWAY_SUCCESS:
        return {
            ...state,
            gateway: payload.gateway,
            isLoggedIn: true,
        };
    case GET_GATEWAY_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };
    default:
        return state;
    }
}

import React from 'react';
import { Card } from '@material-ui/core';

const NotFound = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw',
                height: '100vh',
                margin: 0,
                padding: 0,
            }}
        >
            <Card
                elevation={2}
                style={{
                    width: '80vw',
                    maxWidth: '600px',
                    height: 'auto',
                    padding: '2rem',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 'auto',
                    }}
                >
                    <svg
                        height="150"
                        viewBox="0 0 72 72"
                        width="150" // Adjust the size of the SVG as needed
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_9715024"
                        style={{ marginBottom: '1rem' }} // Adjust spacing between SVG and text
                    >
                        <g id="Layer_25">
                            <g>
                                <path d="m57.007 54h-49c-1.654 0-3-1.346-3-3v-33h37c.552 0 1-.448 1-1s-.448-1-1-1h-37v-3c0-1.654 1.346-3 3-3h52c1.654 0 3 1.346 3 3v3h-3c-.552 0-1 .448-1 1s.448 1 1 1h3v10c0 .552.448 1 1 1s1-.448 1-1v-15c0-2.757-2.243-5-5-5h-52c-2.757 0-5 2.243-5 5v38c0 2.757 2.243 5 5 5h49c.552 0 1-.448 1-1s-.447-1-1-1z"></path>
                                <circle cx="9.007" cy="13" r="1"></circle>
                                <circle cx="14.007" cy="13" r="1"></circle>
                                <circle cx="19.007" cy="13" r="1"></circle>
                                <path d="m57.007 17c0-.552-.448-1-1-1h-10c-.552 0-1 .448-1 1s.448 1 1 1h10c.553 0 1-.448 1-1z"></path>
                                <path d="m19.8 31.707c.196.195.451.293.707.293s.512-.098.707-.293l2.293-2.293 2.293 2.293c.196.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023 0-1.414l-2.293-2.293 2.293-2.293c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-2.293 2.293-2.293-2.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l2.293 2.293-2.293 2.293c-.39.391-.39 1.023 0 1.414z"></path>
                                <path d="m40.8 31.707c.196.195.451.293.707.293s.512-.098.707-.293l2.293-2.293 2.293 2.293c.196.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023 0-1.414l-2.293-2.293 2.293-2.293c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-2.293 2.293-2.293-2.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l2.293 2.293-2.293 2.293c-.39.391-.39 1.023 0 1.414z"></path>
                                <path d="m15.175 46.555c.306.458.926.583 1.387.277 10.598-7.065 24.293-7.065 34.891 0 .17.114.363.168.553.168.323 0 .64-.156.833-.445.306-.459.182-1.081-.277-1.387-11.271-7.515-25.838-7.515-37.109 0-.46.306-.584.927-.278 1.387z"></path>
                                <path d="m66.908 32.877c-.952-1.192-2.374-1.877-3.901-1.877s-2.948.684-3.901 1.877c-.952 1.193-1.305 2.731-.966 4.22l3.892 17.125c.104.455.509.778.975.778s.872-.323.975-.778l3.892-17.125c.338-1.489-.014-3.026-.966-4.22zm-.984 3.777-2.917 12.834-2.917-12.834c-.203-.892.008-1.814.579-2.529s1.423-1.125 2.338-1.125 1.767.41 2.338 1.125.781 1.637.579 2.529z"></path>
                                <path d="m63.007 56c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                            </g>
                        </g>
                    </svg>
                    <h1 style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Not Found</h1>
                    <p style={{ color: 'grey' }}>The page you are looking for does not exist.</p>
                </div>
            </Card>
        </div>
    );
};

export default NotFound;

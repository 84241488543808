import { Formik } from 'formik';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import {
    getDeviceListByLocationId,
    updateDevice,
} from '../../actions/deviceAction';
const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
});
const UpdateDeviceModal = ({ show, setShow, device }) => {
    const { message } = useSelector((state) => state.message);
    const { locationId } = useParams();
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);

    return (
        <>
            <Modal centered backdrop="static" show={show} onHide={handleClose}>
                {message ? (
                    <Modal.Header>
                        <Modal.Title>Update Device Error </Modal.Title>
                    </Modal.Header>
                ) : (
                    <Modal.Header closeButton>
                        <Modal.Title>Update Device </Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body>
                    {message ? (
                        <>
                            {' '}
                            <div className="form-group">
                                <div className="alert alert-danger">{message}</div>
                            </div>
                            <Modal.Footer>
                                <Button variant="light" onClick={handleClose}>
                  Close
                                </Button>
                            </Modal.Footer>
                        </>
                    ) : (
                        <Formik
                            validationSchema={schema}
                            onSubmit={async(values) => {

                                const valuesChanged =
                                values.name !== device.name ||
                                values.description !== device.description

                                if (!valuesChanged) {
                                    handleClose();
                                    return;
                                }
                                Swal.fire({
                                    title: 'Do you really want to Update device?',
                                    showDenyButton: true,
                                    confirmButtonColor: 'red',
                                    confirmButtonText: 'Yes',
                                    denyButtonText: 'No',
                                }).then(async(result) => {
                                    if (result.isConfirmed) {
                                        dispatch(
                                            updateDevice(
                                                device.id,
                                                device.eui,
                                                values.name,
                                                values.model,
                                                values.description,
                                                null,
                                            ),
                                        ).then((response) => {
                                            if (response.success) {
                                                handleClose();
                                                dispatch(getDeviceListByLocationId(locationId));
                                                Swal.fire(
                                                    'Device updated successfully',
                                                    '',
                                                    'success',
                                                );
                                            }
                                        });

                                    }
                                });
                            }}
                            initialValues={{
                                name: device.name,
                                model: device.model,
                                description: device.description,
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="validationFormik01">
                                            <Form.Label>Device name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Device name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="validationFormik01">
                                            <Form.Label>Device description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Device Description"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.description}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Modal.Footer>
                                        <Button variant="light" onClick={handleClose}>
                      Close
                                        </Button>
                                        <Button type="submit" className="btn btn-success">
                      Submit form
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UpdateDeviceModal;

import axios from 'axios';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const API_URL =`${ REACT_APP_SERVER_API_URL }/api/auth/`;

const register = (firstname,lastname,username, email, password, address, phoneNumber) => {
    return axios.post(API_URL + 'signup', {
        firstname,lastname,
        username,
        email,
        password,
        address,
        phoneNumber,
    });
};

const login = (email, password) => {
    return axios
        .post(API_URL + 'login', {
            user_email: email,
            user_password: password,
        })
        .then((response) => {
            if (response.data.success) {
                localStorage.setItem('user', JSON.stringify(response.data.result));
            }

            return response.data;
        });
};

const createUserSocket = (userId, socketId) => {
    return axios
        .post(API_URL + 'createUserSocket', {
            userId: userId,
            socketId: socketId,
        })
        .then((response) => {
            return response.data;
        });
};

const removeUserSocket = (userId, socketId) => {
    return axios
        .post(API_URL + 'removeUserSocket', {
            socketId: socketId,
        })
        .then((response) => {
            return response.data;
        });
};

const getUserById = (userId) => {
    console.log('getUserById service')
    return axios
        .post(API_URL + 'getUserById', {
            userId: userId,
        })
        .then((response) => {
            if (response.data.success) {
                console.log('getUserById service response',JSON.stringify(response.data.res))
            }

            return response.data;
        }).catch((err) => {
            console.log('error',err)
        });
};

const logout = () => {
    localStorage.removeItem('user');
};

export default {
    register,
    login,
    logout,
    createUserSocket,
    removeUserSocket,
    getUserById,
};

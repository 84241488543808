import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ListDevicesCard from '../components/ListDevices';
import ListGatewaysCard from '../components/ListGateways';
import LocationInfo from '../components/LocationInfo';
import Grid from '@mui/material/Grid';
/* import Breadcrumbs from '@mui/material/Breadcrumbs'; */
import Link from '@mui/material/Link';
/* import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@material-ui/core/Typography'; */
import { useDispatch } from 'react-redux';
import { getEntityByLocationId } from '../actions/entityAction';
import { useSocket } from '../SocketContext';
import TabContext from '@mui/lab/TabContext';
import Button from 'react-bootstrap/Button';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MapComponent from '../components/Map/MapComponent';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import { Card, CardContent, Divider } from '@material-ui/core';
import { getEntityById } from '../actions/entityAction';
export default function Location({ recentEntity,setRecentEntity }) {
    const socket = useSocket();

    const { location } = useSelector((state) => state.location);
    const { entity } = useSelector((state) => state.entity);
    const { locationId } = useParams();
    const dispatch = useDispatch();
    const [ isAdmin, setIsAdmin ] = useState(Boolean);
    const [ value, setValue ] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        socket.on('Get_Confirm_Config', () => {
            console.log('Get_Confirm_Config')
        });

        socket.on('update_device_data', () => {
            console.log('update_device_data')
        });
        socket.on('Get_TTN_Connect_Status', () => {
            console.log('Get_TTN_Connect_Status');

        })
        socket.on('gateway_status_changed', () => {
            console.log('gateway_status_changed');
            dispatch(getEntityByLocationId(locationId));
        })
        return () => {
            //socket.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ socket ]);
    useEffect(() => {
        if (locationId) {
            dispatch(getEntityByLocationId(locationId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ locationId ])
    useEffect(() => {
        // eslint-disable-next-line
        const storedUserId = JSON.parse(localStorage.getItem('user'))?.id;
        if (location && storedUserId) {
            const user = location.entities[ 0 ]?.users.find((u) => u.id === storedUserId);
            const userRole = user?.userEntity?.role;
            setIsAdmin(userRole === 'admin');
        }
    }, [ location ]);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if(entity){

                console.log('recent entity is empty')
                console.log('entity',entity)

                // Check if the current pathname contains 'entity/52'

                dispatch(getEntityById(entity.id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ entity ])

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={3}>
                    <LocationInfo id={locationId} entity={entity} />
                </Grid>

                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Card
                                elevation={2}
                                style={{ backgroundColor: '#F4F6F8' }}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >

                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            '& .css-1m2tz38-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                                                color: 'green',
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#F7F7F7',
                                            }, '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                                                color: 'green',
                                            }, '&  .css-1aquho2-MuiTabs-indicator': {
                                                backgroundColor: 'green',
                                            },
                                            '& .css-tujaqj-MuiButtonBase-root-MuiTab-root.Mui-selected':{
                                                color: 'green',
                                            },

                                        }} onChange={handleChange} aria-label="lab API tabs examplee">
                                            <Tab sx={{
                                                minWidth: '50% ',
                                                justifyContent: 'center',
                                            }} label={<MapTwoToneIcon/>} value="1" component="h6" />
                                            <Tab sx={{
                                                minWidth: '50% ',
                                                justifyContent: 'center',
                                            }} label={<ListTwoToneIcon/>}  value="2" component="h6" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Card
                                            elevation={2}
                                            style={{ backgroundColor: '#F4F6F8' }}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Box
                                                className="card-header"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                }}
                                            >
                                                <h5 style={{ margin: 0 }}>Location Map</h5>
                                                {isAdmin && (
                                                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                                        <Link color="inherit" href={`/location/${ locationId }/addDevice`}>
                                                            <Button color="success" variant="success" className='mr-1'>
                        Add Device
                                                            </Button>
                                                        </Link>
                                                        <Link color="inherit" href={`/location/${ locationId }/addGateway`}>
                                                            <Button color="success" variant="success">
                        Add Gateway
                                                            </Button>
                                                        </Link>
                                                    </Box>
                                                )}
                                            </Box>

                                            <Divider />
                                            <CardContent>
                                                <MapComponent apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'} socket={socket} deviceListView={true} />
                                            </CardContent>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <ListDevicesCard locationId={locationId} socket={socket} entity={entity} isAdmin={isAdmin} />

                                    </TabPanel>
                                </TabContext>

                            </Card>

                        </Grid>
                        {/*   <Grid item xs={12}>
                            <ListDevicesCard locationId={locationId} socket={socket} entity={entity} isAdmin={isAdmin} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <ListGatewaysCard id={locationId} location={location} socket={socket} isAdmin={isAdmin} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

import React, { useState } from 'react';
import { GoogleMap, useLoadScript, Circle, Marker } from '@react-google-maps/api';
import point from '../assets/point.png';
import deviceIcon from '../assets/deviceIcon.png';
import gatewayIcon from '../assets/gatewayIcon.png';
import center from '../assets/center.png';
import user from '../assets/user.png';
import Swal from 'sweetalert2';
const LocationMap = ({ circle, setCircle, apiKey, devices,gateways, location }) => {
    const [ drawingCenter, setDrawingCenter ] = useState(null);
    const [ drawingRadius, setDrawingRadius ] = useState(0);
    const [ zoom, setZoom ] = useState();
    const [ isDrawing, setIsDrawing ] = useState(false);
    const [ isDrawingStarted, setIsDrawingStarted ] = useState(false);
    const [ myPosition, setMyPosition ] = useState()

    const defaultCenter = React.useMemo(
        () => ({
            lat: 36.44035,
            lng: 10.70592,
        }),
        [],
    );

    const [ mapCenter, setMapCenter ] = useState(defaultCenter);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
    });
    const getDistance = (point1, point2) => {
        const lat1 = point1?.lat * Math.PI / 180;
        const lat2 = point2?.lat * Math.PI / 180;
        const lon1 = point1?.lng * Math.PI / 180;
        const lon2 = point2?.lng * Math.PI / 180;
        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const radius = 6371000; // Earth radius in meters
        return radius * c;
    };
    const getMyPosition = () => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const userLatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setMyPosition(userLatLng)
                    setMapCenter(userLatLng);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                },
            );
            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    React.useEffect(() => {
        if (circle) {
            setMapCenter(circle?.center);
            if (circle.radius < 50) {
                setZoom(22);
            } else if (circle.radius >= 50 && circle.radius < 100) {
                setZoom(19);
            } else if (circle.radius >= 100 && circle.radius < 400) {
                setZoom(17);
            } else if (circle.radius >= 400 && circle.radius < 800) {
                setZoom(16);
            } else if (circle.radius >= 800 && circle.radius < 1700) {
                setZoom(15);
            } else if (circle.radius >= 1700) {
                setZoom(14);
            } else {
                setZoom(13);
            }
        }
    }, [ circle ]);

    React.useEffect(() => {
        if (!location) {
            getMyPosition();
        }
    }, [ location, isLoaded ]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    const mapStyles = {
        height: '100vh',
        width: '100%',
        cursor: isDrawing ? 'crosshair' : 'grab',
    };

    const handleMapClick = (event) => {
        if (!isDrawingStarted) {
            setDrawingCenter({
                lat: event?.latLng?.lat(),
                lng: event?.latLng?.lng(),
            });
            setIsDrawingStarted(true);
            setIsDrawing(true);
            setMapCenter({
                lat: event?.latLng?.lat(),
                lng: event?.latLng?.lng(),
            });
        } else {
            setIsDrawing(false);
            setIsDrawingStarted(false);

            const newCircle = {
                center: drawingCenter,
                radius: drawingRadius,
            };
            if(devices && devices.length>0 ){
                if ( devices?.every(device => getDistance(device, drawingCenter) <= drawingRadius) && gateways.every(gateway => getDistance(gateway, drawingCenter) <= drawingRadius)) {
                    setCircle(newCircle);
                    setMapCenter(drawingCenter);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title:'Cannot update location on map',
                        text: 'Some end devices are outside the new circle.',
                    });

                }

                setDrawingCenter(null);
                setDrawingRadius(0);
            }else{
                setCircle(newCircle);
                setMapCenter(drawingCenter);
            }

        }
    };

    const handleMapMouseMove = (event) => {
        if (isDrawing) {
            const distance = getDistance(drawingCenter, {
                lat: event?.latLng?.lat(),
                lng: event?.latLng?.lng(),
            });

            const minRadius = 10; // 10 meters
            const maxRadius = 2000; // 2000 meters (2 km)
            let limitedRadius = Math.max(minRadius, distance);
            limitedRadius = Math.min(maxRadius, limitedRadius);

            setDrawingRadius(limitedRadius);
        }
    };

    const options = {
        language: 'french',
        gestureHandling: 'cooperative',
        mapTypeId: 'hybrid',
        labels: true,
        styles: [
            {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.medical',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.attraction',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.government',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.school',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.sports_complex',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi.place_of_worship',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
        ],
    };

    return (
        <GoogleMap
            key={0}
            options={options}
            mapContainerStyle={mapStyles}
            zoom={zoom || 17}
            center={{
                lat: mapCenter.lat || defaultCenter.lat,
                lng: mapCenter.lng || defaultCenter.lng,
            }}
            onClick={handleMapClick}
            onMouseMove={handleMapMouseMove}
        >
            {circle && (
                <>
                    <Circle
                        center={circle.center}
                        radius={circle.radius}
                        options={{
                            fillColor: '#7dfdfd',
                            fillOpacity: 0.35,
                            strokeColor: '#7dfdfd',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            zIndex: 1,
                        }}
                    />
                    <Marker
                        position={circle.center}
                        label={{
                            text: `${ (circle.radius / 1000).toFixed(2) } km`,
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            className: 'marker-label',
                        }}
                        icon={{
                            url: point,
                            scaledSize: new window.google.maps.Size(12, 12),
                            anchor: new window.google.maps.Point(10, 10),
                        }}
                    />
                </>
            )}

            {devices?.map((device, index) => (
                <Marker
                    key={index}
                    position={{ lat: device?.lat, lng: device?.lng }}
                    draggable={false}
                    icon={{ url:deviceIcon }}
                    zoomOnClick={true}
                />
            ))}
            {gateways?.map((gateway, index) => (
                <Marker
                    key={index}
                    position={{ lat: gateway?.lat, lng: gateway?.lng }}
                    icon={{ url:gatewayIcon }}
                    draggable={false}
                    zoomOnClick={true}
                />
            ))}
            {isDrawingStarted && isDrawing && drawingCenter && (
                <>
                    <Circle
                        center={drawingCenter}
                        radius={drawingRadius}
                        options={{
                            fillColor: '#7dfdfd',
                            fillOpacity: 0.35,
                            strokeColor: '#7dfdfd',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            zIndex: 1,
                        }}
                    />
                    <Marker
                        position={drawingCenter}
                        label={{
                            text: `${ (drawingRadius / 1000).toFixed(2) } km`,
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            className: 'marker-label',
                        }}
                        icon={{
                            url: point,
                            scaledSize: new window.google.maps.Size(12, 12),
                            anchor: new window.google.maps.Point(10, 10),
                        }}
                    />
                </>
            )}

            {drawingCenter && (
                <Marker
                    position={drawingCenter}
                    icon={{
                        url: point,
                        scaledSize: new window.google.maps.Size(12, 12),
                        anchor: new window.google.maps.Point(10, 10),
                    }}
                />
            )}

            {mapCenter && (
                <Marker
                    position={mapCenter}
                    icon={{
                        url: center,
                        scaledSize: new window.google.maps.Size(12, 12),
                        anchor: new window.google.maps.Point(10, 10),
                    }}
                />
            )}
            {myPosition && (
                <Marker
                    position={myPosition}
                    icon={{
                        url: user,
                    }}       />
            )}
        </GoogleMap>
    );
};

export default LocationMap;
import {
    SET_MESSAGE,
    ADD_ENTITY_SUCCESS,
    ADD_ENTITY_FAIL,
    GET_ENTITY_SUCCESS,
    GET_ENTITY_FAIL,
} from './types';

import EntityService from '../services/entity.service';
export const addEntity =
  (name, address,image,userId,ownerId) => (dispatch) => {
      return EntityService.addEntity(
          name, address,image,userId,ownerId,
      ).then(
          (response) => {
              if(response.data.success){
                  dispatch({
                      type: ADD_ENTITY_SUCCESS,
                      payload: { data: response },
                  });

                  return response.data;
              }else{
                  dispatch({
                      type: ADD_ENTITY_FAIL,
                  });
                  dispatch({
                      type: SET_MESSAGE,
                      payload: response.data.result,
                  });
              }
              return Promise.resolve();
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: ADD_ENTITY_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      ).catch((error) => {
          dispatch({
              type: ADD_ENTITY_FAIL,
          });

          dispatch({
              type: SET_MESSAGE,
              payload: error,
          });

          return Promise.reject();
      });
  };
/*
export const updateLocation =
  (locationId,name, image, city, state, zipCode, description, userId) => (dispatch) => {
      return LocationService.updateLocation(
          locationId,
          name,
          image,
          city,
          state,
          zipCode,
          description,
          userId,
      ).then(
          (response) => {
              if(response.data.success){
                  dispatch({
                      type: UPDATE_ENTITY_SUCCESS,
                      payload: { data: response },
                  });

                  return Promise.resolve();
              }else{
                  dispatch({
                      type: UPDATE_ENTITY_FAIL,
                  });
                  dispatch({
                      type: SET_MESSAGE,
                      payload: response.data.result,
                  });
              }
              return Promise.resolve();
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: UPDATE_ENTITY_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      ).catch((error) => {
          dispatch({
              type: UPDATE_ENTITY_FAIL,
          });

          dispatch({
              type: SET_MESSAGE,
              payload: error,
          });

          return Promise.reject();
      });
  }; */

export const getEntitiesListByOwnerId = (ownerId) => (dispatch) => {
    return EntityService.getEntitiesListByOwnerId(ownerId).then(
        (response) => {
            dispatch({
                type: GET_ENTITY_SUCCESS,
                payload: { entity: response.data.result },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: GET_ENTITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const getEntityByLocationId = (locationId) => (dispatch) => {
    return EntityService.getEntityByLocationId(locationId).then(
        (response) => {
            console.log('getEntityByLocationId Action ',JSON.stringify((response.data.result)[ 0 ]))
            dispatch({
                type: GET_ENTITY_SUCCESS,
                payload: { entity: (response.data.result)[ 0 ] },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: GET_ENTITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

/*
export const deleteLocationById = (locationId) => (dispatch) => {
    return LocationService.deleteLocationById(locationId).then(
        (response) => {
            if(response.data.success){
                dispatch({
                    type: DELETE_ENTITY_SUCCESS,
                    payload: { data: response },
                });

                return response.data;
            }else{
                dispatch({
                    type: DELETE_ENTITY_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
                return response.data;
            }

        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: DELETE_ENTITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    ).catch((error) => {
        dispatch({
            type: DELETE_ENTITY_FAIL,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: error,
        });

        return Promise.reject();
    });
};
*/
export const getEntityById = (id) => (dispatch) => {
    return EntityService.getEntityById(id).then(
        (response) => {
            if(response.data.success){
                dispatch({
                    type: GET_ENTITY_SUCCESS,
                    payload: { entity: response.data.result },
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.message,
                });
            }else{
                dispatch({
                    type: GET_ENTITY_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
            }

            return response.data;
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: GET_ENTITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

import {
    SET_MESSAGE,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAIL,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAIL,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAIL,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_FAIL,
} from './types';

import LocationService from '../services/location.service';

export const addLocation =
  (name, image, city, state, zipCode, description,lng,lat,radius, entityId) => (dispatch) => {
      console.log('addLocation service',entityId);
      return LocationService.addLocation(
          name, image, city, state, zipCode, description,lng,lat,radius, entityId,
      ).then(
          (response) => {
              if(response.data.success){
                  dispatch({
                      type: ADD_LOCATION_SUCCESS,
                      payload: { data: response },
                  });

                  return response.data
              }else{
                  dispatch({
                      type: ADD_LOCATION_FAIL,
                  });
                  dispatch({
                      type: SET_MESSAGE,
                      payload: response.data.result,
                  });
              }
              return Promise.resolve();
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: ADD_LOCATION_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      ).catch((error) => {
          dispatch({
              type: ADD_LOCATION_FAIL,
          });

          dispatch({
              type: SET_MESSAGE,
              payload: error,
          });

          return Promise.reject();
      });
  };

export const updateLocation =
  (locationId,name, image, city, state, zipCode, description, userId) => (dispatch) => {
      return LocationService.updateLocation(
          locationId,
          name,
          image,
          city,
          state,
          zipCode,
          description,
          userId,
      ).then(
          (response) => {
              if(response.data.success){
                  dispatch({
                      type: UPDATE_LOCATION_SUCCESS,
                      payload: { data: response },
                  });

                  return response.data;
              }else{
                  dispatch({
                      type: UPDATE_LOCATION_FAIL,
                  });
                  dispatch({
                      type: SET_MESSAGE,
                      payload: response.data.result,
                  });
              }
              return Promise.resolve();
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: UPDATE_LOCATION_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      ).catch((error) => {
          dispatch({
              type: UPDATE_LOCATION_FAIL,
          });

          dispatch({
              type: SET_MESSAGE,
              payload: error,
          });

          return Promise.reject();
      });
  };

export const updateRoundLocation =
  (locationId,name, image, city, state, zipCode, description,lng,lat,radius, userId) => (dispatch) => {
      return LocationService.updateRoundLocation(
          locationId,
          name,
          image,
          city,
          state,
          zipCode,
          description,
          lng,lat,radius,
          userId,
      ).then(
          (response) => {
              if(response.data.success){
                  dispatch({
                      type: UPDATE_LOCATION_SUCCESS,
                      payload: { data: response },
                  });

                  return response.data
              }else{
                  dispatch({
                      type: UPDATE_LOCATION_FAIL,
                  });
                  dispatch({
                      type: SET_MESSAGE,
                      payload: response.data.result,
                  });
              }
              return Promise.resolve();
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: UPDATE_LOCATION_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      ).catch((error) => {
          dispatch({
              type: UPDATE_LOCATION_FAIL,
          });

          dispatch({
              type: SET_MESSAGE,
              payload: error,
          });

          return Promise.reject();
      });
  };

export const getLocationsListByEntityId = (ownerId) => (dispatch) => {
    return LocationService.getLocationsListByEntityId(ownerId).then(
        (response) => {
            dispatch({
                type: GET_LOCATION_SUCCESS,
                payload: { location: response.data.result },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: GET_LOCATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const deleteLocationById = (locationId) => (dispatch) => {
    return LocationService.deleteLocationById(locationId).then(
        (response) => {
            if(response.data.success){
                dispatch({
                    type: DELETE_LOCATION_SUCCESS,
                    payload: { data: response },
                });

                return response.data;
            }else{
                dispatch({
                    type: DELETE_LOCATION_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
                return response.data;
            }

        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: DELETE_LOCATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    ).catch((error) => {
        dispatch({
            type: DELETE_LOCATION_FAIL,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: error,
        });

        return Promise.reject();
    });
};

export const getLocationsById = (id) => (dispatch) => {
    return LocationService.getLocationById(id).then(
        (response) => {
            if(response.data.success){
                dispatch({
                    type: GET_LOCATION_SUCCESS,
                    payload: { location: response.data.result },
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.message,
                });
            }else{
                dispatch({
                    type: GET_LOCATION_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
            }

            return response.data;
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: GET_LOCATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

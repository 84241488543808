import { Card, CardContent, Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDeviceById, getDeviceLastMessage } from '../actions/deviceAction';
import { getLocationsById } from '../actions/locationAction';
/* import Breadcrumbs from '@mui/material/Breadcrumbs'; */
import DeviceImage from '../components/deviceImage';
/* import Link from '@mui/material/Link'; */
import { interpretData, interpretHexValues } from '../Utils/DecodeUplink';
import ChartWidget from '../components/ChartWidget';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeviceService from '../services/device.service';
import { useSocket } from '../SocketContext';
import EndNodeImage from '../assets/LoRaWAN_Node_V1.png';
import { getEntityById } from '../actions/entityAction';
export default function Device({ recentEntity, setRecentEntity }) {
    const socket = useSocket()
    const { locationId } = useParams();
    const { deviceId } = useParams();
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.location);
    const { device, deviceMessage } = useSelector((state) => state.device);
    const [ input0, setInput0 ] = useState(null);
    const [ input1, setInput1 ] = useState(null);
    const [ output0, setOutput0 ] = useState(null);
    const [ output1, setOutput1 ] = useState(null);
    const [ ph, setPh ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ batteryVoltage, setBatteryVoltage ] = useState(null);
    // eslint-disable-next-line
    const [batteryCurrent, setBatteryCurrent] = useState(null);
    // eslint-disable-next-line
    const [CO2, setCO2] = useState(null);
    const [ pressure, setPressure ] = useState(null);
    const [ temperature, setTemperature ] = useState(null);
    const [ chartData, setChartData ] = useState([])
    const [ selectedPeriod, setSelectedPeriod ] = useState('lastDay');
    const REACT_APP_SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

    useEffect(() => {
        const updateDeviceData = (data) => {
            if (JSON.parse(deviceId) === data.id) {
                dispatch(getDeviceById(deviceId));
                interpretHexValues(
                    data.data.replace(/"/g, ''),
                    setOutput0,
                    setOutput1,
                    setInput0,
                    setInput1,
                    setBatteryVoltage,
                    setPressure,
                    setTemperature,
                    setBatteryCurrent,
                    setCO2,
                    setPh,
                );
            }
        };

        socket.on('update_device_data', updateDeviceData);
        return () => {
            // Clean up the subscription when the component unmounts
            //socket.disconnect();
        };
    }, [ deviceId, dispatch, socket ]);
    useEffect(() => {
        if (device && device.eui) {
            dispatch(getDeviceLastMessage(device.eui)).then(() => {
                if (deviceMessage) {
                    interpretHexValues(
                        deviceMessage,
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                }
            });
        }
    }, [ dispatch, device, deviceMessage ]);

    useEffect(() => {
        if (device && device.eui) {
            setLoading(true);
            DeviceService.getDeviceLastMessages(device.eui, selectedPeriod).then((response) => {
                const newData = [];
                if (response.data.result) {
                    response.data.result.forEach((aResult) => {
                        const res = interpretData(aResult.data);
                        newData.push({
                            temperature: res.temperature,
                            batteryVoltage: res.batteryVoltage,
                            pressure: res.pressure,
                            pH: res.pH,
                            time: new Date(aResult.updatedAt).toLocaleString('en-GB', {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                                second: 'numeric',
                            }),
                        });
                    });

                }

                setChartData(newData)
                setLoading(false);

            });

        }
    }, [ device, selectedPeriod ]);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location) {

                console.log('recent entity is empty')
                console.log('entity', location?.entities[ 0 ].id)

                // Check if the current pathname contains 'entity/52'

                dispatch(getEntityById(location?.entities[ 0 ].id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])

    useEffect(() => {
        dispatch(getLocationsById(locationId)).then((response) => {
            console.log('getLocationsById Response', response)
        });
        dispatch(getDeviceById(deviceId));
    }, [ dispatch, locationId, deviceId ]);

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        height: '100%',
    }));
    if (loading) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    return (

        <>
            <Card
                elevation={2}
                style={{ backgroundColor: '#F4F6F8', marginTop: '20px' }}
            >

                <CardContent style={{}}>
                    <Box sx={{ width: 1, height: 1 }}>
                        <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                            <Grid item xs={12} md={8}>
                                {device && (
                                    <Card
                                        sx={{
                                            width: 1,
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Box xs={12} sx={{ height: '100%', width: '100%', position: 'relative', minWidth: '100%' }}>
                                            <Box xs={12} md={3} sx={{ height: '100%', position: 'absolute', right: '2.2%', top: '30%' }}>
                                                <DeviceImage
                                                    ph={ph}
                                                    pressure={pressure}
                                                    temperature={temperature}
                                                    input0={input0}
                                                    input1={input1}
                                                    out1={output0}
                                                    out2={output1}
                                                    batteryVoltage={batteryVoltage}
                                                    device={device}
                                                    socket={socket}
                                                />
                                            </Box>
                                            {device.model === 'LoRaWAN Node V1.0' ? (
                                                <img
                                                    src={EndNodeImage}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'fill',
                                                    }}
                                                />)
                                                :
                                                (<img
                                                    src={REACT_APP_SERVER_API_URL + device.image}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'fill',
                                                    }}
                                                />)
                                            }

                                        </Box>
                                    </Card>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {device && (
                                    <Box>
                                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                                            Device details
                                        </h5>
                                        <Divider />
                                        <Item>
                                            <Table striped bordered className="w-full">
                                                <tbody>
                                                    <tr>
                                                        <td>EUI</td>
                                                        <td>{device.eui}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{device.name}</td>
                                                    </tr>
                                                    {location && (
                                                        <tr>
                                                            <td>Location</td>
                                                            <td>{location.name}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>Model</td>
                                                        <td>{device.model}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ph</td>
                                                        {ph ? <td>{ph} </td> : <td>_ </td>}
                                                    </tr>
                                                    <tr>
                                                        <td>Voltage</td>
                                                        {batteryVoltage ? <td>{batteryVoltage} V</td> : <td>_ V</td>}
                                                    </tr>
                                                    <tr>
                                                        <td>Pressure</td>
                                                        {pressure ? <td>{pressure} Pa</td> : <td>_ Pa</td>}
                                                    </tr>
                                                    <tr>
                                                        <td>Temperature</td>
                                                        {temperature ? <td>{temperature} °C</td> : <td>_ °C</td>}
                                                    </tr>
                                                    <tr>
                                                        <td>Latest Activity</td>
                                                        <td>
                                                            {new Date(device.updatedAt).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Description</td>
                                                        <td>{device.description}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Item>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>

            <Card
                className="mt-2"
                elevation={2}
                style={{ width: '100%', backgroundColor: '#F4F6F8' }}
            >
                <h5 className="card-header d-flex justify-content-between align-items-center">
                    Analytics Card
                    <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                        <FormControl >
                            <InputLabel id="period-select-label">Select Period</InputLabel>
                            <Select
                                labelId="period-select-label"
                                id="period-select"
                                value={selectedPeriod}
                                label="Select Period"
                                onChange={(event) =>
                                    setSelectedPeriod(event.target.value)
                                }
                            >
                                <MenuItem value="lastMinute">Last Minute</MenuItem>
                                <MenuItem value="lastDay">Last Day</MenuItem>
                                <MenuItem value="lastWeek">Last Week</MenuItem>
                                <MenuItem value="lastMonth">Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </h5>
                <CardContent
                    spacing={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} >

                            {chartData && <ChartWidget
                                selectedPeriod={selectedPeriod}
                                data={chartData}
                                device={device}
                                title={'Temperature'}
                                color={'red'}
                            />}

                        </Grid>
                        <Grid item xs={12} md={6}>
                            {chartData && <ChartWidget
                                selectedPeriod={selectedPeriod}
                                data={chartData}
                                device={device}
                                title={'Voltage'}
                                color={'rgba(255,165,0,1)'}
                            />}

                        </Grid>
                        <Grid item xs={12} md={6}>
                            {chartData && <ChartWidget
                                selectedPeriod={selectedPeriod}
                                data={chartData}
                                device={device}
                                title={'Ph'}
                                color={'rgba(75,192,192,1)'}
                            />}

                        </Grid>
                        <Grid item xs={12} md={6}>
                            {chartData && <ChartWidget
                                selectedPeriod={selectedPeriod}
                                data={chartData}
                                device={device}
                                title={'Pressure'}
                                color={'green'}
                            />}

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().required(),
});

const EditEntityModal = ({ show, setShow, entity, onUpdate, ownedEntity, ownedEntities }) => {
    console.log('ownedEntity', ownedEntity)
    const handleClose = () => setShow(false);

    const handleSubmit = async(values, { setSubmitting }) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('role', values.role);

        try {
            const response = await axios.put(`/api/users/${ entity.id }`, formData);
            onUpdate(response.data);
            Swal.fire('Success!', 'User updated successfully.', 'success');
        } catch (error) {
            Swal.fire('Error!', 'Something went wrong.', 'error');
        }

        setSubmitting(false);
    };

    return (
        <Modal centered backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: 'entity.name',
                        email: 'entity.email',
                        role: 'entity.role',
                        image: null,
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {ownedEntity?.users?.map((index) =>
                                <Row className="mb-3" key={index} md={10}>
                                    <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            readOnly
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationFormik02">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                            readOnly
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationFormik03">
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="role"
                                            value={values.role}
                                            onChange={handleChange}
                                            isInvalid={!!errors.role}
                                        >
                                            <option value="">Select Role</option>
                                            <option value="admin">Admin</option>
                                            <option value="user">User</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group as={Col} controlId="validationFormik03">
                                        <Form.Label>Delete</Form.Label>
                                        <DeleteIcon />
                                    </Form.Group>
                                    {!ownedEntity && <Row className="mb-3">

                                        <Form.Group as={Col} controlId="validationFormik03">
                                            <Form.Label>Select Entity</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="entity"
                                                value={values.entity}
                                                onChange={handleChange}
                                                isInvalid={!!errors.entity}
                                            >
                                                <option value="" disabled>Select Entity</option>
                                                {ownedEntities?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>}
                                </Row>,

                            )}

                            <Modal.Footer>
                                <Button variant="light" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    Submit form
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>)
}
export default EditEntityModal;
import React, { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { extractTemperature } from '../../Utils/DecodeUplink';
import DeviceService from '../../services/device.service';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const WeatherChart = ({ selectedPeriod, deviceEUI, socket }) => {
    const currentPathname = window.location.pathname;
    const chartView = currentPathname.startsWith('/chart');
    const [ lastDayTemperatureChartData, setLastDayTemperatureChartData ] = useState([]);
    const [ lastWeekTemperatureChartData, setLastWeekTemperatureChartData ] = useState([]);
    const [ lastMonthTemperatureChartData, setLastMonthTemperatureChartData ] = useState([]);
    const [ specificDateTemperatureChartData, setSpecificDateTemperatureChartData ] = useState([])
    const [ chartDataAvailable, setChartDataAvailable ] = useState(false);
    const [ minValue,setMinValue ] = useState()
    const [ maxValue,setMaxValue ] = useState()
    const refreshZoomData = useRef([])
    const isZoomingRef = useRef(false);
    const isChartDataNull = () => {
        if (selectedPeriod === 'lastDay') {
            return lastDayTemperatureChartData.every(data => data.averageTemperature === null || data.averageTemperature === undefined);
        }
        if (selectedPeriod === 'lastWeek') {
            return lastWeekTemperatureChartData.every(data => data.maxTemperature === null || data.maxTemperature === undefined || data.mainTemperature === null || data.minTemperature === undefined);
        }
        if (selectedPeriod === 'lastMonth') {
            return lastMonthTemperatureChartData.every(data => data.maxTemperature === null || data.maxTemperature === undefined || data.mainTemperature === null || data.minTemperature === undefined);
        }
        return false;
    };

    useEffect(() => {
        if (selectedPeriod === 'lastDay' && lastDayTemperatureChartData.length > 0 || selectedPeriod === 'lastWeek' && lastWeekTemperatureChartData.length > 0 || selectedPeriod === 'lastMonth' && lastMonthTemperatureChartData.length > 0) {
            setChartDataAvailable(isChartDataNull());
        }
    }, [ selectedPeriod, lastDayTemperatureChartData, lastWeekTemperatureChartData, lastMonthTemperatureChartData ]);

    const fetchLastDayData = () => {
        if (deviceEUI) {
            DeviceService.getDeviceLastMessages(deviceEUI, selectedPeriod).then((response) => {
                const newChartData = [];
                const groupedData = response.data.result?.reduce((acc, aResult) => {
                    const updatedAt = new Date(aResult.updatedAt);
                    const date = updatedAt.toLocaleDateString();
                    const hour = updatedAt.getHours();
                    const minute = updatedAt.getMinutes();
                    const quarterHour = Math.floor(minute / 15) * 15;
                    const temperature = extractTemperature(aResult.data);
                    const key = `${ date }-${ hour }-${ quarterHour }`;

                    if (!acc[ key ]) {
                        acc[ key ] = {
                            date: date,
                            hour: hour,
                            quarterHour: quarterHour,
                            temperatures: [],
                        };
                    }

                    if (temperature !== undefined) {
                        acc[ key ].temperatures.push(temperature);
                    }

                    return acc;
                }, {});

                const currentDate = new Date();
                for (let i = 0; i < 24; i++) {
                    const date = new Date(currentDate);
                    date.setHours(date.getHours() - i);
                    const dateString = date.toLocaleDateString();
                    const hour = date.getHours();
                    const hourlyAverageTemperature = [];
                    let hourTemperatures = [];

                    for (let j = 0; j < 4; j++) {
                        const quarterHour = j * 15;
                        const key = `${ dateString }-${ hour }-${ quarterHour }`;
                        const existingData = groupedData[ key ];

                        if (existingData) {
                            const sum = existingData.temperatures.reduce((total, temp) => total + temp, 0);
                            const average = sum / existingData.temperatures.length;
                            hourlyAverageTemperature.push({
                                time: `${ hour }:${ quarterHour < 10 ? '0' : '' }${ quarterHour }`,
                                average: average !== 0 ? average.toFixed(2) : null,
                            });
                            hourTemperatures = hourTemperatures.concat(existingData.temperatures);
                        } else {
                            hourlyAverageTemperature.push({
                                time: `${ hour }:${ quarterHour < 10 ? '0' : '' }${ quarterHour }`,
                                average: null,
                            });
                        }
                    }

                    const hourlySum = hourTemperatures.reduce((total, temp) => total + temp, 0);
                    const hourlyAverage = hourTemperatures.length ? (hourlySum / hourTemperatures.length).toFixed(2) : null;

                    newChartData.push({
                        date: dateString,
                        hour: hour,
                        temperatures: hourTemperatures,
                        averageTemperature: hourlyAverage,
                        hourlyAverageTemperature: hourlyAverageTemperature,
                    });
                }

                newChartData.sort((a, b) => {
                    const dateA = new Date(
                        parseInt(a.date.split('/')[ 2 ]),
                        parseInt(a.date.split('/')[ 1 ]) - 1,
                        parseInt(a.date.split('/')[ 0 ]),
                        a.hour,
                    );
                    const dateB = new Date(
                        parseInt(b.date.split('/')[ 2 ]),
                        parseInt(b.date.split('/')[ 1 ]) - 1,
                        parseInt(b.date.split('/')[ 0 ]),
                        b.hour,
                    );
                    return dateA - dateB;
                });

                console.log('LastDayTemperatureChartData', newChartData);
                setLastDayTemperatureChartData(newChartData);
            });
        }
    }
    const fetchSpecificDayData = (date) => {

        // Function to convert date from DD/MM/YYYY format to YYYY-MM-DD
        const convertToISODate = (dateStr) => {
            const [ day, month, year ] = dateStr.split('/').map(Number);
            return new Date(Date.UTC(year, month - 1, day)).toISOString().split('T')[ 0 ]; // Convert to YYYY-MM-DD
        };

        // Validate and parse the date input
        const isValidDate = (str) => {
            const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            const match = str.match(regex);
            if (!match) return false;
            /* eslint-disable no-unused-vars */
            const [ _, day, month, year ] = match.map(Number);
            const date = new Date(Date.UTC(year, month - 1, day));
            return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
        };

        if (!isValidDate(date)) {
            console.error('Invalid date format. Please use DD/MM/YYYY.');
            return;
        }

        const isoDate = convertToISODate(date);

        DeviceService.getDeviceLastMessages(deviceEUI, date).then((response) => {
            const newChartData = [];
            const groupedData = response.data.result?.reduce((acc, aResult) => {
                const resultDate = new Date(aResult.updatedAt);

                // Validate if resultDate is a valid date
                if (isNaN(resultDate.getTime())) {
                    console.warn('Invalid date in data:', aResult.updatedAt);
                    return acc;
                }

                // Convert resultDate to YYYY-MM-DD format
                const dateString = resultDate.toISOString().split('T')[ 0 ]; // YYYY-MM-DD format
                const hour = resultDate.getUTCHours(); // Use UTC hours to avoid timezone issues
                const temperature = extractTemperature(aResult.data);

                const key = `${ dateString }-${ hour }`;
                if (!acc[ key ]) {
                    acc[ key ] = {
                        date: dateString,
                        hour: hour,
                        temperatures: [],
                    };
                }
                // Push temperature to the array
                if (temperature !== undefined) {
                    acc[ key ].temperatures.push(temperature);
                }

                return acc;
            }, {});

            // Define the specific date to iterate over
            const specificDate = new Date(`${ isoDate }T00:00:00Z`);

            // Check if specificDate is valid
            if (isNaN(specificDate.getTime())) {
                console.error('Invalid specific date:', isoDate);
                return;
            }

            // Iterate over 24 hours of the specific day
            for (let i = 0; i < 24; i++) {
                // Set the hour for iteration
                specificDate.setUTCHours(i);

                const dateString = specificDate.toISOString().split('T')[ 0 ]; // YYYY-MM-DD format
                const hour = i;
                const key = `${ dateString }-${ hour }`;

                const existingData = groupedData[ key ];

                if (existingData) {
                    // If data exists for this hour, calculate the average temperature
                    const sum = existingData.temperatures.reduce((total, temp) => total + temp, 0);
                    const average = sum / existingData.temperatures.length;
                    existingData.averageTemperature = average.toFixed(1); // Ensure it has one decimal place
                    newChartData.push(existingData);
                } else {
                    // If data doesn't exist for this hour, push null
                    newChartData.push({
                        date: dateString,
                        hour: hour,
                        temperatures: [],
                        averageTemperature: null,
                    });
                }
            }

            // Sort the data by date and hour
            newChartData.sort((a, b) => {
                const dateA = new Date(
                    `${ a.date }T${ a.hour.toString().padStart(2, '0') }:00:00Z`,
                );
                const dateB = new Date(
                    `${ b.date }T${ b.hour.toString().padStart(2, '0') }:00:00Z`,
                );

                // Compare dates
                return dateA - dateB;
            });
            console.log('SpecificDateTemperatureChartData', newChartData)
            setSpecificDateTemperatureChartData(newChartData);
        });
    };

    const fetchLastWeekData = () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        // Generate an array of dates for the last 8 days (including current date)
        const lastWeekDates = [];
        for (let i = 0; i < 8; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            lastWeekDates.push(date.toLocaleDateString());
        }
        DeviceService.getDeviceLastMessages(deviceEUI, 'lastWeek').then((response) => {
            if (response) {
                const dailyTemperatureData = {};
                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt).toLocaleDateString();
                    const temperature = extractTemperature(result.data);
                    if (temperature !== undefined) {

                        if (!dailyTemperatureData[ date ]) {

                            dailyTemperatureData[ date ] = {
                                maxTemperature: temperature,
                                minTemperature: temperature,
                                temperatures: [ temperature ],
                            };
                        } else {
                            if (temperature > dailyTemperatureData[ date ].maxTemperature) {
                                dailyTemperatureData[ date ].maxTemperature = temperature;
                            }
                            if (temperature < dailyTemperatureData[ date ].minTemperature) {
                                dailyTemperatureData[ date ].minTemperature = temperature;
                            }

                            dailyTemperatureData[ date ].temperatures.push(temperature);

                        }
                    }

                });

                // Populate temperature data for each date, or set to null if not available
                const temperatureChartData = lastWeekDates.map((date) => {
                    return {
                        date: date,
                        temperatures: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].temperatures : [],
                        maxTemperature: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].maxTemperature : null,
                        minTemperature: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].minTemperature : null,
                    };
                });
                // Sort the temperature data by date
                temperatureChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log('LastWeekTemperatureChartData', temperatureChartData)
                setLastWeekTemperatureChartData(temperatureChartData);
            }
        });
    }
    const exportToExcel = (data) => {
        const columnTitles = Object.keys(data[ 0 ]);

        // Create a worksheet with column titles and their respective values
        const worksheet = XLSX.utils.json_to_sheet(data.map(item => {
            const rowData = {};
            columnTitles.forEach(title => {
                rowData[ title ] = item[ title ];
            });
            return rowData;
        }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([ excelBuffer ], { type: 'application/octet-stream' });
        saveAs(blob, 'TemperatureChartData.xlsx');
    };
    const fetchLastMonthData = () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 30); // Adjusted to last 7 days

        // Generate an array of dates for the last 8 days (including current date)
        const lastMonthDates = [];
        for (let i = 0; i < 31; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            lastMonthDates.push(date.toLocaleDateString());
        }
        DeviceService.getDeviceLastMessages(deviceEUI, 'lastMonth').then((response) => {
            if (response) {
                const dailyTemperatureData = {};
                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt).toLocaleDateString();
                    const temperature = extractTemperature(result.data);
                    if (temperature !== undefined) {
                        if (!dailyTemperatureData[ date ]) {
                            dailyTemperatureData[ date ] = {
                                maxTemperature: temperature,
                                minTemperature: temperature,
                                temperatures: [ temperature ],
                            };
                        } else {
                            if (temperature > dailyTemperatureData[ date ].maxTemperature) {
                                dailyTemperatureData[ date ].maxTemperature = temperature;
                            }
                            if (temperature < dailyTemperatureData[ date ].minTemperature) {
                                dailyTemperatureData[ date ].minTemperature = temperature;
                            }
                        }
                        dailyTemperatureData[ date ].temperatures.push(temperature);
                    }

                });
                // Populate temperature data for each date, or set to null if not available
                const temperatureChartData = lastMonthDates.map((date) => {
                    return {
                        date: date,
                        temperatures: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].temperatures : [],
                        maxTemperature: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].maxTemperature : null,
                        minTemperature: dailyTemperatureData[ date ] ? dailyTemperatureData[ date ].minTemperature : null,
                    };
                });
                // Sort the temperature data by date
                temperatureChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log('LastMonthTemperatureChartData', temperatureChartData)
                setLastMonthTemperatureChartData(temperatureChartData);
            }
        })
    }

    useEffect(() => {
        socket && socket.on('update_device_data', () => {
            console.log('update_device_data weatherChart')
            if (selectedPeriod && deviceEUI) {
                console.log('selectedPeriod socket weatherchart', selectedPeriod)
                if (selectedPeriod === 'lastDay') {
                    if (!isZoomingRef.current) {
                        fetchLastDayData()
                    }
                } else if (selectedPeriod === 'lastWeek') {
                    fetchLastWeekData()
                } else if (selectedPeriod === 'lastMonth') {
                    fetchLastMonthData()
                }
            }
        });

    }, [ socket ]);
    const singlePointData = lastDayTemperatureChartData?.map(({ averageTemperature }) => averageTemperature).filter(val => val !== null);
    const isSinglePoint = singlePointData.length === 1;
    const temperatures = lastDayTemperatureChartData?.map(({ averageTemperature }) => averageTemperature).filter(val => val !== null) || [];
    const minTemperature = temperatures.length > 0 ? Math.min(...temperatures) : null;
    const maxTemperature = temperatures.length > 0 ? Math.max(...temperatures) : null;
    const adjustedMin = minTemperature !== null ? minTemperature - 2.5 : null;
    const adjustedMax = maxTemperature !== null ? maxTemperature + 2.5 : null;
    const [ zoomData, setZoomData ] = useState([])

    const options = {
        series: [
            {
                name: 'Average Temperature',
                data: lastDayTemperatureChartData?.map(({ averageTemperature }) => (averageTemperature !== null ? averageTemperature : null)),
                color: '#FF5733',
                type: isSinglePoint ? 'scatter' : 'line', // Use scatter plot for a single point
            },
        ],
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        title:  {
            text:chartView ? 'Average Temperature (°C)':'',
            offsetX: 0,
            offsetY: 0,
        },
        chart: {
            height: 350,
            width: '100%',
            animations: {
                enabled: false,
            },
            min: adjustedMin,
            max: adjustedMax,
            id: 'my-chart',
            type: isSinglePoint ? 'scatter' : 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            zoom: {
                enabled: true,
                type: 'x', // Enable x-axis zooming
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: '<svg></svg>',
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: '<svg style="width: 20px; height: 20px;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_3952273"><path d="m10.035 18.069a7.981 7.981 0 0 0 3.938-1.035l3.332 3.332a2.164 2.164 0 0 0 3.061-3.061l-3.332-3.332a8.032 8.032 0 0 0 -12.68-9.619 8.034 8.034 0 0 0 5.681 13.715zm-4.267-12.301a6.033 6.033 0 1 1 -1.768 4.267 5.989 5.989 0 0 1 1.768-4.267zm.267 4.267a1 1 0 0 1 1-1h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 -1-1z"></path></svg>',
                    customIcons: [
                        {
                            icon: `
                            <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>`,
                            title: 'Export to Excel', // Tooltip for the button
                            index: 0, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                const data = lastDayTemperatureChartData?.map((val) => {
                                    return { date: `${ val.date } ${ val.hour }h`, temperatures: JSON.stringify(val.temperatures), averageTemperature: val.averageTemperature }
                                });
                                exportToExcel(data);
                            },
                        },
                    ],
                },
            },
            events: {
                zoomed: function(chartContext, { xaxis }) {
                    isZoomingRef.current = true
                    let newZoomData = [];

                    if (refreshZoomData.current.length > 0) {
                        for (let i = xaxis.min; i <= xaxis.max; i++) {
                            if (refreshZoomData.current[ i ] && refreshZoomData.current.length > 0) {
                                newZoomData.push({
                                    x: refreshZoomData.current[ i ].x,
                                    y: refreshZoomData.current[ i ].y,
                                });

                            } else {
                                newZoomData = refreshZoomData.current

                            }
                        }
                    } else {
                        for (let i = Math.floor(xaxis.min-1); i <= Math.floor(xaxis.max+1); i++) {
                            if (lastDayTemperatureChartData[ i ]) {
                                lastDayTemperatureChartData[ i ].hourlyAverageTemperature.forEach((e) => {
                                    newZoomData.push({
                                        x: lastDayTemperatureChartData[ i ].date + ' ' + e.time,
                                        y: e.average,
                                    });
                                });
                            } else {
                                console.error('No data to display on zoom');
                            }
                        }
                    }
                    setZoomData(newZoomData)
                    refreshZoomData.current = newZoomData
                    chartContext.updateOptions({
                        series: [
                            {
                                name: 'Average Temperature',
                                data: newZoomData,
                            },
                        ],
                        xaxis: {
                            categories: newZoomData.map(d => d.x),
                            tickAmount:  chartView ? 8 : 24,
                        },
                    }, true, true);
                },

                beforeResetZoom: function(chartContext) {
                    isZoomingRef.current = false
                    refreshZoomData.current = []
                    fetchLastDayData()
                },
                xAxisLabelClick: function(event, chartContext, config) {
                    // Extract the clicked label
                    const clickedLabel = event.target.textContent || event.target.innerText;
                    // Function to get the relevant data entry
                    function getHourlyData(date, hour, data) {
                        return data.find(entry => entry.date === date && entry.hour === hour);
                    }

                    // Parse the clicked label to extract date and hour
                    const [ datePart, timePart ] = clickedLabel.split(' ');
                    const [ hour, minute ] = timePart.replace('h', '').split(':').map(Number);

                    // Retrieve data for the specific date and hour
                    const selectedData = getHourlyData(datePart, hour, lastDayTemperatureChartData);

                    if (selectedData && selectedData.hourlyAverageTemperature) {
                        // Prepare the quarter-hour data
                        const quarterHourData = selectedData.hourlyAverageTemperature.map(e => ({
                            x: `${ selectedData.date } ${ e.time }`,
                            y: e.average,
                        }));

                        // Update chart options
                        setZoomData(quarterHourData);
                        refreshZoomData.current = quarterHourData;
                        isZoomingRef.current = true
                        chartContext.updateOptions({
                            series: [
                                {
                                    name: 'Quarter-Hourly Average Temperature',
                                    data: quarterHourData,
                                },
                            ],
                            xaxis: {
                                categories: quarterHourData.map(d => d.x),
                                tickAmount:  chartView ? 8 : 24,

                            },
                        }, true, true);
                    } else {
                        console.error('No quarter-hour data available for the selected label');
                    }
                },
                dataPointSelection: function(event, chartContext, config) {
                    if(!chartView){
                        const selectedIndex = config.dataPointIndex;
                        const selectedData = lastDayTemperatureChartData[ selectedIndex ];
                        if (selectedData && selectedData.hourlyAverageTemperature) {
                            const quarterHourData = selectedData.hourlyAverageTemperature.map(e => ({
                                x: selectedData.date + ' ' + e.time,
                                y: e.average,
                            }));
                            setZoomData(quarterHourData);
                            refreshZoomData.current = quarterHourData;
                            isZoomingRef.current = true
                            chartContext.updateOptions({
                                series: [
                                    {
                                        name: 'Average Temperature',
                                        data: quarterHourData,
                                    },
                                ],
                                xaxis: {
                                    categories: quarterHourData.map(d => d.x),
                                    tickAmount:  chartView ? 8 : 24,
                                },
                            }, true, true);
                        } else {
                            console.error('No quarter-hour data available for the selected point');
                        }
                    }

                },

            },

        },
        markers: {
            size: 4,
            colors: [ '#FF5733' ],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 4,
            },
        },
        colors: [ '#77B6EA' ],
        dataLabels: {
            enabled: false,
            formatter: function(val) {
                if (val) {
                    return val?.toFixed(1) + '°';
                } else if (val === 0) {
                    return val + '°';
                }
            },
        },

        xaxis: {
            categories:lastDayTemperatureChartData?.map((res) => {
                // Combine date and hour for display
                const formattedHour = res.hour < 10 ? `0${ res.hour }` : res.hour;
                return `${ res.date } ${ formattedHour }h`;
            }) ,
            tickPlacement: 'on',
            tickAmount:  chartView ? 8 : 24,
            labels: {
                rotate: -55,

            },
            offsetY: -5,
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 1,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            enabled: true,
        },
        tooltip: {
            enabled: true,
            shared:true,
            followCursor: false,
            hideEmptySeries: false,
            marker: { show: true },
            onDatasetHover: {
                highlightDataSeries: true,
            },
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 20,
                right:5,
            },
        },
        yaxis: {
            ...(adjustedMin && { min: adjustedMin }), // Conditionally set min
            ...(adjustedMax && { max: adjustedMax }),
            tickAmount: 5,
            labels: {
                formatter: (val) => {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    }
                },
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                axisBorder: {
                    show: true,
                    color: '#78909C',
                    offsetX: 0,
                    offsetY: 0,
                },
                offsetX: 0,
                offsetY: 0,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
            title:!chartView &&  {
                text: 'Average Temperature (°C)',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
            },
        },
    };
    const [ chartOptions, setChartOptions ] = useState(options)
    useEffect(() => {
        if (selectedPeriod && deviceEUI) {
            if (selectedPeriod === 'lastDay') {
                fetchLastDayData()
                setChartOptions(options)
            } else if (selectedPeriod === 'lastWeek') {
                fetchLastWeekData()
            } else if (selectedPeriod === 'lastMonth') {
                fetchLastMonthData()
            }
        }
    }, [ selectedPeriod, deviceEUI ]);
    useEffect(() => {
        if (lastDayTemperatureChartData) {
            setChartOptions(options)
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: [
                    {
                        name: 'Average Temperature',
                        data: lastDayTemperatureChartData?.map(({ averageTemperature }) => (averageTemperature !== null ? averageTemperature : null)),
                        color: '#FF5733',
                        type: isSinglePoint ? 'scatter' : 'line', // Use scatter plot for a single point

                    },
                ],
                xaxis: {
                    categories: lastDayTemperatureChartData?.map((res) => {
                        // Combine date and hour for display
                        const formattedHour = res.hour < 10 ? `0${ res.hour }` : res.hour;
                        return `${ res.date } ${ formattedHour }h`;
                    },

                    ),
                    tickAmount:  chartView ? 8 : 24,
                    offsetY: -5,

                },
            }));
        }
    }, [ lastDayTemperatureChartData,selectedPeriod ]);

    const barChartOptions = {
        series: [ {
            name: 'Max Temperature ',
            data: lastWeekTemperatureChartData?.map(({ maxTemperature }) => maxTemperature),
            color: '#FF5733',
        }, {
            name: 'Min Temperature ',
            data: lastWeekTemperatureChartData?.map(({ minTemperature }) => minTemperature),
            color: '#77B6EA',
        } ],
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
        },
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [
                        {
                            icon: `
                    <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>`,
                            title: 'Export to Excel', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                const data = lastWeekTemperatureChartData?.map((val) => {
                                    return { date: `${ val.date } `, temperatures: JSON.stringify(val.temperatures), minTemperature: val.minTemperature, maxTemperature: val.maxTemperature }
                                })
                                exportToExcel(data)
                            },
                        },
                    ],
                },
            },
            events: {
                dataPointMouseEnter: function(event) {
                    event.target.style.cursor = 'pointer';
                },
                dataPointSelection: function(event, chartContext, config) {
                    const { dataPointIndex } = config;
                    const clickedDate = lastWeekTemperatureChartData[ dataPointIndex ].date;
                    const maxTemp = lastWeekTemperatureChartData[ dataPointIndex ].maxTemperature;
                    const minTemp = lastWeekTemperatureChartData[ dataPointIndex ].minTemperature;

                    console.log('Clicked Date:', clickedDate);
                    console.log('Max Temperature:', maxTemp);
                    console.log('Min Temperature:', minTemp);
                    setMinValue(minTemp)
                    setMaxValue(maxTemp)
                    fetchSpecificDayData(clickedDate)
                    // You can use this data to update your chart or display additional information
                    // For example, you might want to update a detailed chart or show a modal
                },
            },
        },
        stroke: {
            show: true,
            width: 5,
            colors: [ 'transparent' ],
        },
        plotOptions: {
            bar: {
                barWidth: '20%',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },

        legend: {
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'left',
            floating: true,
        },

        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function(val) {
                if (val !== null && val === 0) {
                    return val + '°C'
                } else if (val === null) {
                    return;
                } else return;
            },
            offsetY: -20,
            offsetX: 5,
            style: {
                fontSize: '0.6rem',
                colors: [ '#304758' ],
            },
        },
        xaxis: {
            type: 'category',
            tickPlacement: 'between',
            categories: lastWeekTemperatureChartData?.map((res) => {
                return `${ res.date }`
            }),
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [ 0, 100 ],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 25,
                right: 0,
            },
        },
        yaxis: {
            title: {
                text: 'Weakly Min/Max Temperature  (°C)',
            },
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                show: true,
                formatter: function(val) {
                    return val?.toFixed(1) + '°C';
                },
            },
        },

    };

    const monthBarChartOptions = {
        series: [ {
            name: 'Max Temperature ',
            data: lastMonthTemperatureChartData?.map(({ maxTemperature }) => maxTemperature),
            color: '#FF5733',
        }, {
            name: 'Min Temperature ',
            data: lastMonthTemperatureChartData?.map(({ minTemperature }) => minTemperature),
            color: '#77B6EA',
        } ], noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [
                        {
                            icon: `
                    <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>`,
                            title: 'Export to Excel', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                const data = lastMonthTemperatureChartData?.map((val) => {
                                    return { date: `${ val.date } `, temperatures: JSON.stringify(val.temperatures), minTemperature: val.minTemperature, maxTemperature: val.maxTemperature }
                                })
                                exportToExcel(data)
                            },
                        },
                    ],
                },

            },
            events: {
                dataPointMouseEnter: function(event) {
                    event.target.style.cursor = 'pointer';
                },
                dataPointSelection: function(event, chartContext, config) {
                    const { dataPointIndex } = config;
                    const clickedDate = lastMonthTemperatureChartData[ dataPointIndex ].date;
                    const maxTemp = lastMonthTemperatureChartData[ dataPointIndex ].maxTemperature;
                    const minTemp = lastMonthTemperatureChartData[ dataPointIndex ].minTemperature;

                    console.log('Clicked Date:', clickedDate);
                    console.log('Max Temperature:', maxTemp);
                    console.log('Min Temperature:', minTemp);
                    setMinValue(minTemp)
                    setMaxValue(maxTemp)
                    fetchSpecificDayData(clickedDate)

                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: [ 'transparent' ],
        },
        plotOptions: {
            bar: {
                barWidth: '100%',
                columnWidth: '80%',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
        legend: {
            position: 'top',
            offsetY: -10,
            horizontalAlign: 'left',
            floating: true,
        },
        dataLabels: {
            enabled: true,
            formatter: function(val) {
                if (val !== null && val === 0) {
                    return val + '°C'
                } else return;
            },
            offsetY: -20,
            offsetX: 5,
            style: {
                fontSize: '0.6rem',
                colors: [ '#304758' ],
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 25,
                right: 0,
            },
        },
        xaxis: {
            categories: lastMonthTemperatureChartData?.map((res) => {
                return `${ res.date }`
            }),
            tickPlacement: 'between',
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [ 0, 100 ],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
        },
        yaxis: {
            title: {
                text: 'Monthly Min/Max Temperature  (°C)',
            },
            labels: {
                show: true,
                formatter: function(val) {
                    return val + '°C';
                },
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
    };

    const specificDayOptions = {
        series: [
            {
                name: 'Average Temperature',
                data: specificDateTemperatureChartData?.map(({ averageTemperature }) => (averageTemperature !== null ? averageTemperature : null)),
                color: '#FF5733',
                type: isSinglePoint ? 'scatter' : 'line', // Use scatter plot for a single point
            },
        ],
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        chart: {
            height: 350,
            width: '100%',
            min: minValue - 2.5,
            max: maxValue + 2.5,
            type: isSinglePoint ? 'scatter' : 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false || '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [
                        {
                            icon: `<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#000000"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53035 18.5303C6.23745 18.8232 5.76258 18.8232 5.46969 18.5303C5.17679 18.2374 5.17679 17.7626 5.46968 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z" fill="#000000"/>
</svg>`,
                            title: 'Return to last week chart', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                setSpecificDateTemperatureChartData([])
                            },
                        },
                    ],
                },
            },
        },
        markers: {
            size: 4,
            colors: [ '#FF5733' ],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 4,
            },
        },
        colors: [ '#77B6EA' ],
        dataLabels: {
            enabled: false,
            formatter: function(val) {
                if (val) {
                    return val?.toFixed(1) + '°C';
                } else if (val === 0) {
                    return val + '°C';
                }
            },
        },

        xaxis: {
            categories: specificDateTemperatureChartData?.map((res) => {
                // Combine date and hour for display
                const formattedHour = res.hour < 10 ? `0${ res.hour }` : res.hour;
                return `${ res.date } ${ formattedHour }h`;
            }),
            tickPlacement: 'between',
            tickAmount: chartView && 8,
            labels: {
                rotate: -55,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            enabled: true,
        },
        tooltip: {
            enabled: true,
            followCursor: false,
            hideEmptySeries: false,
            marker: { show: true },
            onDatasetHover: {
                highlightDataSeries: true,
            },
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 25,
                right: 0,
            },
        },
        yaxis: {
            ...({ min: minValue - 2.5 }),
            ...({ max: maxValue + 2.5 }),
            //   ...({ min: Math.min(...(specificDateTemperatureChartData?.map(({ averageTemperature }) => averageTemperature).filter(val => val !== null))) - 2.5 }), // Conditionally set min
            //    ...({ max: Math.max(specificDateTemperatureChartData?.map(({ averageTemperature }) => averageTemperature).filter(val => val !== null)) + 2.5 }),
            tickAmount: 5,
            labels: {
                formatter: (val) => {
                    if (val !== null && val !== undefined) {
                        return val?.toFixed(1) + '°C';
                    } else if (val === 0) {
                        return 0 + '°C';
                    }
                },
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                axisBorder: {
                    show: true,
                    color: '#78909C',
                    offsetX: 0,
                    offsetY: 0,
                },
                offsetX: 0,
                offsetY: 0,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
            title: {
                text: 'Average Temperature (°C)',
                rotate: -90,
                offsetX: 7,
                offsetY: 0,
            },
        },
    };
    return (
        <div id="chart">
            {selectedPeriod === 'lastDay' && !chartDataAvailable && lastDayTemperatureChartData.length > 0 && <> <style>
                {`
                    .apexcharts-tooltip.apexcharts-theme-light {
                        transform: translateY(-100%);
                    }
                `}
            </style>
            <ReactApexChart options={chartOptions} series={chartOptions.series} type="line" height={chartOptions.chart.height} /></>
            }
            {selectedPeriod === 'lastDay' && !chartDataAvailable && lastDayTemperatureChartData.length == 0 && <Typography variant="body1" color="text.secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',  marginTop: '25%',
                marginBottom: '25%',
                height: '100%',
                textAlign: 'center',
            }}>Loading...</Typography>
            }
            {selectedPeriod === 'lastDay' && chartDataAvailable && <Typography variant="body1" color="text.secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',  marginTop: '25%',
                marginBottom: '25%',
                height: '100%',
                textAlign: 'center',
            }}>No coherent data available to display</Typography>}
            {selectedPeriod === 'lastWeek' && !chartDataAvailable && specificDateTemperatureChartData.length === 0 && <> <style>
                {`
                    .apexcharts-tooltip.apexcharts-theme-light {
                        transform: translateY(-80%);
                    }
                `}
            </style> <ReactApexChart options={barChartOptions} series={barChartOptions.series} type="bar" height={barChartOptions.chart.height} /></>}
            {selectedPeriod === 'lastWeek' && specificDateTemperatureChartData.length === 0 && chartDataAvailable && <Typography variant="body1" color="text.secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',  marginTop: '25%',
                marginBottom: '25%',
                height: '100%',
                textAlign: 'center',
            }}>No coherent data available to display</Typography>}
            {selectedPeriod === 'lastWeek' && !chartDataAvailable && specificDateTemperatureChartData.length > 0 && <> <style>
                {`
                    .apexcharts-tooltip.apexcharts-theme-light {
                        transform: translateY(-100%);
                    }
                `}
            </style>
            <ReactApexChart options={specificDayOptions} series={specificDayOptions.series} type="line" height={specificDayOptions.chart.height} /></>
            }
            {selectedPeriod === 'lastMonth' && !chartDataAvailable && specificDateTemperatureChartData.length > 0 && <> <style>
                {`
                    .apexcharts-tooltip.apexcharts-theme-light {
                        transform: translateY(-100%);
                    }
                `}
            </style>
            <ReactApexChart options={specificDayOptions} series={specificDayOptions.series} type="line" height={specificDayOptions.chart.height} /></>
            }
            {selectedPeriod === 'lastMonth' && specificDateTemperatureChartData.length === 0 && !chartDataAvailable && <> <style>
                {`
                    .apexcharts-tooltip.apexcharts-theme-light {
                        transform: translateY(-80%);
                    }
                `}
            </style><ReactApexChart options={monthBarChartOptions} series={monthBarChartOptions.series} type="bar" height={monthBarChartOptions.chart.height} /></>}
            {selectedPeriod === 'lastMonth' && chartDataAvailable && <Typography variant="body1" color="text.secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',  marginTop: '25%',
                marginBottom: '25%',
                height: '100%',
                textAlign: 'center',
            }}>No coherent data available to display</Typography>}
        </div>
    );
};

export default WeatherChart;
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { addLocation } from '../../actions/locationAction';
import axios from 'axios';
import Swal from 'sweetalert2';
import LocationMap from '../../Views/Map';
import { useParams } from 'react-router-dom';
import { getEntityById } from '../../actions/entityAction';
import { Card, CardContent } from '@material-ui/core';
import Box from '@mui/material/Box';
const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    locationImage: yup.mixed(),

});

const AddLocationModal = ({ recentEntity,setRecentEntity }) => {
    const dispatch = useDispatch();
    const [ previewImage, setPreviewImage ] = useState(null);
    const [ circle, setCircle ] = useState(null);
    const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
    const { entityId } = useParams();

    React.useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if(entityId){

                console.log('recent entity is empty')
                console.log('entity',entityId)

                dispatch(getEntityById(entityId)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity,entityId ])

    return (
        <>
            <Card
                elevation={2}
                style={{ backgroundColor: '#F4F6F8', height: '100%', marginRight:10, marginLeft:10  }}
            >
                <h5 className="card-header d-flex justify-content-between align-items-center">
            Add Location
                </h5>

                <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Formik
                            validationSchema={schema}
                            onSubmit={async(values) => {
                                // Display image preview
                                if (!circle) {
                                    console.error('no circle')
                                    // Handle error condition here, e.g., display an alert
                                    Swal.fire('Location position in missing.', ' Please click on  map to set location position.', 'error');
                                    return;
                                }
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setPreviewImage(reader.result);
                                };
                                if (values.locationImage) {
                                    reader.readAsDataURL(values.locationImage);
                                } else {
                                    setPreviewImage(null);
                                }

                                Swal.fire({
                                    title: 'Do you really want to add location?',
                                    showDenyButton: true,
                                    confirmButtonColor: 'red',
                                    confirmButtonText: 'Yes',
                                    denyButtonText: 'No',
                                }).then(async(result) => {
                                    if (result.isConfirmed) {
                                        // Upload the image
                                        const formData = new FormData();
                                        formData.append('image', values.locationImage);

                                        try {
                                            const uploadResponse = await axios.post(
                                                `${ REACT_APP_SERVER_API_URL }/upload`,
                                                formData,
                                            );

                                            if (uploadResponse.data && uploadResponse.data.filename) {
                                                // Image upload successful
                                                const filename = `/uploads/${ uploadResponse.data.filename }`;

                                                // Dispatch addLocation action
                                                dispatch(
                                                    addLocation(
                                                        values.name,
                                                        filename,
                                                        values.city,
                                                        values.state,
                                                        values.zip,
                                                        values.description,
                                                    circle?.center?.lng,
                                                    circle?.center?.lat,
                                                    circle?.radius,
                                                    entityId,
                                                    ),
                                                ).then((response) => {
                                                    if(response.success){
                                                        window.location.href=`/entity/${ entityId }`
                                                    }else{
                                                        Swal.fire({
                                                            icon: 'error',
                                                            text: 'Cannot add location!',
                                                        });
                                                    }
                                                    //   getLocationsListByEntityId(entityId);

                                                });

                                                // Fetch the updated locations list
                                                //     getLocationsListByEntityId(entityId);

                                                // Close the modal
                                                //  handleClose();
                                            } else {
                                                Swal.fire({
                                                    icon: 'error',
                                                    text: 'Image upload failed!',
                                                });
                                            }
                                        } catch (error) {
                                            Swal.fire({
                                                icon: 'error',
                                                text: 'Something went wrong with image upload!',
                                            });
                                        }
                                    } else if (result.isDenied) {
                                        Swal.fire('Location not added', '', 'info');
                                    }
                                });
                            }}
                            initialValues={{
                                name: '',
                                description: '',
                                city: '',
                                state: '',
                                zip: '',
                                locationImage: null,
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                setFieldValue,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}  className='mb-2'>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="validationFormikImage">
                                            <Form.Label>Location Image</Form.Label>
                                            <div className="custom-file">
                                                <Form.Control
                                                    type="file"
                                                    name="locationImage"
                                                    onChange={(event) => {
                                                        setFieldValue('locationImage', event.currentTarget.files[ 0 ]);

                                                        // Display image preview
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            setPreviewImage(reader.result);
                                                        };
                                                        if (event.currentTarget.files[ 0 ]) {
                                                            reader.readAsDataURL(event.currentTarget.files[ 0 ]);
                                                        } else {
                                                            setPreviewImage(null);
                                                        }
                                                    }}
                                                    isInvalid={!!errors.locationImage}
                                                    title="No file chosen"
                                                    className="custom-file-input"
                                                />
                                                <label className="custom-file-label">
                                                    {previewImage ? `File chosen: ${ values.locationImage.name }` : 'Choose File'}
                                                </label>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.locationImage}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {previewImage && (
                                            <Col xs={3} className="mt-4">
                                                <img
                                                    src={previewImage}
                                                    alt="Preview"
                                                    className="img-thumbnail"
                                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                />
                                            </Col>
                                        )}
                                    </Row>

                                    <Row className='mb-3'> <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Location name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Location name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group></Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="validationFormik02">
                                            <Form.Label>Location Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Location Description"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.description}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationFormik03">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                name="city"
                                                value={values.city}
                                                onChange={handleChange}
                                                isInvalid={!!errors.city}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationFormik04">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                name="state"
                                                value={values.state}
                                                onChange={handleChange}
                                                isInvalid={!!errors.state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.state}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationFormik05">
                                            <Form.Label>Zip</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Zip"
                                                name="zip"
                                                value={values.zip}
                                                onChange={handleChange}
                                                isInvalid={!!errors.zip}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.zip}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <div className="text m-3" >Please click on map to set the map location </div>
                                        <LocationMap circle={circle} setCircle={setCircle}  apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'} />
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Button
                                            style={{ display: 'block', margin: '0 auto', marginBottom: '50px', width: '30%' }}
                                            type="submit"
                                            className="btn btn-success btn-lg"
                                        >
    Submit form
                                        </Button>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </CardContent>
            </Card>

        </>
    );
};

export default AddLocationModal;

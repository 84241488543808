import {
    ADD_DEVICE_SUCCESS,
    ADD_DEVICE_FAIL,
    GET_DEVICES_LIST_SUCCESS,
    GET_DEVICES_LIST_FAIL,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case ADD_DEVICE_SUCCESS:
        return {
            ...state,
            data: payload.data,
            isLoggedIn: true,
        };
    case ADD_DEVICE_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };

    case GET_DEVICES_LIST_SUCCESS:
        return {
            ...state,
            deviceList: payload.deviceList,
            isLoggedIn: true,
        };
    case GET_DEVICES_LIST_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };
    default:
        return state;
    }
}

export const generatePayload = (out1, out2) => {
    // Convert booleans to binary strings
    const out1Binary = out1 ? '1' : '0';
    const out2Binary = out2 ? '1' : '0';
    // Construct the hexadecimal payload
    const payload = `020${ out1Binary }0${ out2Binary }`;
    console.log('==> downlink payload hex', payload);

    // Convert hexadecimal to Base64
    const base64Payload = Buffer.from(payload, 'hex').toString('base64');

    console.log('==> downlink payload base64 encoded ', base64Payload);
    return base64Payload;
};

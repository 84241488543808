import { Card, CardContent, Divider } from '@material-ui/core';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceListByLocationId } from '../actions/deviceAction';
import Button from 'react-bootstrap/Button';
import Table from './DeviceTable/DataTable';
import { useSocket } from '../SocketContext';
import Link from '@mui/material/Link';
export default function ListDevicesCard({ locationId,isAdmin }) {
    const socket = useSocket();
    const dispatch = useDispatch();
    const { deviceList } = useSelector((state) => state.deviceList);
    useEffect(() => {
        dispatch(getDeviceListByLocationId(locationId));
    }, [ dispatch, locationId ]);
    useEffect(() => {
        socket.on('update_device_data', () => {
            dispatch(getDeviceListByLocationId(locationId));
        });
        return () => {
            ////socket.disconnect();
        };
    },  [ dispatch, locationId,socket ]);
    return (
        <>
            {deviceList ? (
                <Card
                    elevation={2}
                    style={{ backgroundColor: '#F4F6F8' }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <h5 className="card-header d-flex justify-content-between align-items-center">
             Device List
                        {isAdmin && (
                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <Link color="inherit" href={`/location/${ locationId }/addDevice`}>
                                    <Button color="success" variant="success" className='mr-1'>
                        Add Device
                                    </Button>
                                </Link>

                            </Box>
                        )}

                    </h5>

                    <Divider />
                    <CardContent>
                        <Table data={deviceList} locationId={locationId} isAdmin={isAdmin} />
                    </CardContent>
                    <Divider />
                </Card>
            ) : (
                <Card elevation={2} style={{ backgroundColor: '#F4F6F8' }}>
                    <h5 className="card-header d-flex justify-content-between align-items-center">
             Device List
                    </h5>

                    <CardContent>
                        <Box
                            className=" d-flex align-items-center justify-content-center "
                            sx={{ flexGrow: 1 }}
                        >
                            <div className="spinner-border text-success "></div>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </>
    );
}

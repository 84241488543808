import axios from 'axios';
import authHeader from './auth-header';
const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
const API_URL =`${ REACT_APP_SERVER_API_URL }/api/location/`;

const getLocationsList = () => {
    return axios.get(API_URL + 'getLocationsList',{
        headers: authHeader(),
    });
};
const getLocationsListByEntityId = (ownerId) => {
    console.log('ownerId',ownerId)
    return axios
        .post(API_URL + 'getLocationsListByEntityId', {
            entityId: ownerId,
        },{
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};
const getLocationById = (locationId) => {
    return axios
        .post(API_URL + 'getLocationById', {
            id: locationId,
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
};
const addLocation = (name, image, city, state, zipCode, description,lng,lat,radius, entityId) => {
    return axios.post(API_URL + 'addRoundLocation', {
        name, image, city, state, zipCode, description,lng,lat,radius, entityId,
    }, {
        headers: authHeader(),
    });
};

const updateLocation = (locationId,name, image, city, state, zipCode, description, userId) => {
    return axios.post(API_URL + 'updateLocation', {
        locationId,
        name,
        image,
        city,
        state,
        zipCode,
        description,
        userId,
    }, {
        headers: authHeader(),
    });
};

const updateRoundLocation = (locationId,name, image, city, state, zipCode, description,lng,lat,radius, userId) => {
    return axios.post(API_URL + 'updateRoundLocation', {
        locationId,
        name,
        image,
        city,
        state,
        zipCode,
        description,
        lat,lng,radius,
        userId,
    }, {
        headers: authHeader(),
    });
};

const deleteLocationById = (locationId) => {
    return axios.post(API_URL + 'deleteLocationById', {
        id: locationId,
    }, {
        headers: authHeader(),
    });
};

export default {
    getLocationsList,
    addLocation,
    deleteLocationById,
    getLocationsListByEntityId,
    getLocationById,
    updateLocation,
    updateRoundLocation,
};

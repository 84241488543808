import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import LocationMap from '../../Views/Map';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLocationsById } from '../../actions/locationAction';
import { getEntityById } from '../../actions/entityAction';
import { updateRoundLocation } from '../../actions/locationAction'
import { Card, CardContent } from '@material-ui/core';
import Box from '@mui/material/Box';
const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    locationImage: yup.mixed(),
    circle: yup.object().shape({
        center: yup.object().shape({
            lng: yup.number().required(),
            lat: yup.number().required(),
        }),
        radius: yup.number().required(),
    }),
});

const UpdateLocationView = ({  recentEntity,setRecentEntity }) => {
    const { user } = useSelector((state) => state.auth);
    const [ previewImage, setPreviewImage ] = useState(null);
    const [ circle, setCircle ] = useState(null);
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.location);

    const { locationId } = useParams();
    const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
    useEffect(() => {
        dispatch(getLocationsById(locationId))
    }, [ dispatch, locationId ]);

    useEffect(() => {
        if (location) {
            console.log( 'radius', location?.radius)
            setCircle({
                center: {
                    lng: location?.lng,
                    lat: location?.lat,
                },
                radius: location?.radius,
            });
        }
    }, [ location ]);
    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if(location?.entities[ 0 ]){

                console.log('recent entity is empty')
                console.log('entity',location?.entities[ 0 ])

                dispatch(getEntityById(location?.entities[ 0 ]?.id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity,location ])
    useEffect(() => {
        setPreviewImage(location ? `${ process.env.REACT_APP_SERVER_API_URL }${ location.image }` : null);
    }, [ location ]);

    return (
        <>
            {location &&(
                <Card
                    elevation={2}
                    style={{ backgroundColor: '#F4F6F8', height: '100%', marginRight:10, marginLeft:10  }}
                >
                    <h5 className="card-header d-flex justify-content-between align-items-center">
            Update Location
                    </h5>

                    <CardContent>
                        <Box sx={{ flexGrow: 1 }}>

                            <Formik
                                validationSchema={schema}
                                onSubmit={async(values) => {
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        setPreviewImage(reader.result);
                                    };
                                    if (values.locationImage instanceof Blob) {
                                        reader.readAsDataURL(values.locationImage);
                                    } else {

                                        setPreviewImage(null);
                                    }

                                    Swal.fire({
                                        title: 'Do you really want to update location?',
                                        showDenyButton: true,
                                        confirmButtonColor: 'red',
                                        confirmButtonText: 'Yes',
                                        denyButtonText: 'No',
                                    }).then(async(result) => {
                                        if (result.isConfirmed) {
                                            // Check if the image has changed
                                            if (values.locationImage!==location.image) {

                                                // Upload the new image
                                                const formData = new FormData();
                                                formData.append('image', values.locationImage);

                                                try {
                                                    const uploadResponse = await axios.post(
                                                        `${ REACT_APP_SERVER_API_URL }/upload`,
                                                        formData,
                                                    );

                                                    if (uploadResponse.data && uploadResponse.data.filename) {
                                                        // Image upload successful
                                                        const filename = `/uploads/${ uploadResponse.data.filename }`;
                                                        console.log('updateLocation passed circle',circle);
                                                        dispatch(
                                                            updateRoundLocation(
                                                                location.id,
                                                                values.name,
                                                                filename,
                                                                values.city,
                                                                values.state,
                                                                values.zip,
                                                                values.description,
                                circle?.center?.lng,
                                circle?.center?.lat,
                                circle?.radius,
                                user.id,
                                                            ),
                                                        ).then((response) => {
                                                            if(response.success){
                                                                window.location.href = `/location/${ location.id }`;
                                                            }else{
                                                                Swal.fire({
                                                                    icon: 'error',

                                                                    text: 'Image upload failed!',
                                                                });
                                                            }
                                                        });

                                                        // Close the modal

                                                    } else {
                                                        Swal.fire({
                                                            icon: 'error',

                                                            text: 'Image upload failed!',
                                                        });
                                                    }
                                                } catch (error) {
                                                    Swal.fire({
                                                        icon: 'error',

                                                        text: 'Something went wrong with image upload!',
                                                    });
                                                }
                                            } else {
                                                // Image is not changed, update other location details only
                                                console.log('updateLocation passed circle',circle);
                                                dispatch(
                                                    updateRoundLocation(
                                                        location.id,
                                                        values.name,
                                                        location.locationImage, // Keep the same image filename
                                                        values.city,
                                                        values.state,
                                                        values.zip,
                                                        values.description,
                        circle?.center?.lng,
                        circle?.center?.lat,
                        circle?.radius,
                        user.id,
                                                    ),
                                                ).then((response) => {
                                                    console.log('response',response)
                                                    if(response.success){
                                                        window.location.href = `/location/${ location.id }`;
                                                    }else{
                                                        console.log('response',response)
                                                        Swal.fire({
                                                            icon: 'error',

                                                            text: 'Update Location failed!',
                                                        });
                                                    }
                                                });

                                            }
                                        } else if (result.isDenied) {
                                            Swal.fire('Location not updated', '', 'info');
                                        }
                                    });
                                }}
                                initialValues={{
                                    name: location?.name || '',
                                    description: location?.description || '',
                                    city: location?.city || '',
                                    state: location?.state || '',
                                    zip: location?.zipCode || '',
                                    locationImage: location?.image || null,
                                    circle: {
                                        center: { lng: location?.lng || 0, lat: location?.lat || 0 },
                                        radius: location?.radius || 0,
                                    },
                                }}
                            >
                                {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationFormikImage">
                                                <Form.Label>Location Image</Form.Label>
                                                <div className="custom-file">
                                                    <Form.Control
                                                        type="file"
                                                        name="locationImage"
                                                        onChange={(event) => {
                                                            setFieldValue('locationImage', event.currentTarget.files[ 0 ]);

                                                            // Display image preview
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                setPreviewImage(reader.result);
                                                            };
                                                            if (event.currentTarget.files[ 0 ]) {
                                                                reader.readAsDataURL(event.currentTarget.files[ 0 ]);
                                                            } else {
                                                                setPreviewImage(null);
                                                            }
                                                        }}
                                                        isInvalid={!!errors.locationImage}
                                                        title="No file chosen"
                                                        className="custom-file-input"
                                                    />
                                                    <label className="custom-file-label">
                                                        {previewImage ? 'File chosen' : 'Choose File'}
                                                    </label>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.locationImage}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {previewImage && (
                                                <Col xs={3} className="mt-4">
                                                    <img
                                                        src={previewImage}
                                                        alt="Preview"
                                                        className="img-thumbnail"
                                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationFormik01">
                                                <Form.Label>Location name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Location name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationFormik02">
                                                <Form.Label>Location Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Location Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationFormik03">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.city}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.city}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationFormik04">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="State"
                                                    name="state"
                                                    value={values.state}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.state}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationFormik05">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Zip"
                                                    name="zip"
                                                    value={values.zip}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.zip}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <div className="text mb-3" >Please click on map to set the map location </div>

                                            <LocationMap circle={circle} location={location} devices={location?.devices}  gateways={location?.gateways}  setCircle={setCircle} apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'}  />
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Button
                                                style={{ display: 'block', margin: '0 auto', marginBottom: '50px',width:'30%' }}
                                                type="submit"
                                                className="btn btn-success btn-lg"
                                            >
    Submit form
                                            </Button>
                                        </Row>

                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </CardContent>
                </Card>)}</>
    );
};

export default UpdateLocationView;

function flipHexString(hexValue, hexDigits) {
    let h = hexValue.substr(0, 2);
    for (let i = 0; i < hexDigits; ++i) {
        h += hexValue.substr((2 + (((hexDigits - 1 - i)) * 2)), 2);
    }
    return h;
}

function hexTooFloat(hex) {
    const s = hex >> 31 ? -1 : 1;
    const e = (hex >> 23) & 0xFF;
    return (s * ((hex & 0x7fffff) | 0x800000) * 1.0 / Math.pow(2, 23) * Math.pow(2, (e - 127))).toFixed(2);
}
const GPIO_0_MASK = 0x01;
const GPIO_1_MASK = 0x02;
export const interpretHexValues = (hexString,
    setOutput0,
    setOutput1,
    setInput0,
    setInput1,
    setBatteryVoltage,
    setPressure,
    setTemperature,
    setBatteryCurrent,
    setCO2, setPh) => {
    console.log('interpretHexValues')
    console.log('setOutput0 ',setOutput0)
    const extractHexValue = (start, size) => parseInt(hexString?.slice(start * 2, (start + size) * 2), 16);

    // Sizes of various data fields
    const lengthSize = 1;
    const outputsSize = 1;
    const inputsSize = 1;
    const batteryVoltageSize = 4;
    const batteryCurrentSize = 4;
    const temperatureSize = 4;
    const phSize = 4;
    const pressureSize = 4;
    const co2Size = 4;

    const outputs = extractHexValue(lengthSize, outputsSize);
    const inputs = hexString?.slice((lengthSize + outputsSize) * 2, (lengthSize + outputsSize + inputsSize) * 2);
    const output0 = (outputs & GPIO_0_MASK) !== 0;
    const output1 = (outputs & GPIO_1_MASK) !== 0;
    const input0 = (inputs & GPIO_0_MASK) !== 0;
    const input1 = (inputs & GPIO_1_MASK) !== 0;
    console.log('inputs',inputs)
    console.log('inputs 0',input0)
    console.log('inputs 1',input1)
    // Extracting and converting floating-point values
    const hexToFloat = (hexValue) => {
        const result = parseFloat(hexTooFloat(flipHexString(`0x${ hexValue }`, 8)))
        return result
    };

    const hexBatteryVoltage = hexString?.slice((lengthSize + outputsSize + inputsSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize) * 2);
    const hexBatteryCurrent = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize) * 2)
    const hexTemp = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2)
    const hexPh = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2)
    const hexPressure = (hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize) * 2))
    const hexCO2 = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize + co2Size) * 2)

    console.log('hexBatteryVoltage hexString',hexString)
    console.log('hexBatteryVoltage',hexBatteryVoltage)
    const batteryVoltage = hexBatteryVoltage === 'ffffffff' ? null : hexToFloat(hexBatteryVoltage);
    const batteryCurrent = hexBatteryVoltage === 'ffffffff' ? null : hexToFloat(hexBatteryCurrent);
    const temperature = hexTemp === 'ffffffff' ? null : hexToFloat(hexTemp);

    const pH = hexPh === 'ffffffff' ? null : hexToFloat(hexPh);
    const pressure = hexPressure === 'ffffffff' ? null : hexToFloat(hexPressure);
    const co2 = hexCO2 === 'ffffffff' ? null : hexToFloat(hexCO2);
    console.log('return',{ pH, batteryVoltage, pressure, temperature, batteryCurrent, co2, output0, output1, input0, input1 })
    if(setOutput0){
        setOutput0(output0);
        setOutput1(output1);
        setInput0(input0);
        setInput1(input1);
        setPh(pH);
        setBatteryVoltage(batteryVoltage);
        setPressure(pressure);
        setTemperature(temperature);
        setBatteryCurrent(batteryCurrent);
        setCO2(co2);
    }

    return ({ pH, batteryVoltage, pressure, temperature, batteryCurrent, co2, output0, output1, input0, input1 })

};
// Sizes of various data fields
const lengthSize = 1;
const outputsSize = 1;
const inputsSize = 1;
const batteryVoltageSize = 4;
const batteryCurrentSize = 4;
const temperatureSize = 4;
const phSize = 4;
const pressureSize = 4;
const co2Size = 4;
const hexToFloat = (hexValue) => parseFloat(hexTooFloat(flipHexString(`0x${ hexValue }`, 8)));

export const interpretData = (hexString) => {

    const extractHexValue = (start, size) => parseInt(hexString?.slice(start * 2, (start + size) * 2), 16);

    const outputs = extractHexValue(lengthSize, outputsSize);

    const output0 = (outputs & GPIO_0_MASK) !== 0;
    const output1 = (outputs & GPIO_1_MASK) !== 0;
    const inputs = hexString?.slice((lengthSize + outputsSize) * 2, (lengthSize + outputsSize + inputsSize) * 2);
    const input0 = (inputs & GPIO_0_MASK) !== 0;
    const input1 = (inputs & GPIO_1_MASK) !== 0;

    // Extracting and converting floating-point values

    const hexBatteryVoltage = hexString?.slice((lengthSize + outputsSize + inputsSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize) * 2);

    console.log('hexBatteryVoltage hexString',hexString)
    console.log('hexBatteryVoltage',hexBatteryVoltage)
    const hexBatteryCurrent = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize) * 2)
    const hexTemp = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2)
    const hexPh = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2)
    const hexPressure = (hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize) * 2))
    const hexCO2 = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize + co2Size) * 2)

    const batteryVoltage = hexBatteryVoltage === 'ffffffff' ? null : hexToFloat(hexBatteryVoltage);
    const batteryCurrent = hexBatteryVoltage === 'ffffffff' ? null : hexToFloat(hexBatteryCurrent);
    const temperature = hexTemp === 'ffffffff' ? null : hexToFloat(hexTemp);
    const pH = hexPh === 'ffffffff' ? null : hexToFloat(hexPh);
    const pressure = hexPressure === 'ffffffff' ? null : hexToFloat(hexPressure);
    const co2 = hexCO2 === 'ffffffff' ? null : hexToFloat(hexCO2);

    return ({ pH, batteryVoltage, pressure, temperature, batteryCurrent, co2, output0, output1, input0, input1 })

};

export const extractTemperature = (hexString) => {
    const hexTemp = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2)

    if (hexTemp === 'ffffffff') {
        return undefined
    } else {
        const temperature = hexToFloat(hexTemp);
        return temperature
    }

}

export const extractVoltage = (hexString) => {
    const hexVal = hexString?.slice((lengthSize + outputsSize + inputsSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize) * 2)
    if (hexVal === 'ffffffff') {
        return undefined
    } else {
        const batteryVoltage = hexToFloat(hexVal);
        return batteryVoltage
    }

}

export const extractPh = (hexString) => {
    const hexVal = hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2);
    if (hexVal === 'ffffffff') {
        return undefined
    } else {
        const pH = hexToFloat(hexVal);
        return pH
    }

}

export const extractPressure = (hexString) => {
    const hexPressure = (hexString?.slice((lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize) * 2, (lengthSize + outputsSize + inputsSize + batteryVoltageSize + batteryCurrentSize + temperatureSize + phSize + pressureSize) * 2))

    if (hexPressure === 'ffffffff') {
        return undefined
    } else {
        const pressure = hexToFloat(hexPressure);
        return pressure
    }

}

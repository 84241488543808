import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { RiDeleteBin3Line, RiEdit2Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {
    deleteDeviceById,
    getDeviceListByLocationId,
} from '../../actions/deviceAction';
import FilterComponent from './FilterComponent';
import UpdateDeviceModal from '../Modal/updateDevice';
import { clearMessage } from '../../actions/message';
const Table = (props) => {
    const dispatch = useDispatch();
    const [ showUpdateDevice, setShowUpdateDevice ] = React.useState(false);
    const [ device, setDevice ] = React.useState();
    const columns = [

        {
            name: 'EUI',
            selector: (row) => row.eui,
            sortable: true,
            grow: 1.3,
        }, {
            name: 'Model',
            selector: (row) => row.model,
            sortable: true,
            grow: 1.3,
        },

        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            grow: 1.3,
        },

        {
            name: 'Latest Activity',
            selector: (row) => {
                const updatedAtDate = new Date(row.updatedAt)
                const readableUpdatedAt = updatedAtDate.toLocaleString();
                return readableUpdatedAt
            },
            sortable: true,
            grow: 2,

        },
        ...(!props.listLocation && props.isAdmin
            ? [ {
                name: 'Action',
                selector: (row) => row.action,
                cell: function displayItem(row) {
                    return (
                        <>
                            {showUpdateDevice && (
                                <UpdateDeviceModal
                                    show={showUpdateDevice}
                                    setShow={setShowUpdateDevice}
                                    row={row}
                                    device={device}
                                />
                            )}
                            <RiEdit2Line
                                className="mr-2"
                                onClick={() => {
                                    setShowUpdateDevice(true);
                                    setDevice(row);
                                    dispatch(clearMessage())
                                }}
                            />
                            <RiDeleteBin3Line
                                className="cursor-pointer"
                                onClick={() => {
                                    Swal.fire({
                                        title: 'Do you really want to delete this device?',
                                        showDenyButton: true,
                                        confirmButtonColor: 'red',
                                        confirmButtonText: 'Yes',
                                        denyButtonText: 'No',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            dispatch(deleteDeviceById(row.id))
                                                .then(() => {
                                                    Swal.fire('Device  deleted', '', 'success');
                                                    dispatch(getDeviceListByLocationId(props.locationId));
                                                })
                                                .catch(() => {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'Oops...',
                                                        text: 'Something went wrong!',
                                                    });
                                                });
                                        } else if (result.isDenied) {
                                            Swal.fire('Device not deleted', '', 'info');
                                        }
                                    });
                                }}
                                size={15}
                            />
                        </>
                    );
                },
            } ]
            : []),
    ];

    const [ filterText, setFilterText ] = React.useState('');
    const [ resetPaginationToggle, setResetPaginationToggle ] =
    React.useState(false);
    const filteredItems =
    props &&
    props.data &&
    props.data.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1,
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [ filterText, resetPaginationToggle ]);

    return (
        <DataTable
            customStyles={{
                rows: {
                    style: {
                        cursor: 'pointer',
                    },
                },
            }}
            highlightOnHover
            columns={columns}
            data={filteredItems}
            defaultSortField="eui"
            onRowClicked={(row) => {
                window.location.href = (`/location/${ props.locationId }/devices/${ row.id }`);
            }}
            striped
            pagination
            subHeader={!props.listLocation && true}
            subHeaderComponent={subHeaderComponent}
            paginationPerPage={props.listLocation?3:10}
            paginationRowsPerPageOptions={[ 3, 10, 15, 20 ]}
            noDataComponent={'No Available Devices'}

        />
    );
};

export default Table;

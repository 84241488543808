import React from 'react';
import styled from 'styled-components';

const Input = styled.input.attrs((props) => ({
    type: 'text',
    size: props.small ? 5 : undefined,
}))`
  height: 80%;
  width: 15%;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  position: absolute;
  left: 2%;
`;

const FilterComponent = ({ filterText, onFilter }) => (
    <>
        <Input
            id="search"
            type="text"
            placeholder="Search.."
            value={filterText}
            onChange={onFilter}
        />
    </>
);

export default FilterComponent;

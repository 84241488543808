import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsById } from '../actions/locationAction';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { deleteLocationById } from '../actions/locationAction';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
export default function LocationInfo({ id,view }) {
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.location);
    const [ isAdmin, setIsAdmin ] = useState(Boolean);
    const history = useHistory();
    const REACT_APP_SERVER_API_URL=process.env.REACT_APP_SERVER_API_URL
    useEffect(() => {
        dispatch(getLocationsById(id))
    }, [ dispatch, id ]);

    useEffect(() => {
    // eslint-disable-next-line
        const storedUserId = JSON.parse(localStorage.getItem('user'))?.id;
        if (location && storedUserId) {
            const user = location.entities[ 0 ]?.users.find((u) => u.id === storedUserId);
            const userRole = user?.userEntity?.role;
            setIsAdmin(userRole==='admin');
        }
    }, [ location ]);

    return (
        <>
            {' '}
            {location ? (
                <Card
                    elevation={2}
                    style={{ backgroundColor: '#F4F6F8', height: '100%' }}
                >
                    <h5 className="card-header d-flex justify-content-between align-items-center">
            Location Informations
                    </h5>

                    <CardContent>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="d-flex">
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={`${ REACT_APP_SERVER_API_URL }${ location.image }`}
                                        alt="Location Image"
                                    />
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                    Location name <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark">
                                        {location.name}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                    Location address <span className="text-danger" >*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark text-capitalize">
                                        {location.city} {location.state} , {location.zipCode}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                    Location description <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark">
                                        {location.description}
                                    </label>
                                </Grid>
                                {view!=='Add_Device' &&      (<Grid item xs={12} className="d-flex">
                                    { isAdmin && <>
                                        <Button className="mr-2" color="success" variant="outlined" startIcon={<ModeEditIcon /> } onClick={() => {
                                            window.location.href=`/updateLocation/${ location.id }`
                                        }}>
                  Edit
                                        </Button>
                                        {/*    <UpdateLocationModal handleClose={handleClose} show={show} /> */}
                                        <Button color="error"  variant="outlined" startIcon={<DeleteIcon />}      onClick={() => {
                                            Swal.fire({
                                                title:
                                      'Do you really want to delete this location?',
                                                showDenyButton: true,
                                                confirmButtonColor: 'red',
                                                confirmButtonText: 'Yes',
                                                denyButtonText: 'No',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    dispatch(deleteLocationById(id)).then(
                                                        (response) => {
                                                            if (response.success) {
                                                                history.push('/')
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title: 'Oops...',
                                                                    text: 'Something went wrong!',
                                                                });
                                                            }
                                                        },
                                                        () => {
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Oops...',
                                                                text: 'Something went wrong!',
                                                            });
                                                        },
                                                    );
                                                } else if (result.isDenied) {
                                                    Swal.fire(
                                                        'Location not deleted',
                                                        '',
                                                        'info',
                                                    );
                                                }
                                            });
                                        }}>
                  Delete
                                        </Button></>}
                                </Grid>)}
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            ) : (
                <Card elevation={2} style={{ backgroundColor: '#F4F6F8' }}>
                    <h5 className="card-header d-flex justify-content-between align-items-center">
            Location Informations
                    </h5>

                    <CardContent>
                        <Box
                            className=" d-flex align-items-center justify-content-center "
                            sx={{ flexGrow: 1 }}
                        >
                            <div className="spinner-border text-success "></div>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </>
    );
}

// eslint-disable-next-line react-hooks/exhaustive-deps
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { getGatewayById } from '../actions/gatewayAction';
import { getLocationsById } from '../actions/locationAction';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import GatewayImage from '../assets/Gateway.png'
import { useSocket } from '../SocketContext';
import { getEntityById } from '../actions/entityAction';
export default function Device({ recentEntity, setRecentEntity }) {
    const socket = useSocket()
    const { locationId } = useParams();
    const { gatewayId } = useParams();
    const [ loading, setLoading ] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        socket.on('Get_Confirm_Config', () => {
            console.log('Get_Confirm_Config')
        });
        socket.on('Get_TTN_Connect_Status', (data) => {
            console.log('Get_TTN_Connect_Status', JSON.stringify(data));
            dispatch(getGatewayById(gatewayId)).then((response) => {
                if (response.success) {
                    setLoading(false);
                }
            }).catch(() => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        })
        socket.on('gateway_status_changed', () => {
            console.log('gateway_status_changed');
            dispatch(getGatewayById(gatewayId))
        })
        return () => {
            //socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ socket ])

    useEffect(() => {
        setLoading(true);
        dispatch(getLocationsById(locationId));
        dispatch(getGatewayById(gatewayId)).then((response) => {
            if (response.success) {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }, [ dispatch, locationId, gatewayId ]);

    const { location } = useSelector((state) => state.location);
    const { gateway } = useSelector((state) => state.gateway);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location) {

                console.log('recent entity is empty')
                console.log('entity', location?.entities[ 0 ].id)

                // Check if the current pathname contains 'entity/52'

                dispatch(getEntityById(location?.entities[ 0 ].id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])
    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }));
    if (loading) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    return (
        <>
            <Card
                elevation={2}
                style={{ backgroundColor: '#F4F6F8', marginTop: '20px' }}
            >
                {location && gateway && (
                    <h5 className="card-header justify-content-between align-items-center">
                        Gateway informations
                        <Breadcrumbs separator="/" aria-label="breadcrumb">
                            {/*  <Link underline="hover" color="inherit" href="/">
                                Your Entities
                            </Link> */}

                            {location && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href={`/entity/${ location.entities[ 0 ].id }`}
                                >
                                    {location.entities[ 0 ].name}
                                </Link>
                            )}
                            {location && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href={`/location/${ location.id }`}
                                >
                                    {location.name}
                                </Link>
                            )}
                            {gateway && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href={`/location/${ location.id }/device/${ gateway.id }`}
                                    sx={{ textDecoration: 'underline' }}
                                >
                                    {gateway.name}
                                </Link>
                            )}
                        </Breadcrumbs>
                    </h5>
                )}

                <Divider />
                <CardContent>
                    <Box sx={{ width: 1, height: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Item
                                    sx={{
                                        width: 1,
                                        height: '50%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <img
                                        src={GatewayImage}
                                        alt="Gateway Img"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {gateway && (
                                    <Box gridColumn="span 4">
                                        <h5 className="card-header d-flex justify-content-between align-items-center">
                                            Gateway details
                                        </h5>

                                        <Divider />
                                        <Item style={{ height: '100%' }}>
                                            <Table striped bordered>
                                                <tbody>
                                                    <tr>
                                                        <td>EUI</td>
                                                        <td>{gateway.eui}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{gateway.name}</td>
                                                    </tr>
                                                    {location && <tr>
                                                        <td>Location</td>
                                                        <td>{location.name}</td>
                                                    </tr>}

                                                    <tr>
                                                        <td>Mac Address</td>
                                                        <td>{gateway.macAddress}</td>
                                                    </tr>
                                                    {gateway && <tr>
                                                        <td>Status</td>
                                                        {
                                                            gateway.status === true ? (<td>Connected</td>) : (<td>Disconnected</td>)
                                                        }

                                                    </tr>}
                                                    <tr>
                                                        <td>Description</td>
                                                        <td>{gateway.description}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Latest Activity</td>
                                                        <td>{(() => {
                                                            const updatedAtDate = new Date(gateway.updatedAt);
                                                            const readableUpdatedAt = updatedAtDate.toLocaleString();
                                                            return readableUpdatedAt;
                                                        })()}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Item>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
            </Card>
        </>
    );
}

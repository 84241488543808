import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { addEntity } from '../../actions/entityAction';
import axios from 'axios';
import Swal from 'sweetalert2';
import { clearMessage } from '../../actions/message';
const schema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string().required(),
    entityImage: yup.mixed().required(),
});

const AddEntityModal = ({ openAddEntity, setOpenAddEntity, navbar, setUserRecentEntity }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [ show, setShow ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState(null);
    const REACT_APP_SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL
    const handleClose = () => {
        setShow(false);
        setPreviewImage(null); // Clear the preview image when closing the modal
    };
    const handleShow = () => {
        setShow(true);
        dispatch(clearMessage());
    };
    React.useEffect(() => {
        if (openAddEntity) {
            console.log('openAddEntity', openAddEntity)
            if (openAddEntity === true) {
                handleShow()
                setOpenAddEntity(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ openAddEntity ])

    return (
        <>
            {!navbar && <Button variant="success" onClick={handleShow}>
                ADD ENTITY
            </Button>}

            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Add Entity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={async(values) => {
                            // Display image preview
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                setPreviewImage(reader.result);
                            };
                            if (values.entityImage) {
                                reader.readAsDataURL(values.entityImage);
                            } else {
                                setPreviewImage(null);
                            }

                            Swal.fire({
                                title: 'Do you really want to add entity?',
                                showDenyButton: true,
                                confirmButtonColor: 'red',
                                confirmButtonText: 'Yes',
                                denyButtonText: 'No',
                            }).then(async(result) => {
                                if (result.isConfirmed) {
                                    // Upload the image
                                    const formData = new FormData();
                                    formData.append('image', values.entityImage);

                                    try {
                                        const uploadResponse = await axios.post(
                                            `${ REACT_APP_SERVER_API_URL }/upload`,
                                            formData,
                                        );

                                        if (uploadResponse.data && uploadResponse.data.filename) {
                                            // Image upload successful
                                            const filename = `/uploads/${ uploadResponse.data.filename }`;

                                            // Dispatch addLocation action
                                            dispatch(
                                                addEntity(
                                                    values.name,
                                                    values.address,
                                                    filename, user.id, user.id,
                                                ),
                                            ).then((response) => {
                                                console.log('response', response)
                                                if (response && response.success) {
                                                    if (openAddEntity) {
                                                        setOpenAddEntity(false)
                                                    }
                                                    console.log('result', JSON.stringify(result))
                                                    setUserRecentEntity(response.result)
                                                    console.log('====> go to entity', JSON.stringify(response.result))
                                                    // window.location.href= `/entity/${ response.result.id }`
                                                    handleClose();
                                                } else {
                                                    handleClose();
                                                    Swal.fire({
                                                        title: 'Cannot add entity.',
                                                        icon: 'error',

                                                    });
                                                }

                                            });

                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: 'Image upload failed!',
                                            });
                                        }
                                    } catch (error) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Something went wrong with image upload!',
                                        });
                                    }
                                } else if (result.isDenied) {
                                    Swal.fire('Entity not added', '', 'info');
                                }
                            });
                        }}
                        initialValues={{
                            name: '',
                            address: '',
                            locationImage: null,
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            setFieldValue,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit} className='mb-2'>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormikImage">
                                        <Form.Label>Entity Image</Form.Label>
                                        <div className="custom-file">
                                            <Form.Control
                                                type="file"
                                                name="entityImage"
                                                onChange={(event) => {
                                                    setFieldValue('entityImage', event.currentTarget.files[ 0 ]);

                                                    // Display image preview
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => {
                                                        setPreviewImage(reader.result);
                                                    };
                                                    if (event.currentTarget.files[ 0 ]) {
                                                        reader.readAsDataURL(event.currentTarget.files[ 0 ]);
                                                    } else {
                                                        setPreviewImage(null);
                                                    }
                                                }}
                                                isInvalid={!!errors.entityImage}
                                                title="No file chosen"
                                                className="custom-file-input"
                                            />
                                            <label className="custom-file-label">
                                                {previewImage ? `File chosen: ${ values.entityImage.name }` : 'Choose File'}
                                            </label>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.entityImage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {previewImage && (
                                        <Col xs={3} className="mt-4">
                                            <img
                                                src={previewImage}
                                                alt="Preview"
                                                className="img-thumbnail"
                                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                            />
                                        </Col>
                                    )}
                                </Row>

                                <Row className='mb-3'> <Form.Group as={Col} controlId="validationFormik01">
                                    <Form.Label>Entity name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Entity name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group></Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik03">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Address"
                                            name="address"
                                            value={values.address}
                                            onChange={handleChange}
                                            isInvalid={!!errors.address}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Modal.Footer>
                                    <Button variant="light" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button type="submit" className="btn btn-success">
                                        Submit form
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEntityModal;

import React, { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { extractVoltage } from '../../Utils/DecodeUplink';
import DeviceService from '../../services/device.service';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const VoltageChart = ({ selectedPeriod, deviceEui, socket }) => {
    const currentPathname = window.location.pathname;
    const chartView = currentPathname.startsWith('/chart');
    const [ lastDayVoltageChartData, setLastDayVoltageChartData ] = useState([]);
    const [ lastWeekVoltageChartData, setLastWeekVoltageChartData ] = useState([]);
    const [ lastMonthVoltageChartData, setLastMonthVoltageChartData ] = useState([]);
    const [ chartDataAvailable, setChartDataAvailable ] = useState(false);
    const [ chartOptions, setChartOptions ] = useState()
    const [ dailyData, setDailyData ] = useState([]);
    const [ hourlyData, setHourlyData ] = useState([]);
    const isZoomingRef = useRef(false);
    const isChartDataNull = () => {
        if (selectedPeriod === 'lastDay') {
            return lastDayVoltageChartData.every(data => (data.averageVoltage === undefined || data.averageVoltage === null));
        } else if (selectedPeriod === 'lastWeek') {
            console.log('isWeeklyDataNull', lastWeekVoltageChartData.every(data => (data.dailyAverageVoltage === undefined || data.dailyAverageVoltage === null)))
            return lastWeekVoltageChartData.every(data => (data.dailyAverageVoltage === undefined || data.dailyAverageVoltage === null));
        } else if (selectedPeriod === 'lastMonth') {
            return lastMonthVoltageChartData.every(data => (data.averageVoltage === undefined || data.averageVoltage === null));
        }
        return false;
    };
    useEffect(() => {
        if (selectedPeriod === 'lastDay' && lastDayVoltageChartData.length > 0 || selectedPeriod === 'lastWeek' && lastWeekVoltageChartData.length > 0 || selectedPeriod === 'lastMonth' && lastMonthVoltageChartData.length > 0) {
            setChartDataAvailable(isChartDataNull());
        }
    }, [ selectedPeriod, lastDayVoltageChartData, lastWeekVoltageChartData, lastMonthVoltageChartData ]);
    const fetchLastDayData = () => {
        if (deviceEui) {
            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const newChartData = [];
                const groupedData = response.data.result.reduce((acc, aResult) => {
                    const updatedAt = new Date(aResult.updatedAt);
                    const date = updatedAt.toLocaleDateString();
                    const hour = updatedAt.getHours();
                    const minute = updatedAt.getMinutes();
                    const quarterHour = Math.floor(minute / 15) * 15;
                    const voltage = extractVoltage(aResult.data);
                    const key = `${ date }-${ hour }-${ quarterHour }`;
                    if (!acc[ key ]) {
                        acc[ key ] = {
                            date: date,
                            hour: hour,
                            quarterHour: quarterHour,
                            voltages: [],
                        };
                    }
                    if (voltage !== undefined) {
                        acc[ key ].voltages.push(voltage);
                    }
                    return acc;
                }, {});
                const currentDate = new Date();
                for (let i = 0; i < 24; i++) {
                    const date = new Date(currentDate);
                    date.setHours(date.getHours() - i);
                    const dateString = date.toLocaleDateString();
                    const hour = date.getHours();
                    const hourlyAverageVoltage = [];
                    let hourVoltages = [];
                    for (let j = 0; j < 4; j++) {
                        const quarterHour = j * 15;
                        const key = `${ dateString }-${ hour }-${ quarterHour }`;
                        const existingData = groupedData[ key ];
                        if (existingData) {
                            const sum = existingData.voltages.reduce((total, volt) => total + volt, 0);
                            const average = sum / existingData.voltages.length;
                            hourlyAverageVoltage.push({
                                time: `${ hour }:${ quarterHour < 10 ? '0' : '' }${ quarterHour }`,
                                average: average !== 0 ? average.toFixed(2) : null,
                            });
                            hourVoltages = hourVoltages.concat(existingData.voltages);
                        } else {
                            hourlyAverageVoltage.push({
                                time: `${ hour }:${ quarterHour < 10 ? '0' : '' }${ quarterHour }`,
                                average: null,
                            });
                        }
                    }
                    const hourlySum = hourVoltages.reduce((total, volt) => total + volt, 0);
                    const hourlyAverage = hourVoltages.length ? (hourlySum / hourVoltages.length).toFixed(2) : null;
                    newChartData.push({
                        date: dateString,
                        hour: hour,
                        voltages: hourVoltages,
                        averageVoltage: hourlyAverage,
                        hourlyAverageVoltage: hourlyAverageVoltage,
                    });
                }
                newChartData.sort((a, b) => {
                    const dateA = new Date(
                        parseInt(a.date.split('/')[ 2 ]),
                        parseInt(a.date.split('/')[ 1 ]) - 1,
                        parseInt(a.date.split('/')[ 0 ]),
                        a.hour,
                    );
                    const dateB = new Date(
                        parseInt(b.date.split('/')[ 2 ]),
                        parseInt(b.date.split('/')[ 1 ]) - 1,
                        parseInt(b.date.split('/')[ 0 ]),
                        b.hour,
                    );
                    return dateA - dateB;
                });
                console.log('LastDayVoltageChartData', newChartData);
                setLastDayVoltageChartData(newChartData);
            });
        }
    };
    const fetchLastWeekData = () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7); // Adjusted to last 7 days
        // Generate an array of dates for the last 8 days (including current date)
        const lastWeekDates = [];
        for (let i = 0; i < 8; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            lastWeekDates.push(date.toLocaleDateString());
        }
        if (deviceEui) {
            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const hourlyVoltageData = {};
                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt);
                    const dateString = date.toLocaleDateString();
                    const hour = date.getHours();
                    const voltage = extractVoltage(result.data);
                    if (voltage !== undefined) {
                        if (!hourlyVoltageData[ dateString ]) {
                            hourlyVoltageData[ dateString ] = {};
                        }
                        if (!hourlyVoltageData[ dateString ][ hour ]) {
                            hourlyVoltageData[ dateString ][ hour ] = {
                                voltages: [ voltage ],
                            };
                        } else {
                            hourlyVoltageData[ dateString ][ hour ].voltages.push(voltage);
                        }
                    }
                });
                // Flatten the data for chart
                const lastWeekFlattenedData = [];
                // To store daily average voltages for weekly average calculation
                const dailyAverageVoltages = {};
                // Loop through each date in lastWeekDates
                lastWeekDates.forEach((date) => {
                    const hoursData = hourlyVoltageData[ date ] || {};
                    const averageVoltageList = []; // Reset averageVoltageList for each date
                    let dailyTotalVoltage = 0;
                    let dailyVoltageCount = 0;
                    for (let hour = 0; hour < 24; hour++) {
                        const voltages = hoursData[ hour ] ? hoursData[ hour ].voltages : [];
                        const averageVoltage = voltages.length > 0 ? (voltages.reduce((acc, val) => acc + val, 0) / voltages.length).toFixed(2) : null;
                        if (averageVoltage !== null) {
                            dailyTotalVoltage += parseFloat(averageVoltage);
                            dailyVoltageCount++;
                        }
                        averageVoltageList.push({
                            hour: hour,
                            averageVoltage: averageVoltage,
                        });
                    }
                    const dailyAverageVoltage = dailyVoltageCount > 0 ? (dailyTotalVoltage / dailyVoltageCount).toFixed(2) : null;
                    dailyAverageVoltages[ date ] = dailyAverageVoltage;
                    lastWeekFlattenedData.push({
                        date: date,
                        averageVoltage: averageVoltageList,
                        dailyAverageVoltage: dailyAverageVoltage,
                    });
                });
                // Calculate weekly average voltage
                let weeklyTotalVoltage = 0;
                let weeklyVoltageCount = 0;
                Object.values(dailyAverageVoltages).forEach((dailyAverage) => {
                    if (dailyAverage !== null) {
                        weeklyTotalVoltage += parseFloat(dailyAverage);
                        weeklyVoltageCount++;
                    }
                });
                const weeklyAverageVoltage = weeklyVoltageCount > 0 ? (weeklyTotalVoltage / weeklyVoltageCount).toFixed(2) : null;
                // Add weekly average voltage to each day's data
                lastWeekFlattenedData.forEach((data) => {
                    data.weeklyAverageVoltage = weeklyAverageVoltage;
                });
                console.log('LastWeekVoltageChartData', lastWeekFlattenedData); // Output or use lastWeekFlattenedData as needed
                setLastWeekVoltageChartData(lastWeekFlattenedData);
            });
        }
    }
    const fetchLastMonthData = () => {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1); // Adjusted to last month
        // Generate an array of dates for the last 31 days (including the current date)
        const lastMonthDates = [];
        for (let i = 0; i < 31; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            lastMonthDates.push(date.toLocaleDateString());
        }
        if (deviceEui) {
            DeviceService.getDeviceLastMessages(deviceEui, selectedPeriod).then((response) => {
                const dailyVoltageData = {};
                // Process the response data
                response.data.result.forEach((result) => {
                    const date = new Date(result.updatedAt).toLocaleDateString();
                    const voltage = extractVoltage(result.data);
                    if (voltage !== undefined) {
                        if (!dailyVoltageData[ date ]) {
                            dailyVoltageData[ date ] = {
                                voltages: [ voltage ],
                            };
                        } else {
                            dailyVoltageData[ date ].voltages.push(voltage);
                        }
                    }
                });
                // Calculate average voltage for each date, or set to null if not available
                const voltageChartData = lastMonthDates.map((date) => {
                    const voltages = dailyVoltageData[ date ] ? dailyVoltageData[ date ].voltages : [];
                    const averageVoltage = voltages.length > 0 ? voltages.reduce((acc, val) => acc + val, 0) / voltages.length : null;
                    return {
                        date: date,
                        voltages: voltages,
                        averageVoltage: averageVoltage === 0 ? averageVoltage?.toFixed(2) : averageVoltage ? averageVoltage?.toFixed(2) : null,
                    };
                });
                // Sort the voltage data by date
                voltageChartData.sort((a, b) => new Date(a.date) - new Date(b.date));
                console.log('LastMonthVoltageChartData', voltageChartData)
                setLastMonthVoltageChartData(voltageChartData);
            });
        }
    }
    const exportToExcel = (data) => {
        const columnTitles = Object.keys(data[ 0 ]);
        // Create a worksheet with column titles and their respective values
        const worksheet = XLSX.utils.json_to_sheet(data.map(item => {
            const rowData = {};
            if (columnTitles && columnTitles.length > 0) {
                columnTitles.forEach(title => {
                    rowData[ title ] = item[ title ];
                });
            }
            return rowData;
        }));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'VoltageChartData');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([ excelBuffer ], { type: 'application/octet-stream' });
        saveAs(blob, 'VoltageChartData.xlsx');
    };
    useEffect(() => {
        if (selectedPeriod) {
            if (selectedPeriod === 'lastDay') {
                fetchLastDayData()
            } else if (selectedPeriod === 'lastWeek') {
                fetchLastWeekData()
            } else if (selectedPeriod === 'lastMonth') {
                fetchLastMonthData()
            }
        }
    }, [ selectedPeriod ]);
    useEffect(() => {
        socket && socket.on('update_device_data', () => {
            console.log('update_device_data voltageChart')
            if (selectedPeriod && deviceEui) {
                if (selectedPeriod === 'lastDay') {
                    if (!isZoomingRef.current) {
                        fetchLastDayData()
                    }
                } else if (selectedPeriod === 'lastWeek') {
                    if (!isZoomingRef.current) {
                        fetchLastWeekData()
                    }
                } else if (selectedPeriod === 'lastMonth') {
                    fetchLastMonthData()
                }
            }
        });
    }, [ socket ]);
    const singlePointData = lastDayVoltageChartData?.map(({ averageVoltage }) => averageVoltage).filter(val => val !== null);
    const isSinglePoint = singlePointData.length === 1;
    const options = {
        series: [
            {
                name: 'Average Voltage',
                data: selectedPeriod === 'lastDay' ? lastDayVoltageChartData?.map(({ averageVoltage }) => averageVoltage) : selectedPeriod === 'lastWeek' ? lastWeekVoltageChartData?.map((val) => {
                    if (val.averageVoltage.length > 0) {
                        return val.averageVoltage[ 0 ]
                    } else return null
                }) : selectedPeriod === 'lastMonth' ? lastMonthVoltageChartData?.map((val) => {
                    if (val.averageVoltage !== undefined) {
                        return val.averageVoltage
                    } else return null
                }) : null,
                color: 'rgba(255,165,0,1)',
            },
        ],
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        title: chartView && {
            text: 'Average Voltage (V)',  // Your chart title
            align: 'left',           // Aligns the title to the left
            margin: 10,              // Optional: Margin from the top
            offsetX: 0,              // Optional: Horizontal offset
            style: {
                fontSize: '12px',      // Optional: Font size

            },
        },

        chart: {
            height: 350,
            width: '100%',
            type: isSinglePoint ? 'scatter' : 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [
                        {
                            icon: `
                   <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>`,
                            title: 'Export to Excel', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                const data = selectedPeriod === 'lastDay' ? lastDayVoltageChartData?.map((val) => {
                                    return { date: `${ val.date } ${ val.hour }h`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                }) : selectedPeriod === 'lastMonth' ? (lastMonthVoltageChartData?.map((val) => {
                                    return { date: `${ val.date }`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                })) : selectedPeriod === 'lastWeek' ? (lastWeekVoltageChartData?.map((val) => {
                                    return { date: `${ val.date }`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                })) : null
                                exportToExcel(data)
                            },
                        },
                    ],
                },
            },
        },
        colors: [ '#77B6EA' ],
        dataLabels: {
            enabled: false,
            formatter: function(val) {
                if (val) {
                    return val?.toFixed(2);
                } else if (val === 0) {
                    return val?.toFixed(2)
                }
            },
        },
        xaxis: {
            type: 'category',
            categories:
                selectedPeriod === 'lastDay' ? lastDayVoltageChartData?.map((res) => {
                    const formattedHour = res.hour < 10 ? `0${ res.hour }` : res.hour;
                    return `${ res.date } ${ formattedHour }h`;
                }) : selectedPeriod === 'lastMonth' ? lastMonthVoltageChartData?.map((res) => {
                    return `${ res.date }`;
                }) : null,
            tickPlacement: 'on',
            tickAmount:  chartView ? 8 : 24,
            position: 'bottom',
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 120,

            },
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            enabled: true,
        },
        tooltip: {
            enabled: true,
            followCursor: false,
            hideEmptySeries: false,
            onDatasetHover: {
                highlightDataSeries: true,
            },
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return Number(parseFloat(val.toFixed(2))) + 'V';
                    } else if (val === 0) {
                        return 0.01 + 'V';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
        yaxis: {
            min: 0.00,
            max: 15.00,
            decimalsInFloat: true,
            labels: {
                formatter: (val) => {
                    if (val) {
                        return Number(parseFloat(val.toFixed(2))) + 'V';
                    }
                },
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 14,
                offsetY: 0,
                rotate: 0,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                width: 6,
                offsetX: 0,
                offsetY: 0,
            },
            title: !chartView && {
                text: 'Average Voltage (V)',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 25,
                right: 0,
            },
        },
    };
    let ZOOMDATA = []
    const [ initialDailyData, setInitialDailyData ] = useState([]);
    const [ initialHourlyData, setInitialHourlyData ] = useState([]);
    const dailyChartOptions = {
        noData: {
            text: 'Loading ...',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined,
            },
        },
        chart: {
            id: 'daily-chart',
            type: isSinglePoint ? 'scatter' : 'line',
            width: '100%',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: '<svg></svg>',
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [
                        {
                            icon: `
                    <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a1 1 0 0 1 1 1v9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V4a1 1 0 0 1 1-1Z" fill="#000000"/><path d="M6 17a1 1 0 1 0-2 0v.6C4 19.482 5.518 21 7.4 21h9.2c1.882 0 3.4-1.518 3.4-3.4V17a1 1 0 1 0-2 0v.6c0 .778-.622 1.4-1.4 1.4H7.4c-.778 0-1.4-.622-1.4-1.4V17Z" fill="#000000"/></svg>`,
                            title: 'Export to Excel', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                const data = selectedPeriod === 'lastDay' ? lastDayVoltageChartData?.map((val) => {
                                    return { date: `${ val.date } ${ val.hour }h`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                }) : selectedPeriod === 'lastMonth' ? (lastMonthVoltageChartData?.map((val) => {
                                    return { date: `${ val.date }`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                })) : selectedPeriod === 'lastWeek' ? (lastWeekVoltageChartData?.map((val) => {
                                    return { date: `${ val.date }`, voltages: JSON.stringify(val.voltages), averageVoltage: val.averageVoltage }
                                })) : null
                                exportToExcel(data)
                            },
                        },
                        {
                            icon: '<svg class="svg_icon" style="width: 19px; height: 19px" viewBox="0 0 24 24" fill = "grey" xmlns="http://www.w3.org/2000/svg" id="fi_3952273"><path d="m10.035 18.069a7.981 7.981 0 0 0 3.938-1.035l3.332 3.332a2.164 2.164 0 0 0 3.061-3.061l-3.332-3.332a8.032 8.032 0 0 0 -12.68-9.619 8.034 8.034 0 0 0 5.681 13.715zm-4.267-12.301a6.033 6.033 0 1 1 -1.768 4.267 5.989 5.989 0 0 1 1.768-4.267zm.267 4.267a1 1 0 0 1 1-1h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 -1-1z"></path></svg>',
                            title: 'Zoom Out', // Tooltip for the button
                            index: -1, // Position of the button in the toolbar
                            class: 'custom-icon-class', // CSS class for additional styling
                            click: function() {
                                if (ZOOMDATA.length > 0) {
                                    ZOOMDATA = [];
                                }
                                if (initialDailyData && selectedPeriod === 'lastWeek') {
                                    setInitialDailyData(lastWeekVoltageChartData.map(day => ({
                                        x: day.date,
                                        y: day.dailyAverageVoltage ? parseFloat(day.dailyAverageVoltage) : null,
                                    })))
                                    setDailyData(initialDailyData);
                                }
                                if (initialHourlyData && selectedPeriod === 'lastDay') {
                                    if (hourlyData) {
                                        setHourlyData(ZOOMDATA);
                                    }
                                    setHourlyData(initialHourlyData)
                                }
                                isZoomingRef.current = false
                            },
                        },
                    ],
                },
            },
            events: {
                zoomed: function(chartContext, { xaxis }) {
                    isZoomingRef.current = true;
                    if (lastWeekVoltageChartData.length > 0) {
                        for (let i = xaxis.min - 1; i < xaxis.max + 1; i++) {
                            if (lastWeekVoltageChartData && lastWeekVoltageChartData[ i ]) {
                                if (lastWeekVoltageChartData[ i ] && lastWeekVoltageChartData[ i ].averageVoltage.length > 0) {
                                    lastWeekVoltageChartData[ i ].averageVoltage.map((e) => {
                                        const dateParts = lastWeekVoltageChartData[ i ]?.date.split('/');
                                        const day = parseInt(dateParts[ 0 ], 10);
                                        const month = parseInt(dateParts[ 1 ], 10) - 1;
                                        const year = parseInt(dateParts[ 2 ], 10);
                                        const hour = parseInt(e.hour, 10);
                                        const transformedDate = new Date(year, month, day, hour);
                                        const days = transformedDate.getDate().toString().padStart(2, '0');
                                        const months = (transformedDate.getMonth() + 1).toString().padStart(2, '0');
                                        const years = transformedDate.getFullYear();
                                        const hours = transformedDate.getHours().toString().padStart(2, '0');
                                        const formattedDate = `${ days }/${ months }/${ years } ${ hours }h`;
                                        ZOOMDATA.push({
                                            x: formattedDate,
                                            y: e.averageVoltage,
                                        });
                                    });
                                }
                            }
                        }
                        if (ZOOMDATA.length > 0) {
                            setDailyData(ZOOMDATA);
                        }
                    } else if (lastDayVoltageChartData.length > 0) {
                        for (let i = xaxis.min - 1; i < xaxis.max; i++) {
                            if (lastDayVoltageChartData && lastDayVoltageChartData[ i ]) {
                                lastDayVoltageChartData[ i ].hourlyAverageVoltage.map((e) => {
                                    ZOOMDATA.push({
                                        x: lastDayVoltageChartData[ i ].date + ' ' + e.time,
                                        y: e.average,
                                    });
                                });
                            }
                        }
                        if (ZOOMDATA.length > 0) {
                            setHourlyData(ZOOMDATA);
                        }
                    }
                },
                xAxisLabelClick: function(event, chartContext) {
                    // Extract the clicked label
                    const clickedLabel = event.target.textContent || event.target.innerText;
                    // Validate and log the clickedLabel
                    if (!clickedLabel) {
                        console.error('No label clicked');
                        return;
                    }
                    // Extract datePart and timePart
                    const [ datePart, timePart ] = clickedLabel.split(' ');
                    if (!datePart) {
                        console.error('Invalid label format');
                        return;
                    }
                    // Initialize variables
                    let zoomData = [];
                    // Handle data from lastDayVoltageChartData if available
                    if (lastDayVoltageChartData.length > 0) {
                        const hour = timePart ? parseInt(timePart.replace('h', ''), 10) : null;
                        if (isNaN(hour)) {
                            console.error('Invalid hour format');
                            return;
                        }
                        // Find data for the specific date and hour
                        const selectedData = lastDayVoltageChartData.find(entry => entry.date === datePart && entry.hour === hour);
                        if (selectedData && selectedData.hourlyAverageVoltage) {
                            // Filter for quarter-hourly data
                            zoomData = selectedData.hourlyAverageVoltage.map(e => ({
                                x: `${ selectedData.date } ${ e.time }`,
                                y: e.average,
                            }));
                        }
                    }
                    // Handle data from lastWeekVoltageChartData if available and no data found in lastDayVoltageChartData
                    if (zoomData.length === 0 && lastWeekVoltageChartData.length > 0) {
                        // Get all hourly data for the selected date from lastWeekVoltageChartData
                        lastWeekVoltageChartData.forEach(dayData => {
                            if (dayData.date === datePart) {
                                dayData.averageVoltage.forEach(e => {
                                    const dateParts = dayData.date.split('/');
                                    const day = parseInt(dateParts[ 0 ], 10);
                                    const month = parseInt(dateParts[ 1 ], 10) - 1;
                                    const year = parseInt(dateParts[ 2 ], 10);
                                    const hour = parseInt(e.hour, 10);
                                    const formattedDate = `${ day.toString().padStart(2, '0') }/${ (month + 1).toString().padStart(2, '0') }/${ year } ${ hour.toString().padStart(2, '0') }h`;
                                    zoomData.push({
                                        x: formattedDate,
                                        y: e.averageVoltage,
                                    });
                                });
                            }
                        });
                    }
                    if (zoomData.length > 0) {
                        isZoomingRef.current = true;
                        chartContext.updateOptions({
                            series: [ {
                                data: zoomData,
                            } ],
                            xaxis: {
                                categories: zoomData.map(d => d.x),
                                tickPlacement: 'on',

                            },
                        }, true, true);
                    } else {
                        console.error('No data available for the selected label');
                    }
                },
            },
        },
        markers: {
            size: 4,
            //  colors: ['#FF5733'],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 4,
            },
        },
        colors: [ '#77B6EA' ],
        dataLabels: {
            enabled: false,
            formatter: function(val) {
                if (val) {
                    return val?.toFixed(2);
                } else if (val === 0) {
                    return val?.toFixed(2);
                }
            },
        },
        xaxis: {
            type: 'category',
            tickPlacement: 'on',
            tickAmount:  chartView ? 8 : 24,
            labels: {
                show: true,
                rotate: -55,
                rotateAlways: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 120,

            },
            offsetY: -5,
            position: 'bottom',
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            enabled: true,
        },
        yaxis: {
            min: 0.00,
            max: 5.00,
            decimalsInFloat: true,
            axisTicks: {
                show: false,
            },
            labels: {
                formatter: (val) => {
                    if (val) {
                        return Number(parseFloat(val.toFixed(2))) + 'V';
                    }
                },
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 14,
                offsetY: 0,
                rotate: 0,
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: -5,
                offsetY: 0,
            },
            title: !chartView && {
                text: 'Average Voltage (V)',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 20,
                right: 10,
            },
        },
        tooltip: {
            enabled: true,
            followCursor: false,
            hideEmptySeries: false,
            onDatasetHover: {
                highlightDataSeries: true,
            },
            y: {
                formatter: function(val) {
                    if (val !== null && val !== undefined) {
                        return Number(parseFloat(val.toFixed(2))) + 'V';
                    } else if (val === 0) {
                        return 0.01 + 'V';
                    } else {
                        return 'No available measurement'
                    }
                },
            },
        },
    };
    useEffect(() => {
        if (lastWeekVoltageChartData.length > 0) {
            setInitialDailyData(lastWeekVoltageChartData.map(day => ({
                x: day.date,
                y: day.dailyAverageVoltage ? parseFloat(day.dailyAverageVoltage) : null,
            })))
        }
    }, [ lastWeekVoltageChartData ])
    useEffect(() => {
        if (lastDayVoltageChartData.length > 0) {
            console.log('initialHourlyData lastDayVoltageChartData ', lastDayVoltageChartData)
            setInitialHourlyData(lastDayVoltageChartData.map(day => {
                const formattedHour = day.hour < 10 ? `0${ day.hour }` : day.hour;
                return {
                    x: `${ day.date } ${ formattedHour }h`,
                    y: day.averageVoltage ? parseFloat(day.averageVoltage) : null,
                }
            }))
        }
    }, [ lastDayVoltageChartData ])
    useEffect(() => {
        if (initialDailyData) {
            setDailyData(initialDailyData)
        }
        setChartOptions(dailyChartOptions)
    }, [ initialDailyData ])
    useEffect(() => {
        if (initialHourlyData) {
            setHourlyData(initialHourlyData)
        }
        setChartOptions(dailyChartOptions)
    }, [ initialHourlyData ])
    return (
        <>
            <div>
                {selectedPeriod === 'lastWeek' && !chartDataAvailable && lastWeekVoltageChartData.length > 0 && initialDailyData.length > 0 && dailyData.length > 0 ? (
                    <div id="daily-chart">
                        <ReactApexChart
                            options={chartOptions}
                            series={[ { name: 'Average Voltage', data: dailyData, color: 'rgba(255,165,0,1)' } ]}
                            type={chartOptions.chart.type}
                            height={350}
                        />
                    </div>
                ) : (
                    selectedPeriod === 'lastWeek' && !chartDataAvailable && (
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                marginTop: '25%',
                                marginBottom: '25%',
                                height: '100%',
                                textAlign: 'center',
                            }}
                        >
                            Loading...
                        </Typography>
                    )
                )}
                {selectedPeriod === 'lastDay' && !chartDataAvailable && lastDayVoltageChartData.length > 0 && initialHourlyData.length > 0 && hourlyData.length > 0 ? (
                    <div id="daily-chart">
                        <ReactApexChart
                            options={chartOptions}
                            series={[ { name: 'Average Voltage', data: hourlyData, color: 'rgba(255,165,0,1)' } ]}
                            type={chartOptions.chart.type}
                            height={350}
                        />
                    </div>
                ) : (
                    selectedPeriod === 'lastDay' && !chartDataAvailable && (
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                marginTop: '25%',
                                marginBottom: '25%',
                                height: '100%',
                                textAlign: 'center',
                            }}
                        >
                            Loading...
                        </Typography>
                    )
                )}
                {selectedPeriod === 'lastMonth' && !chartDataAvailable ? (
                    <div id="chart">
                        <ReactApexChart
                            options={options}
                            series={options.series}
                            type={options.chart.type}
                            height={options.chart.height}
                        />
                    </div>
                ) : (
                    selectedPeriod === 'lastMonth' && (
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                marginTop: '25%',
                                marginBottom: '25%',
                                height: '100%',
                                textAlign: 'center',
                            }}
                        >
                            Loading...
                        </Typography>
                    )
                )}
                {selectedPeriod && chartDataAvailable && (
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            verticalAlign: 'middle',
                            marginTop: '25%',
                            marginBottom: '25%',
                            height: '100%',
                            textAlign: 'center',
                        }}
                    >
                        No coherent data available to display
                    </Typography>
                )}
            </div></>
    );
};
export default VoltageChart;
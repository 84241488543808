import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

const ChartWidget = ({ data,selectedPeriod,title,color }) => {

    const [ chartData, setChartData ] = useState({
        labels: [],
        datasets: [
            {
                label: title,
                data: [],
                fill: false,
                borderColor: color,
                lineTension: 0.1,
            },
        ],
    });

    useEffect(() => {
        const generateTimeLabels = (existingLabels) => {
            if(selectedPeriod==='lastDay'){
                const startTime = new Date(existingLabels[ 0 ]);
                const endTime = new Date(existingLabels[ existingLabels.length - 1 ]);
                const timeLabels = [];
                const currentTime = new Date(startTime);

                while (currentTime <= endTime) {
                    const formattedTime = `${ currentTime.toLocaleDateString(undefined, {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                    }) } ${ currentTime.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    }) }`;
                    timeLabels.push(formattedTime);

                    // Increment time by 30 seconds
                    currentTime.setSeconds(currentTime.getSeconds() + 30);
                }

                return timeLabels;
            } else if (selectedPeriod === 'lastMonth') {
                console.log('existingLabels'+JSON.stringify(existingLabels))

                return existingLabels;
            }  else {
                return existingLabels;
            }

        };
        // Format data for Chart.js

        const formattedData = data.map((entry) => ({
            x: entry.time,
            y:title==='Temperature'? entry.temperature:title==='Voltage'?entry.batteryVoltage:title==='Pressure'?entry.pressure:entry.pH,
        }));

        // Extract labels and data points

        const labels = formattedData.map((entry) => entry.x);
        const ydata = formattedData.map((entry) => entry.y);

        setChartData({
            labels: generateTimeLabels(labels),
            datasets: [
                {
                    label: title,
                    data: ydata,
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    borderColor: color,
                    lineTension: 0.1,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: color,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                },
            ],
        });
    }, [ data ,color,title,selectedPeriod ]);

    return (
        <div>
            <Line

                data={chartData}
                options={{
                    title: {
                        display: true,
                        text:`Chart Line ${ title } Over Time` ,
                    },
                    scales: {
                        xAxes: [

                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Date',
                                    fontColor: '',
                                },
                                gridLines: {

                                    display:false,
                                },
                                ticks: {
                                    fontColor: 'black',
                                    minRotation: 80,
                                    maxRotation: 80,
                                    mirror: true,
                                },
                            },
                        ],yAxes:[
                            { scaleLabel: {
                                display: true,
                                labelString: title==='Temperature'? 'Temperature (°C)':title==='Voltage'?'Voltage (V)':title==='Pressure'?'Pressure (Bar)':'Ph',
                                fontColor: '',
                            } },
                        ],

                    },
                }}
            />
        </div>
    );
};

export default ChartWidget;
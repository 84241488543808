// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Card, Divider, Typography, CardContent, Grid } from '@mui/material';
import CardMedia from '@material-ui/core/CardMedia';
import { Backdrop, CircularProgress } from '@mui/material';
//import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import entityService from '../services/entity.service';
import InviteUserModal from '../components/Modal/inviteUserModal';
import AddEntityModal from '../components/Modal/addEntity';
import Swal from 'sweetalert2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditEntityModal from '../components/Modal/editEntity';
const REACT_APP_SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

function OwnedEntityCard({ ownedEntity, getEntitiesListByOwnerId, setRecentEntity,setUserRecentEntity }) {

    const { user } = useSelector((state) => state.auth);
    const [ show, setShow ] = useState(false)
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEdit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        console.log('handle Edit')
        handleClose()
        setShow(true)

    }

    const handleDelete = (e) => {
        e.stopPropagation()
        e.preventDefault()
        console.log('handle delete')
        handleClose()
        Swal.fire({
            title:
                'Do you really want to delete this entity?',
            showDenyButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                entityService.deleteEntityByOwnerId(ownedEntity.id, ownedEntity.ownerId).then(
                    (response) => {
                        if (response.data.success) {
                            getEntitiesListByOwnerId()
                            setRecentEntity(null)
                            // history.push('/')
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
                            });
                        }
                    },
                    () => {
                        Swal.fire({
                            icon: 'error',
                            text: 'Something went wrong!',
                        });
                    },
                );
            } else if (result.isDenied) {
                Swal.fire(
                    'Entity not deleted',
                    '',
                    'info',
                );
            }
        });
    }
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };
    const ITEM_HEIGHT = 48;

    return (
        <Card sx={{ textAlign: 'center', height: '100%' }}>
            {show && <EditEntityModal show={show} setShow={setShow} ownedEntity={ownedEntity} />}
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Link key={ownedEntity.id}
                        underline="none"
                        color="black"
                        href={`/entity/${ ownedEntity.id }`}
                        textDecoration="none"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <Grid container spacing={2} style={{ cursor: 'pointer' }} onClick={() => {
                            if (!open) {
                                setUserRecentEntity(ownedEntity)
                            }

                        }}>
                            <Grid item xs={12} className=" card-header d-flex align-items-center"  >
                                <h5 className="text-capitalize" style={{ flexGrow: 1 }}>
                                    {ownedEntity.name}
                                    <p className="subtitle1 text-capitalize" style={{ fontWeight: 300, fontSize: 18 }}>
                                        {ownedEntity.users[ 0 ].userEntity.role}
                                    </p>
                                </h5>

                                {
                                    user && ownedEntity.ownerId === user.id && (
                                        <>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}
                                            >
                                                <MenuItem disableRipple onClick={handleEdit} ><IconButton>
                                                    <EditIcon />
                                                </IconButton> Edit</MenuItem>
                                                <MenuItem disableRipple onClick={handleDelete}> <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>Delete</MenuItem>

                                            </Menu>
                                        </>
                                    )
                                }

                            </Grid>
                            <Grid item xs={12} className="d-flex" >
                                <CardMedia
                                    component="img"
                                    height="300px"
                                    image={`${ REACT_APP_SERVER_API_URL }${ ownedEntity.image }`}
                                    alt="Location Image"
                                />
                            </Grid>
                            <Grid container spacing={2} className="d-flex">
                                <Grid item xs={6} lg={3}>
                                    <label className="font-weight-bold form-control-label text-capitalize">
                                        Owner
                                    </label>
                                    <label className=" form-control-label text-dark ml-1 text-capitalize">
                                        {ownedEntity?.owner?.username}
                                    </label>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <label className="font-weight-bold form-control-label text-capitalize">
                                        Address
                                    </label>
                                    <label className=" form-control-label text-dark text-capitalize ">
                                        {ownedEntity.address}
                                    </label>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <label className="font-weight-bold form-control-label text-capitalize ">
                                        Created At
                                    </label>
                                    <label className=" form-control-label text-dark text-capitalize">
                                        {new Date(ownedEntity.createdAt).toLocaleString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </label>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <label className="font-weight-bold form-control-label text-capitalize">
                                        Owned Since
                                    </label>
                                    <label className=" form-control-label text-dark text-capitalize">
                                        {new Date(ownedEntity.users[ 0 ].userEntity.updatedAt).toLocaleString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid></Link>

                </Box>
            </CardContent>
        </Card>
    );
}

const ListEntities = ({ recentEntity, setRecentEntity }) => {
    const [ show, setShow ] = React.useState(false);
    const [ ownedEntities, setOwnedEntities ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { user } = useSelector((state) => state.auth);
    const { user: currentUser } = useSelector((state) => state.auth);
    const getEntitiesListByOwnerId = () => {
        if (user && user.id) {
            entityService.getEntitiesListByOwnerId(user.id).then((response) => {
                if (response.data.success) {

                    const entities = response.data.result;

                    const owned = entities.filter(
                        (entity) => entity.users[ 0 ].userEntity.invitation === null || entity.users[ 0 ].userEntity.invitation === 'accepted');
                    console.log('owned', JSON.stringify(owned))

                    setOwnedEntities(owned);
                    setLoading(false);
                }
            }).catch(() => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    React.useEffect(() => {
        setLoading(true);
        getEntitiesListByOwnerId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user ])

    if (loading) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    const setUserRecentEntity = (entity) => {
        console.log('setUserRecentEntit listEntity', entity)
        if (currentUser && currentUser.id) {
            console.log('call setUserRecentEntity listEntity')
            entityService.setUserRecentEntity(currentUser.id, entity.id).then((response) => {
                if (response.data.success) {
                    console.log('Response setUserRecentEntity', JSON.stringify(response.data))
                    const userDataString = localStorage.getItem('user');
                    const userData = JSON.parse(userDataString);
                    userData.recentEntityId = entity.id;
                    console.log('update recent user entity id with id ', entity.id, localStorage.getItem('user'))
                    localStorage.setItem('user', JSON.stringify(userData));
                    console.log('localStorage', localStorage.getItem('user'))
                    setRecentEntity(entity)
                    //history.push(`/entity/${entity.id}`)
                }
            })
        }

    }

    return (
        <div style={{ overflow:'auto !important' }}>
            <InviteUserModal show={show} setShow={setShow} ownedEntities={ownedEntities} />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" elevation={2} style={{ backgroundColor: '#ECEEF0', marginTop: '20px' }}>
                    <Toolbar
                    >

                        <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: 'black' }}>
                            <h5 className=" justify-content-between align-items-center">
                               Entity List</h5>

                        </Typography>

                    </Toolbar>
                </AppBar>
                <Card
                    elevation={2}
                    style={{ backgroundColor: '#F4F6F8', marginTop: '20px' }}
                >

                    {ownedEntities.length > 0 ? (<Card
                        elevation={0}
                    >

                        <Divider />
                        <CardContent>
                            <Box
                                sx={{
                                    // height:"40vh",
                                    display: 'grid',
                                    gap: 3,
                                    gridTemplateColumns: {
                                        xs: 'repeat(1, 1fr)',
                                        sm: 'repeat(2, 1fr)',
                                        md: 'repeat(3, 1fr)',
                                    },
                                }}
                            >
                                {ownedEntities && ownedEntities.map((entity) => (
                                    <OwnedEntityCard key={entity.id} ownedEntity={entity} getEntitiesListByOwnerId={getEntitiesListByOwnerId} recentEntity={recentEntity} setUserRecentEntity={setUserRecentEntity} setRecentEntity={setRecentEntity} />
                                ))}

                            </Box>
                        </CardContent>
                        <Divider />
                    </Card>) : (<Card
                        elevation={0}
                    >
                        <Divider />
                        <CardContent>
                            <Box
                                sx={{
                                    height: '70vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >

                                    <Typography variant="h6" component="div" color="textSecondary">
                                        You have no entity yet.
                                    </Typography>
                                </Box>
                                <AddEntityModal getEntitiesListByOwnerId={getEntitiesListByOwnerId} setRecentEntity={setRecentEntity} setUserRecentEntity={setUserRecentEntity} />
                            </Box>
                        </CardContent>
                        <Divider />
                    </Card>)} </Card>
            </Box>
        </div>
    );
};

export default ListEntities;
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { Link } from 'react-router-dom';
import { isEmail } from 'validator';
import { login } from '../actions/auth';
import logo  from '../assets/Watermon.png'

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
        This field is required!
            </div>
        );
    }
};
const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
        This is not a valid email.
            </div>
        );
    }
};
const Login = () => {
    const form = useRef();
    const checkBtn = useRef();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const { isLoggedIn } = useSelector((state) => state.auth);
    const { user: currentUser } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };
    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(email, password))
                .then(() => {

                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    if (isLoggedIn) {
        console.log('currentUser',currentUser)
        if(currentUser && currentUser.recentEntityId){
            console.log('currentUser inside1',currentUser.recentEntityId)
            return <Redirect to={`/entity/${ currentUser.recentEntityId }`} />;
        }else{
            console.log('currentUser inside2',currentUser.recentEntityId)
            return <Redirect to={'/entity'} />;
        }
    }

    return (
        <div className="col-md-12 parent-container " >
            <div className="card card-container "  >
                <img
                    src={logo}
                    alt="profile-img"
                    className="profile-img-card"
                />

                <Form onSubmit={handleLogin} ref={form}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            validations={[ required, validEmail ]}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[ required ]}
                        />
                    </div>

                    <div className="form-group">
                        <button className="btn btn-success btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Sign in</span>
                        </button>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={5}>
                                <Link
                                    href="#"
                                    to='/'
                                    style={{
                                        fontSize: '16px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                        color: '#28A745',
                                    }}
                                >
                  Forgot password?
                                </Link>
                            </Col>
                            <Col sm={7}>
                Don&apos;t have an account?
                                <a
                                    href="/register"
                                    style={{
                                        fontSize: '16px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                        color: '#28A745',
                                    }}
                                >
                                    {'Sign Up'}
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: 'none' }} ref={checkBtn} />
                </Form>
            </div>
        </div>
    );
};

export default Login;

// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import DeviceTable from '../components/DeviceTable/DataTable';
import GatewayTable from '../components/GatewayTable/DataTable';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
//import Breadcrumbs from '@mui/material/Breadcrumbs';
//import Link from '@mui/material/Link';
import InviteUserModal from '../components/Modal/inviteUserModal';
import LocationService from '../services/location.service';
import { clearMessage } from '../actions/message';
import { getEntityById } from '../actions/entityAction';
import { useSocket } from '../SocketContext';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import noLocationImage from '../assets/noLocation.png'
/* import socket from '../socket'; */
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'initial',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
    },
    header: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    card: {
        maxWidth: '100%',
        width: '100%',
        maxHeight: '65vh',
        height: '100%',
        cursor: 'pointer',
        transition: '0.3s',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
    },
    media: {
        paddingTop: '90%',
    },

    heading: {
        fontWeight: 'bold',
    },
    subheading: {
        lineHeight: 1.8,
    },
    avatar: {
        display: 'inline-block',
        border: '2px solid white',
    },
    iconStyle: {
        left: '30%',
    },
}));

export default function ListLocations({ setRecentEntity, recentEntity }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { entityId } = useParams();
    const [ content, setContent ] = useState([]);
    const REACT_APP_SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
    const { user: currentUser } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth);
    const { entity } = useSelector((state) => state.entity)
    const [ isAdmin, setIsAdmin ] = useState(Boolean)
    const [ show, setShow ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ ownedEntities, setOwnedEntities ] = useState([]);
    const [ userEntity, setUserEntity ] = useState(null);

    const [ tabValues, setTabValues ] = useState({});

    const handleChangee = (id, newValue) => {
        setTabValues(prevState => ({
            ...prevState,
            [ id ]: newValue,
        }));
    };
    let socket;
    const userSocket = useSocket()
    useEffect(() => {
        if (user) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            socket = user.socket ? user.socket : userSocket
        }
    }, [ user ])

    useEffect(() => {
        if (content && content.users) {
            const theCurrentUser = content.users.find(user => user.username === currentUser.username);
            if (theCurrentUser) {
                setIsAdmin(theCurrentUser.userEntity.role === 'admin');
            }
        }
    }, [ content, currentUser ]);

    useEffect(() => {
        if (isAdmin) {
            console.log('isAdmin', isAdmin)
        }
    }, [ isAdmin ])
    const { message } = useSelector((state) => state.message);

    const [ visible, setVisible ] = useState(!!message);
    const getLocationsListByEntityId = (entityId) => {
        console.log('Call getLocationsListByEntityId', entityId)
        LocationService.getLocationsListByEntityId(entityId).then(

            (response) => {
                console.log('response.data.result', response.data.result)
                setContent(response.data.result);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            },
        );

    };

    useEffect(() => {
        // Fetch locations list by entity ID
        if (entityId) {
            getLocationsListByEntityId(entityId);
        }
        if (entity) {
            console.log('entity', entity)
            dispatch(getEntityById(entityId)).then((response) => {
                console.log(response)
                console.log('getEntityById entityId', entityId)
                setUserEntity(entity)
                console.log('userEntity', userEntity)
            })
            setRecentEntity(entity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dispatch, entityId ]);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {

            console.log('recent entity is empty')

            const currentPathname = window.location.pathname;

            // Check if the current pathname contains 'entity/52'
            if (currentPathname.includes('/entity/')) {
                // Extract the entity ID
                console.log('===>currentPathname', currentPathname)
                const entityId = currentPathname.split('/').pop(); // Get the last segment
                console.log('Entity ID:', entityId); // Output: Entity ID: 52

            }
            dispatch(getEntityById(entityId)).then((response) => {
                console.log('getEntityById', response)
                console.log('getEntityById entityId', response.result)

                setRecentEntity(response.result)

            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity ])

    useEffect(() => {
        if (socket) {

            userSocket.on('update_device_data', () => {
                getLocationsListByEntityId(entityId);
            });
            userSocket.on('Get_TTN_Connect_Status', () => {
                console.log('Get_TTN_Connect_Status');
                getLocationsListByEntityId(entityId);
            })
            userSocket.on('gateway_status_changed', () => {
                console.log('gateway_status_changed');
                getLocationsListByEntityId(entityId);
            })
            return () => {
                //  socket.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ socket ]);
    useEffect(() => {
        if (message && message.length > 0) {
            setVisible(true);

            const timer = setTimeout(() => {
                setVisible(false);
                dispatch(clearMessage());
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [ message, dispatch ]);

    return (
        <div className="root">
            <AppBar position="static" sx={{ backgroundColor: '#F7F7F7 !important' }}>
                <Container maxWidth="100vw">
                    <InviteUserModal show={show} setShow={setShow} ownedEntities={ownedEntities} entityId={entityId} />
                    <Toolbar className={classes.title} disableGutters sx={{ mb: -1 }}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'black',

                            }}
                        >
                            {content && (<h4 style={{ color: 'black' }}> {content?.name}</h4>)}

                        </Typography>
                        <h5 className=" d-flex justify-content-between align-items-center">

                            {isAdmin && (
                                <Box ml="auto">
                                    <Button type="submit" className="btn btn-success" onClick={() => {
                                        window.location.href = `/addLocation/${ entity.id }`
                                    }}> Add Location</Button>

                                </Box>
                            )}
                        </h5>

                    </Toolbar>
                </Container>
            </AppBar>
            <br />
            {visible && message.length > 0 && (
                <div className="form-group ">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={2} > <Card
                    elevation={0}
                    style={{ backgroundColor: '#F4F6F8', height: '100%' }}
                >
                    <h5 className="card-header d-flex justify-content-between align-items-center">
                        Informations
                    </h5>

                    <CardContent>
                        <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} className="d-flex">
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={`${ REACT_APP_SERVER_API_URL }${ content?.image }`}
                                        alt="Entity Image"
                                    />
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Entity Name  <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark">
                                        {content?.name}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Entity Address  <span className="text-danger" >*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark text-capitalize">
                                        {content?.address}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Owner <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark text-capitalize">
                                        {content?.owner?.username}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Role <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark text-capitalize">
                                        {content?.users?.find(user => user?.username === currentUser?.username)?.userEntity?.role}
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Created At <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark">
                                        {new Date(content?.createdAt).toLocaleString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </label>

                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <label className="font-weight-bold form-control-label">
                                        Owned Since <span className="text-danger">*</span>
                                    </label>
                                    <label className="ml-2 form-control-label text-dark">
                                        {new Date(content?.users?.find(user => user.id === JSON.parse(localStorage.getItem('user'))?.id)?.userEntity?.updatedAt).toLocaleString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </label>

                                </Grid>

                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={10} >
                    <Card
                        elevation={0}
                        style={{ backgroundColor: '#F4F6F8', height: '100%' }}>
                        {/*    <h5 className=" d-flex justify-content-between align-items-center">

                            {isAdmin && (
                                <Box ml="auto">
                                    <Button type="submit" className="btn btn-success" onClick={() => {
                                        window.location.href = `/addLocation/${ entity.id }`
                                    }}> Add Location</Button>

                                </Box>
                            )}
                        </h5> */}

                        <CardContent>
                            <Grid container spacing={1}>

                                {content?.locations && content?.locations?.length > 0 ?
                                    content?.locations?.sort((a, b) => b.devices.length - a.devices.length)
                                        ?.map((cards) => {
                                            const {
                                                id,
                                                image,
                                                name,
                                                description,
                                                city,
                                                zipCode,
                                                state,
                                                devices,
                                                gateways,
                                            } = cards;
                                            return (
                                                <Grid item xs={12} sm={12} md={12} xl={6} lg={6} key={id}>
                                                    <Card key={id} className={classes.card} elevation={0} >
                                                        <CardContent style={{ height: '100%' }}>
                                                            <Box sx={{ width: 1, height: '100%', minHeight: 380, overflow: 'auto' }}>
                                                                <Box
                                                                    sx={{ width: 1, height: '100%' }}
                                                                    display="grid"
                                                                    gridTemplateColumns="repeat(12, 1fr)"
                                                                    gap={2}
                                                                >

                                                                    <Box
                                                                        gridColumn={{
                                                                            xs: 'span 12',
                                                                            sm: 'span 12',
                                                                            md: 'span 12',
                                                                            lg: 'span 12',
                                                                            xl: 'span 12',
                                                                        }}
                                                                        sx={{ height: '100%' }}
                                                                    >

                                                                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                                                            <TabContext value={tabValues[ id ] || '1'}>
                                                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                                    <TabList sx={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        '& .css-1m2tz38-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                                                                                            color: 'green',
                                                                                        },
                                                                                        '& .Mui-selected': {
                                                                                            backgroundColor: '#F7F7F7',

                                                                                        }, '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                                                                                            color: 'green',
                                                                                        }, '&  .css-1aquho2-MuiTabs-indicator': {
                                                                                            backgroundColor: 'green',
                                                                                        },
                                                                                    }} onChange={(event, newValue) => handleChangee(id, newValue)} aria-label="lab API tabs example">
                                                                                        <Tab sx={{
                                                                                            width: '33%',
                                                                                            justifyContent: 'center',
                                                                                        }} label="Location Details" value="1" component="h6" />
                                                                                        <Tab sx={{
                                                                                            width: '33%',
                                                                                            justifyContent: 'center',
                                                                                        }} label="Devices" value="2" component="h6" />
                                                                                        <Tab sx={{
                                                                                            width: '33%',
                                                                                            justifyContent: 'center',
                                                                                        }} label="Gateways" value="3" component="h6" />

                                                                                    </TabList>
                                                                                </Box>
                                                                                <TabPanel value="1">
                                                                                    <Stack
                                                                                        direction="column"
                                                                                        justifyContent="flex-start"
                                                                                        alignItems="stretch"
                                                                                        spacing={0.5}
                                                                                        gridColumn={{
                                                                                            xs: 'span 12',
                                                                                            sm: 'span 12',
                                                                                            md: 'span 12',
                                                                                            lg: 'span 12',
                                                                                            xl: 'span 12',
                                                                                        }}
                                                                                        sx={{ width: 1, height: '100%' }}
                                                                                    >
                                                                                        <div className="d-flex" style={{ display: 'flex', height: '180px' }} onClick={() => {
                                                                                            window.location.href = (`/location/${ id }`);
                                                                                        }}>
                                                                                            <CardMedia
                                                                                                component="img"
                                                                                                style={{
                                                                                                    width: '30%',
                                                                                                    height: '100%',
                                                                                                    objectFit: 'cover',

                                                                                                }}
                                                                                                alt="img"
                                                                                                className='mt-3'
                                                                                                image={image.length > 0 ? `${ REACT_APP_SERVER_API_URL }${ image }` : noLocationImage}
                                                                                            />
                                                                                            <Grid container className='ml-2' style={{ flex: 1 }}>
                                                                                                <Grid item xs={12} className="d-flex">
                                                                                                    <label className="font-weight-bold form-control-label">
                                                                                                    Location name <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <label className="ml-2 form-control-label text-dark">
                                                                                                        {name}
                                                                                                    </label>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} className="d-flex">
                                                                                                    <label className="font-weight-bold form-control-label">
                                                                                                    Location address <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <label className="ml-2 form-control-label text-dark text-capitalize">
                                                                                                        {city} {state} , {zipCode}
                                                                                                    </label>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} className="d-flex">
                                                                                                    <label className="font-weight-bold form-control-label">
                                                                                                    Location description <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <label className="ml-2 form-control-label text-dark">
                                                                                                        {description}
                                                                                                    </label>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} className="d-flex">
                                                                                                    <label className="font-weight-bold form-control-label">
                                                                                                    Location devices <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <label className="ml-2 form-control-label text-dark">
                                                                                                        {devices.length}
                                                                                                    </label>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} className="d-flex">
                                                                                                    <label className="font-weight-bold form-control-label">
                                                                                                    Location gateways <span className="text-danger">*</span>
                                                                                                    </label>
                                                                                                    <label className="ml-2 form-control-label text-dark">
                                                                                                        {gateways.length}
                                                                                                    </label>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </div>
                                                                                    </Stack>
                                                                                </TabPanel>
                                                                                <TabPanel value="2" >   {devices && (
                                                                                    <DeviceTable
                                                                                        data={devices}
                                                                                        locationId={id}
                                                                                        listLocation={true}
                                                                                        content={content}
                                                                                    />
                                                                                )} </TabPanel>
                                                                                <TabPanel value="3">
                                                                                    {gateways && (
                                                                                        <GatewayTable
                                                                                            data={gateways}
                                                                                            locationId={id}
                                                                                            listLocation={true}
                                                                                        />
                                                                                    )}</TabPanel>

                                                                            </TabContext>
                                                                        </Box>

                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        }) : (<Grid item xs={12} >
                                        <Card style={{ width: '100%', height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >No available locations.</Card></Grid>)}
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </div>
    );
}

import { combineReducers } from 'redux';
import auth from './auth';
import deviceList from './deviceListReducer';
import device from './deviceReducer';
import gatewayList from './gatewayListReducer';
import gateway from './gatewayReducer';
import location from './locationReducer';
import entity from './entityReducer';
import message from './message';
export default combineReducers({
    auth,
    message,
    location,
    deviceList,
    device,
    gatewayList,
    gateway,
    entity,
});

import {
    ADD_DEVICE_FAIL,
    ADD_DEVICE_SUCCESS,
    GET_DEVICE_FAIL,
    GET_DEVICE_SUCCESS,
    GET_DEVICE_LAST_MESSAGE_SUCCESS,
    GET_DEVICE_LAST_MESSAGE_FAIL,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case ADD_DEVICE_SUCCESS:
        return {
            ...state,
            data: payload.data,
            isLoggedIn: true,
        };
    case ADD_DEVICE_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };

    case GET_DEVICE_SUCCESS:
        return {
            ...state,
            device: payload.device,
            isLoggedIn: true,
        };
    case GET_DEVICE_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };
    case GET_DEVICE_LAST_MESSAGE_SUCCESS:
        return {
            ...state,
            deviceMessage: payload.deviceMessage,
            isLoggedIn: true,
        };
    case GET_DEVICE_LAST_MESSAGE_FAIL:
        return {
            ...state,
            isLoggedIn: true,
        };
    default:
        return state;
    }
}

import {
    ADD_GATEWAY_FAIL,
    ADD_GATEWAY_SUCCESS,
    DELETE_GATEWAY_FAIL,
    DELETE_GATEWAY_SUCCESS,
    GET_GATEWAYS_LIST_FAIL,
    GET_GATEWAYS_LIST_SUCCESS,
    GET_GATEWAY_FAIL,
    GET_GATEWAY_SUCCESS,
    SET_MESSAGE,
    UPDATE_GATEWAY_SUCCESS,
    UPDATE_GATEWAY_FAIL,
} from './types';

import GatewayService from '../services/gateway.service';
export const addGateway =
  (name, description, locationId,macAddress,lat,lng) => (dispatch) => {
      return GatewayService.addGateway(name, description, locationId,macAddress,lat,lng).then(
          (response) => {
              dispatch({
                  type: ADD_GATEWAY_SUCCESS,
                  payload: { data: response },
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: response.data.message,
              });

              return response.data;
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: ADD_GATEWAY_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              // return Promise.reject();
          },
      );
  };

export const updateGateway =
  (gatewayId, name, eui, description,lat,lng) => (dispatch) => {
      return GatewayService.updateGateway(gatewayId, name, eui, description,lat,lng).then(
          (response) => {
              dispatch({
                  type: UPDATE_GATEWAY_SUCCESS,
                  payload: { data: response },
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: response.data.message,
              });

              return response.data;
          },
          (error) => {
              const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

              dispatch({
                  type: UPDATE_GATEWAY_FAIL,
              });

              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });

              return Promise.reject();
          },
      );
  };

export const deleteGatewayById = (gatewayId) => (dispatch) => {
    return GatewayService.deleteGatewayById(gatewayId).then(
        (response) => {
            dispatch({
                type: DELETE_GATEWAY_SUCCESS,
                payload: { data: response },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });

            return response.data;
        },
        (error) => {
            const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

            dispatch({
                type: DELETE_GATEWAY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const getGatewayListByLocationId = (locationId) => (dispatch) => {
    return GatewayService.getGatewayListByLocationId(locationId).then(
        (response) => {
            if (response.data.success) {
                dispatch({
                    type: GET_GATEWAYS_LIST_SUCCESS,
                    payload: { gatewayList: response.data.result },
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.message,
                });
                return Promise.resolve();
            } else {
                dispatch({
                    type: GET_GATEWAYS_LIST_FAIL,
                });

                dispatch({
                    type: SET_MESSAGE,
                    payload: response.data.result,
                });
                return Promise.reject();
            }
        },
    );
};

export const getGatewayById = (id) => (dispatch) => {
    return GatewayService.getGatewayById(id).then((response) => {
        if (response.data.success) {
            dispatch({
                type: GET_GATEWAY_SUCCESS,
                payload: { gateway: response.data.result },
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            });
            return Promise.resolve();
        } else {
            dispatch({
                type: GET_GATEWAY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: response.data.result,
            });
            return response.data
        }
    });
};

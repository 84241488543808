import { Card, Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDeviceById, getDeviceLastMessage } from '../actions/deviceAction';
import { getLocationsById } from '../actions/locationAction';
import { interpretHexValues } from '../Utils/DecodeUplink';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSocket } from '../SocketContext';
import { getEntityById } from '../actions/entityAction';
import WeatherChart from '../components/chart/WeatherChart';
import VoltageChart from '../components/chart/VoltageChart';
import PressureChart from '../components/chart/PressureChart';
import PhChart from '../components/chart/PhChart';
export default function ChartView({ recentEntity, setRecentEntity }) {
    const socket = useSocket()
    const { locationId } = useParams();
    const { deviceId } = useParams();
    const dispatch = useDispatch();
    const { location } = useSelector((state) => state.location);
    const { device, deviceMessage } = useSelector((state) => state.device);
    // eslint-disable-next-line
    const [ input0, setInput0 ] = useState(null);
    // eslint-disable-next-line
    const [ input1, setInput1 ] = useState(null);
    const [ output0, setOutput0 ] = useState(null);
    // eslint-disable-next-line
    const [ output1, setOutput1 ] = useState(null);
    // eslint-disable-next-line
    const [ ph, setPh ] = useState(null);
    // eslint-disable-next-line
    const [ batteryVoltage, setBatteryVoltage ] = useState(null);
    // eslint-disable-next-line
    const [batteryCurrent, setBatteryCurrent] = useState(null);
    // eslint-disable-next-line
    const [CO2, setCO2] = useState(null);
    // eslint-disable-next-line
    const [ pressure, setPressure ] = useState(null);
    // eslint-disable-next-line
    const [ temperature, setTemperature ] = useState(null);
    const [ selectedTemperaturePeriod, setSelectedTemperaturePeriod ] = useState('lastDay');
    const [ selectedVoltagePeriod, setSelectedVoltagePeriod ] = useState('lastDay');
    const [ selectedPhPeriod, setSelectedPhPeriod ] = useState('lastDay');
    const [ selectedPressurePeriod, setSelectedPressurePeriod ] = useState('lastDay');

    useEffect(() => {
        const updateDeviceData = (data) => {
            if (JSON.parse(deviceId) === data.id) {
                dispatch(getDeviceById(deviceId));
                console.log('data', data)
                if (data.data !== null) {
                    interpretHexValues(
                        data.data?.replace(/"/g, ''),
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                }

            }
        };

        socket.on('update_device_data', updateDeviceData);

    }, [ deviceId, dispatch, socket ]);

    useEffect(() => {
        if (output0) {
            console.log('output0 changed')
        }
    }, [ output0 ])
    useEffect(() => {
        if (device && device.eui) {
            dispatch(getDeviceLastMessage(device.eui)).then(() => {
                if (deviceMessage && deviceMessage.data) {
                    interpretHexValues(
                        deviceMessage?.data,
                        setOutput0,
                        setOutput1,
                        setInput0,
                        setInput1,
                        setBatteryVoltage,
                        setPressure,
                        setTemperature,
                        setBatteryCurrent,
                        setCO2,
                        setPh,
                    );
                }
            });
        }
    }, [ deviceMessage?.data ]);

    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location) {

                console.log('recent entity is empty')
                console.log('entity', location?.entities[ 0 ].id)

                // Check if the current pathname contains 'entity/52'

                dispatch(getEntityById(location?.entities[ 0 ].id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    if (response.success) {
                        setRecentEntity(response.result);
                    } else {
                        window.location.href = '/notfound';
                    }

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])

    useEffect(() => {
        dispatch(getLocationsById(locationId)).then((response) => {
            console.log('getLocationsById Response', response)
            if(!response.success){
                window.location.href = '/notfound';
            }
        });
        dispatch(getDeviceById(deviceId));
    }, [  deviceId ]);

    const tempChartRef = React.useRef(null);
    const voltChartRef = React.useRef(null);
    const pressureChartRef = React.useRef(null);
    const phChartRef = React.useRef(null);

    const scrollToTempChart = () => {
        if (tempChartRef.current) {
            console.log('tempChartRef.current',tempChartRef.current)
            tempChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToVoltChart = () => {
        if (voltChartRef.current) {
            voltChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPressureChart = () => {
        if (pressureChartRef.current) {
            pressureChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPhChart = () => {
        if (phChartRef.current) {
            phChartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const { sensor } = useParams(); // Assuming your parameter is named 'param'

    useEffect(() => {
        console.log('sensor', sensor);
        switch (sensor) {
        case 'temp':
            scrollToTempChart();
            break;
        case 'volt':
            scrollToVoltChart();
            break;
        case 'pressure':
            scrollToPressureChart();
            break;
        case 'ph':
            scrollToPhChart();
            break;
        default:
            break;
        }
    }, [ sensor ]);
    return (

        <>

            <Grid container spacing={1} elevation={1}>

                <Grid item xs={12}  ref={tempChartRef}>
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 300 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedTemperaturePeriod === 'lastDay' && 'Average Temperature Over Last Day'}
                            {selectedTemperaturePeriod === 'lastWeek' && 'Temperature Extremes Over Last Week'}
                            {selectedTemperaturePeriod === 'lastMonth' && 'Temperature Extremes Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedTemperaturePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedTemperaturePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <WeatherChart deviceEUI={device?.eui} selectedPeriod={selectedTemperaturePeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}  ref={voltChartRef} >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 300 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedVoltagePeriod === 'lastDay' && 'Average Voltage Over Last Day'}
                            {selectedVoltagePeriod === 'lastWeek' && 'Average Voltage  Over Last Week'}
                            {selectedVoltagePeriod === 'lastMonth' && 'Average Voltage  Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedVoltagePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedVoltagePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <VoltageChart deviceEui={device?.eui} selectedPeriod={selectedVoltagePeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12}  ref={pressureChartRef} >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 300 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedPressurePeriod === 'lastDay' && 'Average Pressure Over Last Day'}
                            {selectedPressurePeriod === 'lastWeek' && 'Average Pressure Over Last Week'}
                            {selectedPressurePeriod === 'lastMonth' && 'Average Pressure Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedPressurePeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedPressurePeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />

                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <PressureChart deviceEui={device?.eui} selectedPeriod={selectedPressurePeriod} socket={socket} />
                        </div>
                    </Card>

                </Grid>

                <Grid item xs={12}  ref={phChartRef}  >
                    <Card elevation={2} sx={{ minWidth: 275, minHeight: 300 }}>
                        <h5 className="card-header d-flex justify-content-between align-items-center w-full">
                            {selectedPhPeriod === 'lastDay' && 'Average Ph Over Last Day'}
                            {selectedPhPeriod === 'lastWeek' && 'Average Ph  Over Last Week'}
                            {selectedPhPeriod === 'lastMonth' && 'Average Ph  Over Last Month'}
                            <Box sx={{ minWidth: 120, marginLeft: 'auto' }}>
                                <FormControl >
                                    <InputLabel id="period-select-label">Select Period</InputLabel>
                                    <Select
                                        color="success"
                                        labelId="period-select-label"
                                        id="period-select"
                                        value={selectedPhPeriod}
                                        label="Select Period"
                                        onChange={(event) =>
                                            setSelectedPhPeriod(event.target.value)
                                        }
                                    >

                                        <MenuItem value="lastDay">Last Day</MenuItem>
                                        <MenuItem value="lastWeek">Last Week</MenuItem>
                                        <MenuItem value="lastMonth">Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </h5>
                        <Divider />
                        <div style={{ paddingRight: '10px', paddingLeft: '10px', marginTop: '40px' }}>
                            <PhChart deviceEui={device?.eui} selectedPeriod={selectedPhPeriod} socket={socket} />
                        </div>
                    </Card>
                </Grid>

            </Grid>

        </>
    );
}

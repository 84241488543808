import React, {  useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { getEntityById } from '../../actions/entityAction';
import { Card, CardContent } from '@material-ui/core';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsById } from '../../actions/locationAction';
import MapComponent from '../Map/MapComponent';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import {
    updateGateway, getGatewayById,
} from '../../actions/gatewayAction';
import { useHistory } from 'react-router-dom';
const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
});
const UpdateGatewayModal = ({

    recentEntity, setRecentEntity, socket,
}) => {

    const { locationId } = useParams();
    const { location } = useSelector((state) => state.location);
    const dispatch = useDispatch();
    const [ lng, setLng ] = React.useState(null);
    const [ lat, setLat ] = React.useState(null);
    const { gateway } = useSelector((state) => state.gateway);
    const { gatewayId } = useParams();
    const history = useHistory()
    console.log('gatewayId', gatewayId)
    console.log('gateway', gateway)
    useEffect(() => {
        dispatch(getLocationsById(locationId))
        dispatch(getGatewayById(gatewayId))
    }, [ dispatch, locationId, gatewayId ]);
    useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location?.entities[ 0 ]) {

                console.log('recent entity is empty')
                console.log('entity', location?.entities[ 0 ])

                dispatch(getEntityById(location?.entities[ 0 ]?.id)).then((response) => {
                    console.log('getEntityById', response)
                    console.log('getEntityById entityId', response.result)

                    setRecentEntity(response.result)

                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])
    return (
        <>   <Card
            elevation={2}
            style={{ backgroundColor: '#F4F6F8', height: '100%', marginRight: 10, marginLeft: 10 }}
        >
            <h5 className="card-header d-flex justify-content-between align-items-center">
                Update Gateway
            </h5>

            <CardContent>
                <Box sx={{ flexGrow: 1 }}>

                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => {

                            Swal.fire({
                                title: 'Do you really want to Update gateway?',
                                showDenyButton: true,
                                confirmButtonColor: 'red',
                                confirmButtonText: 'Yes',
                                denyButtonText: 'No',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch(
                                        updateGateway(
                                            gateway?.id,
                                            values.name,
                                            values.eui,
                                            values.description,
                                            lat,
                                            lng,
                                        ),
                                    ).then((response) => {
                                        if (response.success) {

                                            Swal.fire({
                                                title: 'Gateway updated successfully',
                                                icon: 'success',
                                                timer: 2000,  // Auto-close after 2 seconds
                                                showConfirmButton: false,
                                            }).then(() => {

                                                history.push(`/location/${ locationId }`)
                                            });
                                        } else {
                                            Swal.fire(
                                                'An error occured while updating gateway',
                                                '',
                                                'error',
                                            ).then(() => {
                                                Swal.close();

                                                // history.push(`/location/${locationId}`)
                                            });
                                        }
                                    });
                                }
                            });
                        }}
                        initialValues={{
                            name: gateway?.name,
                            eui: gateway?.eui,
                            description: gateway?.description,
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Gateway mac address</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            placeholder="Gateway Mac Address"
                                            name="macAddress"
                                            value={gateway?.macAddress || ''}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Gateway location</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            placeholder="Gateway location"
                                            name="location"
                                            value={gateway?.location?.name || ''}

                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Gateway name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Gateway name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />{' '}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik01">
                                        <Form.Label>Gateway description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Gateway Description"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            isInvalid={!!errors.description}
                                        />{' '}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <div className="text mb-3" >Please drag your gateway to adjust its position.</div>
                                <MapComponent apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'} lat={lat} lng={lng} setLat={setLat} setLng={setLng} gatewayId={gatewayId} socket={socket} />

                                <Button
                                    type="submit"
                                    className="btn btn-success mt-1"
                                    onClick={handleSubmit}
                                >
                                    Submit form
                                </Button>

                            </Form>
                        )}
                    </Formik>

                </Box></CardContent></Card>
        </>
    );
};
export default UpdateGatewayModal;

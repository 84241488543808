// eslint-disable-next-line react-hooks/exhaustive-deps
import React, {  useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { addGateway } from '../../actions/gatewayAction';
import { getEntityById } from '../../actions/entityAction';
import Swal from 'sweetalert2';
import { Card, CardContent } from '@material-ui/core';
import Box from '@mui/material/Box';
import GatewayService from '../../services/gateway.service';
import { useSocket } from '../../SocketContext';
import MapComponent from '../Map/MapComponent';
import { getLocationsById } from '../../actions/locationAction';
import { useHistory } from 'react-router-dom';
const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
const schema = yup.object().shape({
    name: yup.string().required(),
    macAddress: yup.string().matches(macAddressRegex, 'Invalid MAC Address').required(),
});

const AddGatewayModal = ({ recentEntity, setRecentEntity }) => {
    const socket = useSocket();
    const { location } = useSelector((state) => state.location);
    const { locationId } = useParams();
    const dispatch = useDispatch();
    const gatewaySwalRef = useRef(null);
    let gatewayAdded = false;
    const history = useHistory();
    console.log('location', location)

    useEffect(() => {
        dispatch(getLocationsById(locationId))
    }, [ dispatch, locationId ]);

    React.useEffect(() => {
        if (recentEntity) {
            console.log('recentEntity', recentEntity)
        } else {
            if (location) {
                dispatch(getEntityById(location?.entities[ 0 ]?.id)).then((response) => {
                    setRecentEntity(response.result)
                })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ recentEntity, location ])

    const [ lng, setLng ] = React.useState(null);
    const [ lat, setLat ] = React.useState(null);
    const [ latChanged, setLatChanged ] = React.useState(false);
    const [ lngChanged, setLngChanged ] = React.useState(false);

    React.useEffect(() => {
        if (lat !== null) {
            setLatChanged(true);
        }
    }, [ lat ]);

    React.useEffect(() => {
        if (lng !== null) {
            setLngChanged(true);
        }
    }, [ lng ]);

    useEffect(() => {
        socket.on('user_connected', () => {
            console.log('user_connected');
        });
        socket.on('Get_Ack_Config', (data) => {
            console.log('Get_Ack_Config', JSON.stringify(data));
            if (data.data.includes('Success')) {

                if (gatewaySwalRef.current) {
                    gatewaySwalRef.current.close();
                }
                gatewayAdded = false;

                if (data.macAddress === sessionStorage.getItem('macAddress')) {
                    Swal.fire({
                        title: 'Gateway added succesfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                    }).then(() => {
                        sessionStorage.removeItem('macAddress');
                        sessionStorage.removeItem('GwId');
                        setTimeout(() => {
                            window.location.href = `/location/${ locationId }`;
                        }, 1000);

                    });
                }

            } else if (data.data.includes('Failure')) {
                console.log('Get_Ack_Config', JSON.stringify(data));
                if (data.macAddress === sessionStorage.getItem('macAddress')) {
                    Swal.fire({
                        title: 'Cannot Add Gateway.',
                        icon: 'error',
                        showConfirmButton: false, // Remove the OK button
                        showCancelButton: true, // Show the Cancel button
                        cancelButtonText: 'Cancel',
                    }).then(() => {
                        console.log('deleteGatewayById Get_Ack_Config Failure', sessionStorage.getItem('GwId'));
                        GatewayService.deleteGatewayById(sessionStorage.getItem('GwId')).then((response) => {
                            if (response) {
                                setTimeout(() => {
                                    window.location.href = `/location/${ locationId }`;
                                }, 2000);
                                console.log('Delete Gateway response', JSON.stringify(response));
                                sessionStorage.removeItem('macAddress');
                                sessionStorage.removeItem('GwId');
                            }
                        });
                    });

                }

            }
        });

    }, []);

    const checkGatewayExistence = async(macAddress) => {
        try {
            // Make an API call to check if the gateway exists
            const response = await GatewayService.checkGatewayExistence(macAddress);
            if (response.success) {
                // Gateway exists
                //   sessionStorage.removeItem('macAddress');
                //  sessionStorage.removeItem('name');
                return true;
            } else {
                // Gateway doesn't exist
                return false;
            }
        } catch (error) {
            console.error('Error checking gateway existence:', error);
            // Handle error (e.g., show an error message)
            return false; // Assuming false means gateway doesn't exist due to error
        }
    };

    let timeoutId;

    function handleTimeout() {
        Swal.close();
        Swal.fire({
            title: 'Cannot Add Gateway.',
            icon: 'error',
            showConfirmButton: false, // Remove the OK button
            showCancelButton: true, // Show the Cancel button
            cancelButtonText: 'Cancel',
        })

        GatewayService.deleteGatewayById(sessionStorage.getItem('GwId')).then((response) => {
            if (response) {
                console.log('Delete Gateway response', JSON.stringify(response));
                sessionStorage.removeItem('GwId');
                setTimeout(() => {
                    Swal.close()
                    history.push(`/location/${ locationId }`)
                }, 1000);
            }
        });
    }

    useEffect(() => {
        const handleGetConfirmConfig = (data) => {
            console.log('handleGetConfirmConfig');
            if (!gatewayAdded && data.macAddress === sessionStorage.getItem('macAddress')) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                gatewayAdded = true;
                Swal.close();
                dispatch(addGateway(sessionStorage.getItem('name'), location.name, locationId, sessionStorage.getItem('macAddress'), lat, lng)).then((response) => {
                    // sessionStorage.removeItem('macAddress');
                    sessionStorage.removeItem('name');
                    console.log('add gateway response', JSON.stringify(response.result));
                    if (response.success) {
                        gatewayAdded = false;
                        sessionStorage.setItem('GwId', response.result.id);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        gatewaySwalRef.current = Swal.fire({
                            title: 'Waiting for adding gateway',
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            timer: 30000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                setTimeout(() => {
                                    console.log('Waiting for adding gateway timeout')
                                    handleTimeout()
                                }, 30000);
                                // eslint-disable-next-line react-hooks/exhaustive-deps
                                timeoutId = setTimeout(handleTimeout, 30000);
                            },
                        });
                        console.log('gatewaySwalRef.current here 1', gatewaySwalRef.current)
                        // Add a listener to detect when the swal is closed and clear the timeout
                        gatewaySwalRef.current.then(() => {
                            console.log('gatewaySwal here 2 clearTimeout')
                            clearTimeout(timeoutId); // Clear the timeout when swal is closed

                        });

                    } else {
                        if (gatewayAdded === true) {
                            console.log('display Cannot Add Gateway')
                            Swal.fire({
                                title: 'Cannot Add Gateway.',
                                icon: 'error',
                                showConfirmButton: false, // Remove the OK button
                                showCancelButton: true, // Show the Cancel button
                                cancelButtonText: 'Cancel',
                            })
                            Swal.close();
                            history.push(`/location/${ locationId }`)
                        }

                    }
                });
            } else {

                // Reset the gatewayAdded flag if it's already true
                setTimeout(() => {
                    gatewayAdded = false;
                }, 50000);

            }
        };

        socket.on('Get_Confirm_Config', handleGetConfirmConfig);

        return () => {

            gatewayAdded = false;
            socket.off('Get_Confirm_Config', handleGetConfirmConfig);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ socket, lat, lng ]);

    return (
        <>
            <Card
                elevation={2}
                style={{ backgroundColor: '#F4F6F8', height: '100%', marginRight: 10, marginLeft: 10 }}
            >
                <h5 className="card-header d-flex justify-content-between align-items-center">
                    Add Gateway
                </h5>

                <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Formik
                            validationSchema={schema}
                            onSubmit={async(values) => {
                                if (!latChanged || !lngChanged) {
                                    Swal.fire('End Device position in missing.', ' Please double click on the map or drag the marker to set the end device position.', 'error');
                                    return;
                                }
                                const isGatewayExists = await checkGatewayExistence(values.macAddress);
                                if (isGatewayExists) {
                                    // Display a message indicating the gateway already exists
                                    Swal.fire({
                                        title: 'Cannot add gateway.',
                                        text: 'Already existant.',
                                        icon: 'error',
                                        showConfirmButton: false, // Remove the OK button
                                        showCancelButton: true, // Show the Cancel button
                                        cancelButtonText: 'Cancel',
                                    })
                                    setTimeout(() => {
                                        history.push(`/location/${ locationId }`)
                                    }, 1000);

                                }
                                Swal.fire({
                                    title: 'Do you really want to add gateway?',
                                    showDenyButton: true,
                                    confirmButtonColor: 'red',
                                    confirmButtonText: 'Yes',
                                    denyButtonText: 'No',
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                        GatewayService.getGatewaySocket(values.macAddress.toUpperCase()).then((response) => {
                                            if (response.data.success) {
                                                let timerInterval;
                                                Swal.fire({
                                                    title: 'Waiting for pairing...',
                                                    html: '<p>Press the gateway button for pairing before the time limit expires.</p><b></b>',
                                                    timer: 60000,
                                                    timerProgressBar: true,
                                                    showConfirmButton: false, // Remove the OK button
                                                    showCancelButton: true, // Show the Cancel button
                                                    cancelButtonText: 'Cancel', // Customize the text of the Cancel button
                                                    didOpen: () => {
                                                        const timer = Swal.getPopup().querySelector('b');
                                                        const initialTime = Math.ceil(Swal.getTimerLeft() / 1000);
                                                        timer.textContent = `${ initialTime }`;
                                                        timerInterval = setInterval(() => {
                                                            const remainingTime = Math.ceil(Swal.getTimerLeft() / 1000);
                                                            timer.textContent = `${ remainingTime } `;
                                                        }, 1000);
                                                    },
                                                    willClose: () => {
                                                        clearInterval(timerInterval);
                                                    },
                                                }).then((result) => {
                                                    if (result.dismiss === Swal.DismissReason.cancel) {
                                                        console.log('Waiting canceled');
                                                        //  sessionStorage.removeItem('macAddress');
                                                        sessionStorage.removeItem('name');
                                                        Swal.fire({
                                                            title: 'Gateway addition canceled',
                                                            icon: 'info',
                                                            showConfirmButton: false,
                                                            timer: 2000,
                                                        }).then(() => {
                                                            history.push(`/location/${ locationId }`)
                                                        });
                                                        // Handle the cancel action here
                                                    } else if (result.dismiss === Swal.DismissReason.timer) {
                                                        //   sessionStorage.removeItem('macAddress');
                                                        sessionStorage.removeItem('name');
                                                        console.log('I was closed by the timer');
                                                        Swal.fire({
                                                            title: 'Cannot to add gateway.',
                                                            text: 'Time limit exceeded.',
                                                            showConfirmButton: false, // Remove the OK button
                                                            showCancelButton: true, // Show the Cancel button
                                                            cancelButtonText: 'Cancel',
                                                        }).then((result) => {
                                                            if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
                                                                // Code to execute when cancel button is clicked
                                                                history.push(`/location/${ locationId }`)
                                                            }
                                                        });
                                                        setTimeout(() => {
                                                            Swal.close()
                                                            history.push(`/location/${ locationId }`)
                                                        }, 2000);
                                                    }
                                                });
                                            } else {
                                                //  handleClose()
                                                Swal.fire({
                                                    title: 'Your gateway is not connected.',
                                                    showConfirmButton: false,
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cancel',
                                                })

                                            }

                                        }).catch((err) => {
                                            console.log('error getGatewaySocket ', err)
                                        })

                                    }
                                });
                            }}
                            initialValues={{
                                name: '',
                                macAddress: '',
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={(event) => {
                                    handleSubmit(event);
                                    //   handleClose();
                                }}>
                                    <Grid container className="m-0" >

                                        <Grid item xs={12} sm={12} md={6}  >
                                            <Form.Group as={Col} controlId="validationFormik01">
                                                <Form.Label>Gateway Mac Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Gateway Mac Address"
                                                    name="macAddress"
                                                    value={values.macAddress}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        sessionStorage.setItem('macAddress', (e.target.value).toUpperCase())
                                                    }}
                                                    isInvalid={!!errors.macAddress}
                                                />
                                                <Form.Text muted>
                                                    XX:XX:XX:XX:XX:XX
                                                </Form.Text>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.macAddress}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Grid>

                                        <Grid xs={12} sm={12} md={6} className="mb-3">
                                            <Form.Group as={Col} controlId="validationFormik01">
                                                <Form.Label>Gateway name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Gateway name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        sessionStorage.setItem('name', e.target.value)
                                                    }}
                                                    isInvalid={!!errors.name}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} className="mb-3 mr-3" >

                                            <div className="text mb-3" >Please double click on map set the new end device position</div>
                                            <MapComponent apiKey={'AIzaSyAdWJYiOiTXMtzVyxzYCDCDdpLcEVAN8fw'} setLat={setLat} setLng={setLng} socket={socket} />
                                        </Grid>
                                        <Grid item >

                                            <Button type="submit" className="btn btn-success">
                                                Submit form
                                            </Button></Grid>

                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default AddGatewayModal;
